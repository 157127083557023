import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import DialogWrapper from "../../../../../components/DialogWrapper";

const CalendarComponent = ({ events }) => {
  const localizer = momentLocalizer(moment);

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    const changeView = (view: any) => {
      toolbar.onView(view);
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0px",
          fontSize: "16px",
          backgroundColor: "white",
          paddingX: "20px",
          paddingY: "10px",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <ButtonGroup sx={{ gap: 1 }}>
          <IconButton
            onClick={goToBack}
            disableRipple
            sx={{
              border: "1px solid white",
              borderRadius: "5px",
              padding: "0 2px",
              backgroundColor: "#F4347F",
            }}
          >
            <KeyboardArrowLeftIcon sx={{ color: "white", fontSize: 35 }} />
          </IconButton>
          <IconButton
            onClick={goToNext}
            disableRipple
            sx={{
              border: "1px solid white",
              borderRadius: "5px",
              padding: "0 2px",
              backgroundColor: "#F4347F",
            }}
          >
            <KeyboardArrowRightIcon sx={{ color: "white", fontSize: 35 }} />
          </IconButton>
        </ButtonGroup>
        <Typography sx={{ color: "black", fontSize: "20px" }}>
          {toolbar.label}
        </Typography>
        <ButtonGroup>
          <Button
            onClick={() => changeView("month")}
            sx={{
              backgroundColor: "white",
              width: "75px",
              ":hover": { backgroundColor: "#D9D9D9" },
            }}
          >
            Month
          </Button>
          <Button
            onClick={() => changeView("day")}
            sx={{
              backgroundColor: "white",
              width: "75px",
              ":hover": { backgroundColor: "#D9D9D9" },
            }}
          >
            Day
          </Button>
        </ButtonGroup>
      </Box>
    );
  };

  const EventComponent = ({ event }: any) => {
    return (
      <>
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            borderRadius: "12px",
            backgroundColor: "#afc2fb",
            color: "black",
            px: 1,
            py: "3px",
          }}
        >
          {event.title}
        </Typography>
      </>
    );
  };
  const handleEventSelect = (event: any) => {
    const selectedDate = moment(event.start).format("YYYY-MM-DD");
    const eventsForDate = events.filter((evt: any) =>
      moment(evt.start).isSame(selectedDate, "day")
    );
    setSelectedEvent(eventsForDate);
    setOpen(true);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={{ month: true, day: true }}
        defaultView="month"
        style={{
          height: 600,
        }}
        components={{
          toolbar: CustomToolbar,
          event: EventComponent,
        }}
        onSelectEvent={handleEventSelect}
        // onView={handleViewChange}
        // onDrillDown={handleDrillDown}
      />
      <DialogWrapper open={open} setOpen={setOpen} title="Session Information">
        <Grid container rowGap={2}>
          {selectedEvent?.map((item: any) => (
            <>
              <Grid item xs={12}>
                <Typography>
                  Start Date & Time :{" "}
                  {moment(item.start).format("DD-MM-YYYY, hh:mm A")}
                </Typography>
                <Typography>
                  End Date & Time :{" "}
                  {moment(item.end).format("DD-MM-YYYY, hh:mm A")}
                </Typography>
                <Typography>Title : {item.title}</Typography>
                <Box sx={{ display: "inline-flex" }}>
                  <Typography>Session Link : </Typography>
                  <Link
                    onClick={() => window.open(item.link, "_blank")}
                    sx={{
                      textDecorationLine: "none",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {"  Click Here"}
                  </Link>
                </Box>
              </Grid>
            </>
          ))}
        </Grid>
      </DialogWrapper>
    </Paper>
  );
};

export default CalendarComponent;
