import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledAcordian } from "../../MyLearningPage/styles";

export const NavigationPhoneContainer = ({
  buttonLabel,
  color,
  children,
  exploreOpen,
  setExploreOpen,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Button
        sx={{
          color: color,
          p: 0,
          width: "max-content",
        }}
        size="small"
        onClick={() => setExploreOpen(!exploreOpen)}
      >
        {buttonLabel}
      </Button>
      <Box
        sx={{
          pt: "13px",
          position: "absolute",
          top: "100%",
          zIndex: 999,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const TradeAccordion = ({ menuItems, exploreOpen, setExploreOpen }) => {
  const navigate = useNavigate();
  const [openedTrade, setOpenedTrade] = useState("");
  const [openedCourseBundle, setOpenedCourse] = useState("");
  return (
    <Box sx={{ marginLeft: 0, width: "280px" }}>
      <Box
        sx={{
          background: "white",
          minWidth: "280px",
          boxShadow: "10px 10px 20px -10px rgba(0,0,0,0.3)",
          border: "1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        {menuItems?.map((item, index) => (
          <StyledAcordian
            elevation={0}
            disableGutters
            sx={{ padding: 0 }}
            expanded={openedTrade === item?._id}
          >
            <AccordionSummary
              sx={{
                borderBottom: "1px solid #cecece",
                backgroundColor: "#F2F7FF",
              }}
              expandIcon={
                item?.courseBundles?.length > 0 ? <ExpandMoreIcon /> : <></>
              }
              onClick={() => {
                openedTrade === item._id
                  ? setOpenedTrade("")
                  : setOpenedTrade(item._id);
              }}
            >
              <Typography fontSize={"14px"}>{item?.name}</Typography>
            </AccordionSummary>
            {item?.courseBundles?.map((courseBundle, index) => (
              <AccordionDetails
                sx={{
                  margin: 0,
                  paddingY: "0px",
                  borderBottom: "1px solid #cecece45",
                  px: 0,
                }}
              >
                <StyledAcordian
                  elevation={0}
                  disableGutters
                  sx={{ padding: 0 }}
                  expanded={openedCourseBundle === courseBundle?._id}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#f2f7ff3d",
                      borderBottom: "1px solid #cecece",
                    }}
                    expandIcon={
                      courseBundle?.courses?.length > 0 ? (
                        <ExpandMoreIcon />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      openedCourseBundle === courseBundle?._id
                        ? setOpenedCourse("")
                        : setOpenedCourse(courseBundle?._id);
                    }}
                  >
                    <Typography fontSize={"15px"}>
                      {courseBundle?.bundle?.name}
                    </Typography>
                  </AccordionSummary>
                  {courseBundle?.courses?.map((course) => (
                    <AccordionSummary
                      sx={{
                        backgroundColor: "white",
                        borderBottom: "1px solid #cecece",
                      }}
                      onClick={() => {
                        setExploreOpen(false);
                        return navigate(
                          `bundles/${courseBundle?.bundle?._id}/course-curriculum/${course?._id}`,
                          {
                            state: { bundleId: courseBundle?._id },
                          }
                        );
                      }}
                    >
                      <Typography fontSize={"15px"}>{course?.title}</Typography>
                    </AccordionSummary>
                  ))}
                </StyledAcordian>
              </AccordionDetails>
            ))}
          </StyledAcordian>
        ))}
      </Box>
    </Box>
  );
};
