import { object, string } from "yup";

let loginDefaultValues = {
  username: "",
  password: "",
};

let LoginSchema = object().shape({
  username: string().required("Username is required"),
  password: string().required(),
});

export { loginDefaultValues, LoginSchema };
