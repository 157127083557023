import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCourseById } from "../../../../api/services";
import { icons } from "../../../../assets";
import Card from "../../Components/Card";
import LineText from "../../Components/LineText";

function Curriculum() {
  const params = useParams();
  const courseid = params.courseid;
  const { data } = useQuery(["get-course-by-id", courseid], getCourseById);

  const mergedChapters = data?.data?.subjects?.reduce((acc: any, curr: any) => {
    return acc.concat(curr.chapters);
  }, []);

  return (
    <Box sx={{ marginTop: "100px" }}>
      <LineText title={"Curriculum"} />
      <Box
        sx={{
          width: {
            xs: "80vw",
            sm: "80vw",
            lg: "55vw",
            xl: "55vw",
          },
          marginTop: 2,
        }}
      >
        {mergedChapters?.map((chapter: any) => {
          return (
            <>
              <StyledAcordian
                key={chapter}
                elevation={0}
                disableGutters
                TransitionProps={{ unmountOnExit: true }}
                sx={{ padding: "0px" }}
              >
                <AccordionSummary
                  sx={{
                    border: "1px solid #22222229",
                    borderRadius: "15px",
                    marginY: "10px",
                    px: "10px",
                    py: "5px",
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack gap={"5px"}>
                    <Typography variant="h5" fontSize={"16px"}>
                      {chapter?.title}
                    </Typography>
                    <Typography variant="body2">
                      {chapter?.duration > 60
                        ? `${chapter?.sections?.length}Sections / ${
                            Math.round((chapter?.duration / 60) * 100) / 100
                          } Min`
                        : `${chapter?.sections?.length} Sections / ${chapter?.duration} Sec`}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <Chapeters data={chapter?.sections} />
              </StyledAcordian>
            </>
          );
        })}
      </Box>
    </Box>
  );
}

export default Curriculum;

export const Chapeters = (data: any) => {
  return (
    <>
      {data?.data?.map((section: any) => {
        return (
          <>
            <StyledAcordian
              key={section}
              elevation={0}
              disableGutters
              TransitionProps={{ unmountOnExit: true }}
              sx={{ padding: "0px" }}
            >
              <AccordionSummary
                sx={{
                  border: "1px solid #22222229",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Stack gap={"5px"}>
                  <Typography variant="h5" fontSize={"16px"}>
                    {section?.title}
                  </Typography>
                  <Typography variant="body2">
                    {section?.duration > 60
                      ? `${section?.topics?.length} Topics / ${
                          Math.round((section?.duration / 60) * 100) / 100
                        } Min`
                      : `${section?.topics?.length} Topics / ${section?.duration} Sec`}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <Sections data={section?.topics || []} />
            </StyledAcordian>
          </>
        );
      })}
    </>
  );
};

export const Sections = (data: any) => {
  return data?.data?.map((topic: any) => {
    return (
      <AccordionDetails
        key={topic}
        sx={{
          backgroundColor: "#F7F7F7",
          m: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img
              src={
                topic?.eachTopic?.filter((item: any) => item?.type === "VIDEO")
                  ?.length > 0
                  ? icons.play
                  : topic?.eachTopic?.filter(
                      (item: any) => item?.type === "QUIZ"
                    )?.length > 0
                  ? icons.quiz
                  : icons.onlineExam
              }
              alt="icon"
              height={"15px"}
              width={"15px"}
            />
            <Typography fontSize={"14px"}>{topic.title}</Typography>
          </Box>
          <Typography fontSize={"14px"}>
            {moment
              .duration(topic.duration, "seconds")
              .format("mm:ss", { trim: false })}
          </Typography>
        </Box>
      </AccordionDetails>
    );
  });
};

export const AboutInstructor = () => {
  const params = useParams();
  const courseid = params.courseid;
  const { data } = useQuery(["get-course-by-id", courseid], getCourseById);
  const instructorData = data?.data?.instructor;
  const imagekey = data?.data?.instructor?.imageKey;
  const instructorimage = `https://digitaliti.s3.ap-south-1.amazonaws.com/${imagekey}`;
  return (
    <>
      <Box sx={{ marginTop: "40px", pb: { xs: 5, sm: 5, md: 3, lg: 0 } }}>
        <LineText title={"About Instructor"} />

        <Box
          sx={{
            paddingY: "20px",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              xl: "row",
              lg: "row",
            },
            alignItems: "center",
            gap: "20px",
          }}
        >
          {imagekey ? (
            <img
              src={instructorimage}
              style={{
                minWidth: "160px",
                height: "160px",
                borderRadius: "10px",
              }}
              alt="Instructor"
            />
          ) : (
            <Box
              sx={{
                minWidth: "160px",
                height: "160px",
                backgroundColor: "yellow",
                borderRadius: "10px",
              }}
            ></Box>
          )}
          <Stack gap={"6px"}>
            <Typography
              sx={{ fontSize: "18px", color: "#0E125E" }}
              variant="h3"
            >
              {instructorData?.name}
            </Typography>
            <Typography variant="caption">Asso. Professor</Typography>
            <CollapsibleTypography></CollapsibleTypography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export const CollapsibleTypography = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Text content
  const textContent = `
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
    accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
    quae ab illo inventore veritatis et quasi architecto beatae vitae
    dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
    aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
    eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
    est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
    velit, sed quia non numquam eius modi tempora incidunt ut labore
    et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
    veniam, quis nostrum exercitationem ullam corporis suscipit
    laboriosam.
  `;
  const lines = textContent.split("\n");
  return (
    <div>
      <Typography>
        <Collapse in={true}>
          {lines.map((line, index) => (
            <div key={index}>{index < 3 || expanded ? line : null}</div>
          ))}
        </Collapse>
      </Typography>
      {lines.length > 3 && !expanded && (
        <Button
          onClick={toggleExpanded}
          sx={{
            marginTop: "10px",
            fontSize: "14px",
            padding: "10px",
          }}
          variant="outlined"
          size="small"
        >
          Show More
        </Button>
      )}
      {expanded && (
        <Button
          onClick={toggleExpanded}
          sx={{
            marginTop: "10px",
            fontSize: "14px",
            padding: "10px",
          }}
          variant="outlined"
          size="small"
        >
          Show Less
        </Button>
      )}
    </div>
  );
};

export const RelatedBundles = () => {
  return (
    <>
      <Box sx={{ marginTop: "80px" }}>
        <LineText title={"Related Bundles"} />
        <Box
          sx={{
            marginY: "30px",
            display: "flex",
            gap: "20px",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: "0.5em",
              height: "0.5em",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "3px",

              "&:hover": {
                background: "rgba(255, 255, 255, 0.4)",
              },
            },
          }}
        >
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
        </Box>
      </Box>
    </>
  );
};

export const StyledAcordian = styled(Accordion)(() => ({
  "&.MuiPaper-root:before": {
    opacity: 0,
  },
}));
