import { MenuOpen } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { icons } from "../../../../assets";
import TabsContainer from "../../../../components/TabsContainer";
import SideBar from "./SideBar";
import Assignments from "./Tabs/Assignments";
import Tab from "./Tabs/LearnTab";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }: any) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  position: "relative",
}));

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
// }));

function Curriculum() {
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      {isLargeScreen ? (
        <Box
          sx={{
            zIndex: 1000,
            overflowY: "auto",
            maxHeight: "100vh",
          }}
        >
          <SideBar setOpenSidebar={setOpenSidebar} />
        </Box>
      ) : (
        <Drawer open={openSidebar}>
          <SideBar setOpenSidebar={setOpenSidebar} />
        </Drawer>
      )}

      <Main open={openSidebar}>
        {isLargeScreen ? (
          <></>
        ) : (
          <IconButton
            onClick={() => {
              setOpenSidebar(true);
            }}
          >
            <MenuOpen></MenuOpen>
          </IconButton>
        )}

        <Box display="flex" sx={{}}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TabsContainer
              tabs={[
                {
                  component: <Tab />,
                  key: "Learn",
                  label: "Learn",
                  icon: (
                    <Avatar
                      src={icons.learn}
                      sx={{ height: "20px", width: "20px", p: "2px" }}
                    />
                  ),
                },
                {
                  component: <Tab Doubt={true} />,
                  key: "Doubts",
                  label: "Ask a Doubts",
                  icon: (
                    <Avatar
                      src={icons.askDoubt}
                      sx={{ height: "20px", width: "20px", p: "2px" }}
                    />
                  ),
                },
                {
                  component: <Tab Notes={true} />,
                  key: "Personal notes",
                  label: "Personal notes",
                  icon: (
                    <Avatar
                      src={icons.personalNotes}
                      sx={{ height: "20px", width: "20px", p: "2px" }}
                    />
                  ),
                },
                {
                  component: <Tab EBooks={true} />,
                  key: "E-Books",
                  label: "E-Books",
                  icon: (
                    <Avatar
                      src={icons.eBook}
                      sx={{ height: "20px", width: "20px", p: "2px" }}
                    />
                  ),
                },
                {
                  component: <Tab OnlineExam={true} />,
                  key: "Online Exam",
                  label: "Online Exam",
                  icon: (
                    <Avatar
                      src={icons.onlineExam}
                      sx={{ height: "20px", width: "20px", p: "2px" }}
                    />
                  ),
                },
                {
                  component: <Assignments />,
                  key: "Assignments & Projects",
                  label: "Assignments & Projects",
                },
              ]}
            />
          </Box>
        </Box>
      </Main>
    </Box>
  );
}

export default Curriculum;
