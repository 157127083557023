import { Autocomplete, Box, TextField } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  getAllCenterType,
  getAllDistrics,
  getAllItiCenters,
  getTrades,
} from "../../../api/services";
import { StyledTitle } from "../../../components/styledHead";
import { AlignItems, StyledFlexBox } from "../../../styles";

function PracticalSlot() {
  const [district, setDistrict] = useState<any>({});
  const [type, setType] = useState<any>({});

  const { data: districtData } = useQuery("districts", getAllDistrics);

  const { data: tradeData } = useQuery("trades", getTrades);

  const { data: typeData } = useQuery("centre-type", getAllCenterType);

  const { data: itiCentersData } = useQuery(
    [
      "iti-centres",
      {
        districtId: district?.value,
        typeId: type?.value,
      },
    ],
    getAllItiCenters
  );

  return (
    <AlignItems mt={15}>
      <StyledTitle title="Choose your Practical Centre" />
      <StyledFlexBox width="60%" mt={4} gap={3}>
        <Autocomplete
          fullWidth
          options={districtData?.data?.data?.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }))}
          value={district?.label}
          onChange={(event: any, newValue: any) => {
            setDistrict(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select District" />
          )}
        />
        <Autocomplete
          fullWidth
          options={typeData?.data?.data?.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }))}
          value={type?.label}
          onChange={(event: any, newValue: any) => {
            setType(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Practical Center Type" />
          )}
        />
      </StyledFlexBox>
      <Box className={"horizontal-table"} mt={5}>
        <table className={"content"} cellPadding={15}>
          <tr>
            <th rowSpan={2}>S.No</th>
            <th rowSpan={2}>Center Name</th>
            <th rowSpan={2}>Address</th>
            <th colSpan={5}>Allotted Seats in each Trade</th>
            <th colSpan={5}>Available Seats in each Trade</th>
            <th rowSpan={2}>Contact Person &amp; Mobile</th>
          </tr>
          <tr>
            {tradeData?.data?.data?.map((item: any) => (
              <>
                <th key={item?._id}>{item?.name}</th>
              </>
            ))}
            {tradeData?.data?.data?.map((item: any) => (
              <>
                <th key={item?._id}>{item?.name}</th>
              </>
            ))}
          </tr>
          {itiCentersData?.data?.count === 0 ? (
            <tr style={{ backgroundColor: "#fff" }}>
              <td width="160%" colSpan={50}>
                No data found
              </td>
            </tr>
          ) : (
            <>
              {itiCentersData?.data?.data?.map((item: any) => (
                <tr>
                  <td>{itiCentersData?.data?.data?.indexOf(item) + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.address}</td>
                  {item?.trades?.map((trade: any) => (
                    <td>{trade?.allotedSeats}</td>
                  ))}
                  {item?.trades?.map((trade: any) => (
                    <td>{trade?.allotedSeats}</td>
                  ))}
                  <td>
                    {item?.pocName} & {item?.pocMobile}
                  </td>
                </tr>
              ))}
            </>
          )}
        </table>
      </Box>
    </AlignItems>
  );
}

export default PracticalSlot;
