import { Box, Button, Grid, Paper, Radio, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { getQuiz, getTopicComponents } from "../../../../../api/services";
import { emptyDashboard } from "../../../../../assets";
import EmptyPage from "../../../../../components/EmptyPage";
import { Loader } from "../../../../../components/loader";
import { StyledQuizPaper } from "../../../../../styles";
import Doubts from "./Doubts";
import EBooksComponent from "./EBooks";
import OnlineExamTab from "./OnlineExam";
import PersonalNotes from "./PersonalNotes";
import { getDoubts, getPersonalNotes } from "./services";

function Tab({
  Doubt = false,
  Notes = false,
  EBooks = false,
  OnlineExam = false,
  AssignmentProject = false,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatetopicId = searchParams.get("topicId");
  const { topicId } = useParams();
  const currenttopicId = updatetopicId ? updatetopicId : topicId;

  const { data: PersonalNotesData, isLoading } = useQuery(
    "get-personal-notes",
    () => getPersonalNotes(currenttopicId)
  );

  const [params, setParams] = useState({
    status: null,
    userId: true,
    topicId: topicId,
  });

  const { data: getDoubte } = useQuery("get-doubts", () => getDoubts(params));

  const { data: TopicData } = useQuery(
    ["topicData", currenttopicId],
    getTopicComponents,
    {
      enabled: !!currenttopicId,
    }
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      {TopicData?.data?.length === 0 ||
      TopicData?.data?.length === undefined ? (
        <EmptyPage image={emptyDashboard} title="No Topic Component found" />
      ) : (
        <>
          {TopicData?.data?.map((item: any) => (
            <Box
              sx={{
                height: {
                  xs: "50vh",
                  sm: "50vh",
                  lg: "80vh",
                },
                overflowY: "scroll",
                px: "10px",
                pb: "10px",
              }}
            >
              {item?.type === "VIDEO" && (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#F4347F",
                      padding: "15px",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography sx={{ color: "white" }}>
                      {item?.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      height: {
                        xs: "40vh",
                        sm: "40vh",
                        lg: "650px",
                      },
                      backgroundColor: "#EEF2FF",
                      borderRadius: "10px",
                      marginY: "10px",
                      padding: "20px",
                    }}
                  >
                    <iframe
                      title={item?.title}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                      src={
                        new DOMParser()
                          .parseFromString(item?.link, "text/html")
                          .documentElement.getElementsByTagName("iframe")[0]
                          ?.src
                      }
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Box>
                </>
              )}
              {item?.type === "QUIZ" && (
                <Box>
                  <Quiz data={item} />
                </Box>
              )}
            </Box>
          ))}
        </>
      )}
      {Doubt && (
        <Doubts DoubtsData={getDoubte} setParams={setParams} params={params} />
      )}

      {Notes && <PersonalNotes NotesData={PersonalNotesData} />}

      {EBooks && <EBooksComponent />}

      {OnlineExam && <OnlineExamTab />}
    </Box>
  );
}

export default Tab;

const Quiz = ({ data }: any) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const [showAns, setShowAns] = useState(false);

  const { data: quizData } = useQuery(
    [
      "quizData",
      {
        topicId: data?.topicId,
        quizId: data?.quizId,
        questionId: selectedValue?.questionId,
      },
    ],
    getQuiz,
    {
      enabled: !!selectedValue?.questionId,
    }
  );

  const handleSubmit = () => {
    setShowAns(true);
  };

  const handleChange = (optionId: any, questionId: any) => {
    setSelectedValue({ optionId: optionId, questionId: questionId });
    setShowAns(false);
  };
  return (
    <>
      {data?.questions?.questions?.map((item: any, index: number) => (
        <Paper key={item?._id} sx={{ p: 3, mt: 3 }}>
          <Typography variant="h5">Question {index + 1}</Typography>
          <Typography mt={1}>{item?.question}</Typography>
          <Grid mt={1.5} container spacing={2}>
            {item?.options?.map((option: any) => (
              <Grid key={option?._id} item xs={12} md={6}>
                <StyledQuizPaper elevation={2}>
                  <Typography>{option?.option}</Typography>
                  <Radio
                    checked={selectedValue?.optionId === option?._id}
                    onChange={() => handleChange(option?._id, item?._id)}
                    value={option?._id}
                    name="radio-buttons"
                    // icon={<CheckIcon />}
                  />
                </StyledQuizPaper>
              </Grid>
            ))}
          </Grid>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {selectedValue?.questionId === item?._id && showAns && (
              <Box width="100%">
                {quizData?.data?.isCorrect ? (
                  <Typography color={"#7CB343"}>Correct Answer</Typography>
                ) : (
                  <Typography color={"#F4347F"}>Wrong Answer</Typography>
                )}
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
              }}
            >
              <Button
                disabled={
                  selectedValue?.questionId === item?._id ? false : true
                }
                onClick={handleSubmit}
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Box>
          {selectedValue?.questionId === item?._id && showAns && (
            <Box mt={2}>
              <Typography variant="h6">Explanation:</Typography>
              <Typography variant="subtitle2" fontSize="16px">
                {item?.explanation}
              </Typography>
            </Box>
          )}
        </Paper>
      ))}
    </>
  );
};
