import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import ReactPlayer from "react-player";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getTopicComponents,
  nextVideo,
  videoLog,
} from "../../../api/services/learning";
import { NoData } from "../../../assets";
import { Loader } from "../../../components/loader";

const Learn = () => {
  const { bundleId, courseId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const chapterId = searchParams.get("chapterId");
  const sectionId = searchParams.get("sectionId");
  const playerRef = useRef(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ["getTopicComponents", topicId],
    getTopicComponents
  );

  const videoData = data?.data?.filter(
    (item: any) => item?.type === "VIDEO"
  )[0];

  const { mutate: paused } = useMutation(videoLog, {
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const { mutate: completed } = useMutation(videoLog, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
      queryClient.invalidateQueries("getTopicComponents");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const { mutate } = useMutation(nextVideo, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
      queryClient.invalidateQueries("getTopicComponents");
      searchParams.set("chapterId", data?.data?.NextCourse?.chapterId);
      searchParams.set("sectionId", data?.data?.NextCourse?.sectionId);
      searchParams.set("topicId", data?.data?.NextCourse?.topicId);
      navigate(`?${searchParams.toString()}`);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const handleLog = (status: any) => {
    const postData = {
      bundleId: bundleId,
      courseId: courseId,
      subjectId: videoData?.subjectId,
      chapterId: chapterId,
      sectionId: sectionId,
      topicId: topicId,
      status: videoData?.video?.status === "COMPLETED" ? "COMPLETED" : status,
      completedDuration: playerRef.current.getCurrentTime(),
    };
    if (status === "COMPLETED") {
      return completed(postData);
    } else if (
      playerRef.current.getDuration() - playerRef.current.getCurrentTime() >
      5
    ) {
      return paused(postData);
    }
  };

  const handleNextVideo = () => {
    const postData = {
      bundleId: bundleId,
      courseId: courseId,
      subjectId: videoData?.subjectId,
      chapterId: chapterId,
      sectionId: sectionId,
      topicId: topicId,
    };
    mutate(postData);
  };

  const handleSeek = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(videoData?.video?.completedDuration, "seconds");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : videoData?.link ? (
        <Box
          sx={{
            px: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F4347F",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ p: 1, color: "white", fontSize: "16px" }}>
              {videoData?.topic?.title}
            </Typography>
            <Typography sx={{ p: 1, color: "white", fontSize: "16px" }}>
              {moment
                .duration(videoData?.videoDuration, "seconds")
                .format("hh:mm:ss", {
                  trim: false,
                })}
            </Typography>
          </Box>
          <ReactPlayer
            style={{ backgroundColor: "black" }}
            width="100%"
            height={`calc(100vh - ${"240px"})`}
            ref={playerRef}
            url={"https://vimeo.com/141165330"}
            controls
            onReady={handleSeek}
            onEnded={() => {
              return handleLog("COMPLETED");
            }}
            onPause={() => {
              return handleLog("UNLOCKED");
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#F4347F", fontSize: "14px" }}>
                Next Video
              </Typography>
              <IconButton
                sx={{
                  borderRadius: "5px",
                  backgroundColor: "#F4347F",
                  ":hover": { backgroundColor: "#F4347F" },
                  ":disabled": { backgroundColor: "#f4347f87" },
                }}
                disabled={
                  videoData?.video?.status === "COMPLETED" ? false : true
                }
                onClick={handleNextVideo}
              >
                <KeyboardArrowRightIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Box sx={{ alignItems: "center", width: "500px" }}>
            <img src={NoData} alt="No Data" height={"100%"} width={"100%"} />
          </Box>
          <Typography variant="h4" sx={{ py: 1 }}>
            No Video Found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Learn;
