import { useLocation } from "react-router-dom";
import QuizResults from "../pages/Quiz/results";
import { StyledMainContentContainer } from "../pages/SignInPages/Sign-in-Layout";
import Results from "../pages/Test/results";
import ResultsHeader from "./ResultsHeader";

export const ResultLayout = () => {
  const location = useLocation();
  const isLearning = location.pathname.includes("learnings");
  return (
    <>
      <ResultsHeader isLearning={isLearning} />
      <StyledMainContentContainer
        sx={{
          width: `100vw`,
          top: "64px",
          left: 0,
          height: `calc(100vh - ${"64px"})`,
          overflowX: "hidden",
          px: { sx: 2, sm: 2, lg: 0 },
        }}
      >
        {isLearning ? <QuizResults /> : <Results />}
      </StyledMainContentContainer>
    </>
  );
};
