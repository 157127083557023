import { Box } from "@mui/material";
import TabsContainer from "../../../../components/TabsContainer";
import DETLive from "./components/DETLive";
import TradeLive from "./components/TradeLive";

const MyLiveSession = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <TabsContainer
        tabs={[
          {
            component: <DETLive />,
            key: "DET Live Sessions",
            label: "DET Live Sessions",
          },
          {
            component: <TradeLive />,
            key: "Trade Live",
            label: "Trade Live",
          },
        ]}
      />
    </Box>
  );
};

export default MyLiveSession;
