import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../../../../assets";
import { StyledAcordian } from "../../../../MyLearningPage/styles";

const updateSearchParams = (key: any, value: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  return searchParams.toString();
};

export const Curriculums = ({ data, bundleId, courseId }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const chapterId = searchParams.get("chapterId");

  const [expandedChapter, setExpandedChapter] = useState<any>("");

  useEffect(() => {
    setExpandedChapter(chapterId);
  }, [chapterId]);

  return (
    <Box>
      {data?.map((item: any, index: any) => (
        <>
          <StyledAcordian
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{ padding: 0 }}
            expanded={expandedChapter === item?._id}
          >
            <AccordionSummary
              sx={{
                backgroundColor: "#F2F7FF",
                borderBottom: "1px solid #cecece59",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                expandedChapter === item._id
                  ? setExpandedChapter("")
                  : setExpandedChapter(item._id);
                return navigate(
                  `?${updateSearchParams("chapterId", item?._id)}`
                );
              }}
            >
              <Typography fontSize={"14px"}>{item?.title}</Typography>
              <Typography fontSize={"14px"}>{item?.title}</Typography>
            </AccordionSummary>

            {item?.sections?.map((lesson: any, index: any) => (
              <Lessons
                data={lesson}
                index={index}
                key={index}
                total={item?.sections?.length}
                bundleId={bundleId}
                courseId={courseId}
              />
            ))}
          </StyledAcordian>
        </>
      ))}
    </Box>
  );
};
const Lessons = ({ data, index, total, bundleId, courseId }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sectionId = searchParams.get("sectionId");

  useEffect(() => {
    setExpandedSection(sectionId);
  }, [sectionId]);

  const [expandedSection, setExpandedSection] = useState<any>("");

  return (
    <>
      <AccordionDetails
        sx={{
          margin: 0,
          paddingY: "0px",
          borderBottom: "1px solid #cecece45",
          px: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <StyledAcordian
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{ width: "100%" }}
            expanded={expandedSection === data._id}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#F2F7FF", width: "auto" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                expandedSection === data._id
                  ? setExpandedSection("")
                  : setExpandedSection(data._id);
                return navigate(
                  `?${updateSearchParams("sectionId", data?._id)}`
                );
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography fontSize={"14px"}>{data?.title}</Typography>
                <Typography fontSize={"14px"}>
                  {data?.topics?.length > 1
                    ? `${data?.topics?.length} topics`
                    : `${data?.topics?.length} topic`}
                </Typography>
              </Box>
            </AccordionSummary>

            {data?.topics?.map((classItem: any, index: any) => (
              <AccordionDetails
                key={classItem._id}
                sx={{ borderBottom: "1px solid #cecece45" }}
                onClick={() => {
                  if (classItem?.status !== "LOCKED") {
                    return navigate(
                      `/learnings/bundle/${bundleId}/course/${courseId}?${updateSearchParams(
                        "topicId",
                        classItem?._id
                      )}`
                    );
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <img
                      src={
                        classItem?.eachTopic?.filter(
                          (item: any) => item?.type === "VIDEO"
                        )?.length > 0
                          ? icons.play
                          : classItem?.eachTopic?.filter(
                              (item: any) => item?.type === "QUIZ"
                            )?.length > 0
                          ? icons.quiz
                          : icons.onlineExam
                      }
                      alt="icon"
                      height={"15px"}
                      width={"15px"}
                    />
                    <Typography fontSize={"14px"}>{classItem.title}</Typography>
                    {classItem?.status === "LOCKED" ? (
                      <img
                        src={icons.lock}
                        alt="lock"
                        width={"12px"}
                        height={"16px"}
                      />
                    ) : classItem?.status === "COMPLETED" ? (
                      <img
                        src={icons.completed}
                        alt="lock"
                        width={"14px"}
                        height={"16px"}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Typography fontSize={"14px"}>
                    {moment
                      .duration(classItem.duration, "seconds")
                      .format("mm:ss", { trim: false })}
                  </Typography>
                </Box>
              </AccordionDetails>
            ))}
          </StyledAcordian>
        </Box>
      </AccordionDetails>
    </>
  );
};
