import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium";
  control: any;
  multiline?: boolean;
  required?: boolean;
}

function FormPasswordInput(props: Props) {
  const {
    name,
    size = "small",
    control,
    label = "",
    multiline,
    required = false,
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <TextField
              error={Boolean(error)}
              variant="outlined"
              label={`${label} ${required ? "*" : ""}`}
              fullWidth
              multiline={multiline}
              rows={multiline ? 2 : 1}
              size={size}
              type={showPassword ? "text" : "password"}
              {...field}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ cursor: "pointer" }}
                    onClick={togglePassword}
                    position="start"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
                ),
              }}
            />
            {error && (
              <Typography
                variant="caption"
                sx={{ pl: "2px", display: "block" }}
                color="rgb(211, 47, 47)"
              >
                {error.message}
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
}

export default FormPasswordInput;
