import { Box, Typography } from "@mui/material";

function LineText({ title }: any) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        marginTop: "20px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          bgcolor: "#F4347F",
          width: "4px",
          height: "35px",
          borderRadius: "0px 5px 5px 0px",
        }}
      ></Box>
      <Typography
        sx={{
          fontSize: {
            xs: "25px",
            sm: "25px",
            xl: "25px",
            lg: "25px",
          },
        }}
        variant="h4"
      >
        {title}
      </Typography>
    </Box>
  );
}

export default LineText;
