import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllCenterType,
  getAllDistrics,
  getAllItiCenters,
  getAllLanguages,
  getTrades,
} from "../api/services";
import { bgImg, logo } from "../assets";
import UploadImage from "../components/UploadImage";
import { Loader } from "../components/loader";
import { StyledHead } from "../components/styledHead";
import { AlignItems, StyledFlexBox } from "../styles";
import { studentSchema } from "../validations/student";
import Preview from "./preview";
import SuccessScreen from "./successScreen";

function SignUp() {
  const theme = useTheme();
  const [value, setValue] = useState("signUp");
  const [pic, setPic] = useState<any>(null);
  const [aadhar, setAadhar] = useState<any>(null);
  const [markSheet, setMarkSheet] = useState<any>(null);
  const [signature, setSignature] = useState<any>(null);
  const [formData, setFormData] = useState<any>({});
  const [allData, setAllData] = useState<any>({});
  const [response, setResponse] = useState("");
  const [allIds, setAllIds] = useState<any>({});
  const [dob, setDob] = useState<string>();

  const { data: districtData } = useQuery("districts", getAllDistrics);

  const { data: tradeData } = useQuery("trades", getTrades);

  const { data: typeData } = useQuery("centre-type", getAllCenterType);

  const { data: itiCentersData } = useQuery(
    [
      "iti-centres",
      {
        districtId: allIds?.districtId,
        tradeId: allIds?.tradeId,
        typeId: allIds?.typeId,
      },
    ],
    getAllItiCenters,
    {
      enabled: !!allIds?.districtId && !!allIds?.tradeId && !!allIds?.typeId,
    }
  );

  const filterCenters = itiCentersData?.data?.data
    .filter((item: any) => item?._id === allIds.centreId)[0]
    ?.trades.filter((item: any) => item?.trade?._id === allIds.tradeId)[0];

  const { data: languageData, isLoading } = useQuery(
    "languages",
    getAllLanguages
  );

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(studentSchema),
    defaultValues: {
      name: "",
      mobile: "",
      email: "",
      fatherName: "",
      aadharNumber: "",
      districtId: "",
      tradeId: "",
      typeId: "",
      centreId: "",
      languageIds: [languageData?.data?.data[0]?._id],
    },
  });

  useEffect(() => {
    setFormData({ ...formData, language: languageData?.data?.data[0]?.name });
    reset({
      languageIds: [languageData?.data?.data[0]?._id],
    });
  }, [languageData]);

  const onSubmit = async (formData: any) => {
    const res = {
      ...formData,
      imageKey: pic?.key,
      aadharKey: aadhar?.key,
      sscKey: markSheet?.key,
      signatureKey: signature?.key,
      dateOfBirth: moment(dob).format("YYYY-MM-DD"),
    };

    if (
      pic === null ||
      aadhar === null ||
      markSheet === null ||
      signature === null
    ) {
      toast.error("Please upload your documents");
    } else {
      setAllData(res);
      setValue("preview");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box mt={6}>
      <AlignItems>
        <Grid mb={4} justifyContent="center" container columnSpacing={8}>
          <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            item
            xs={12}
            sm={6}
            mt={8}
          >
            <Link to="https://digitaliti.in/">
              <img
                src={logo}
                height="120"
                width="334"
                style={{ cursor: "pointer" }}
              />
            </Link>
            <Typography variant="h1" color={theme.palette.primary.main} my={4}>
              Digital ITI - Registration
            </Typography>
            <img
              src={bgImg}
              height="410"
              width="722"
              style={{ maxHeight: "350px", maxWidth: "622px" }}
            />
          </Grid>
          {value === "signUp" && (
            <Grid item xs={12} sm={5} pr={3} overflow="auto" maxHeight="700px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <StyledHead head="Student Details" />
                <StyledFlexBox flexDirection="column" gap={2} mt={2}>
                  <FormControl fullWidth>
                    <FormLabel>
                      Name (As per 10th Marksheet){" "}
                      <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <TextField
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "Name is required",
                      })}
                      helperText={!errors?.name?.message}
                      error={!!errors?.name}
                    />
                    {errors?.name && (
                      <Typography color="error">
                        {errors?.name?.message}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      Mobile Number <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <TextField
                      placeholder="Enter Mobile number"
                      {...register("mobile", {
                        required: "Mobile Number is required",
                        maxLength: 10,
                        minLength: 10,
                      })}
                      helperText={!errors?.mobile?.message}
                      error={!!errors?.mobile}
                      type="number"
                    />
                    {errors?.name && (
                      <Typography color="error">
                        {errors?.name?.message}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      Email <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <TextField
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "Email is required",
                      })}
                      helperText={!errors?.email?.message}
                      error={!!errors?.email}
                      type="email"
                    />
                    {errors?.name && (
                      <Typography color="error">
                        {errors?.name?.message}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      Father's Name <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <TextField
                      placeholder="Enter Name"
                      {...register("fatherName", {
                        required: "Father Name is required",
                      })}
                      helperText={!errors.fatherName?.message}
                      error={!!errors.fatherName}
                    />
                    {errors.name && (
                      <Typography color="error">
                        {errors.name.message}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      Date of Birth <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      maxDate={moment()}
                      value={dob}
                      onChange={(newValue: any) => {
                        setDob(newValue);
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      Aadhar Number <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <TextField
                      placeholder="Enter Aadhar Number"
                      {...register("aadharNumber", {
                        required: "Aadhar Number is required",
                        maxLength: 12,
                        minLength: 12,
                      })}
                      type="number"
                      helperText={!errors.aadharNumber?.message}
                      error={!!errors.aadharNumber}
                    />
                    {errors.name && (
                      <Typography color="error">
                        {errors.name.message}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      District <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <Controller
                      name="districtId"
                      control={control}
                      {...register("districtId", {
                        required: "districctId is required",
                      })}
                      rules={{ required: "District is required" }}
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          defaultValue={
                            districtData?.data?.data?.find(
                              (item: any) => item?._id === allData?.districtId
                            ) || null
                          }
                          options={districtData?.data?.data}
                          getOptionLabel={(option: any) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select District"
                              helperText={!errors.districtId?.message}
                              error={!!errors.districtId}
                            />
                          )}
                          onChange={(_, data) => (
                            onChange(data?._id),
                            setFormData({ ...formData, district: data?.name }),
                            setAllIds({ ...allIds, districtId: data?._id })
                          )}
                        />
                      )}
                    />
                    {errors.name && (
                      <Typography color="error">
                        {errors.name.message}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel>
                      Languages <span style={{ color: "#F4347F" }}>*</span>
                    </FormLabel>
                    <Controller
                      name="languageIds"
                      control={control}
                      {...register("languageIds", {
                        required: "Name is required",
                      })}
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          multiple
                          options={languageData?.data?.data}
                          defaultValue={[languageData?.data?.data[0]]}
                          getOptionLabel={(option: any) => option?.name}
                          renderOption={(props, option: any, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select your language"
                              helperText={!errors.languageIds?.message}
                              error={!!errors.languageIds}
                            />
                          )}
                          onChange={(_, data) => (
                            onChange(data?.map((d: any) => d?._id)),
                            setFormData({
                              ...formData,
                              language: data?.map((d: any) => d?.name),
                            })
                          )}
                        />
                      )}
                    />
                    {errors.name && (
                      <Typography color="error">
                        {errors.name.message}
                      </Typography>
                    )}
                  </FormControl>
                </StyledFlexBox>
                <Box mt={5}>
                  <StyledHead head="Trade Details" />
                  <StyledFlexBox flexDirection="column" gap={2} mt={2}>
                    <FormControl fullWidth>
                      <FormLabel>
                        Trade <span style={{ color: "#F4347F" }}>*</span>
                      </FormLabel>
                      <Controller
                        name="tradeId"
                        {...register("tradeId", {
                          required: "tradeId is required",
                        })}
                        control={control}
                        rules={{ required: "Trade is required" }}
                        render={({ field: { onChange } }) => (
                          <Autocomplete
                            defaultValue={
                              tradeData?.data?.data?.find(
                                (item: any) => item?._id === allData?.tradeId
                              ) || null
                            }
                            options={tradeData?.data?.data}
                            getOptionLabel={(option: any) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select your trade"
                                helperText={!errors.tradeId?.message}
                                error={!!errors.tradeId}
                              />
                            )}
                            onChange={(_, data) => (
                              onChange(data?._id),
                              setFormData({ ...formData, trade: data?.name }),
                              setAllIds({ ...allIds, tradeId: data?._id })
                            )}
                          />
                        )}
                      />
                      {errors.name && (
                        <Typography color="error">
                          {errors.name.message}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel>
                        Practical Center Type{" "}
                        <span style={{ color: "#F4347F" }}>*</span>
                      </FormLabel>
                      <Controller
                        name="typeId"
                        control={control}
                        rules={{
                          required: "Practical Center Type is required",
                        }}
                        render={({ field: { onChange } }) => (
                          <Autocomplete
                            defaultValue={
                              typeData?.data?.data?.find(
                                (item: any) => item?._id === allData?.typeId
                              ) || null
                            }
                            options={typeData?.data?.data}
                            getOptionLabel={(option: any) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Practical Center Type"
                                helperText={!errors.typeId?.message}
                                error={!!errors.typeId}
                              />
                            )}
                            onChange={(_, data) => (
                              onChange(data?._id),
                              setFormData({ ...formData, type: data?.name }),
                              setAllIds({ ...allIds, typeId: data?._id })
                            )}
                          />
                        )}
                      />
                      {errors.name && (
                        <Typography color="error">
                          {errors.name.message}
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl fullWidth>
                      <FormLabel>
                        Practical Center{" "}
                        <span style={{ color: "#F4347F" }}>*</span>
                      </FormLabel>
                      <Controller
                        name="centreId"
                        control={control}
                        rules={{
                          required: "Practical Center is required",
                        }}
                        render={({ field: { onChange } }) => (
                          <Autocomplete
                            defaultValue={
                              itiCentersData?.data?.data?.find(
                                (item: any) => item?._id === allData?.centreId
                              ) || null
                            }
                            options={itiCentersData?.data?.data || []}
                            getOptionLabel={(option: any) => option?.name || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select your Practical Center"
                                helperText={!errors.centreId?.message}
                                error={!!errors.centreId}
                              />
                            )}
                            onChange={(_, data) => (
                              onChange(data?._id),
                              setFormData({ ...formData, center: data?.name }),
                              setAllIds({ ...allIds, centreId: data?._id })
                            )}
                          />
                        )}
                      />
                      {errors.name && (
                        <Typography color="error">
                          {errors.name.message}
                        </Typography>
                      )}
                    </FormControl>
                    <StyledFlexBox width="100%">
                      {filterCenters?.allotedSeats >= 0 && (
                        <StyledFlexBox
                          width="100%"
                          gap={1}
                          justifyContent="left"
                        >
                          <Typography
                            color={theme.palette.success.main}
                            variant="h5"
                          >
                            Allotted Seats:
                          </Typography>
                          <Typography
                            color={theme.palette.success.main}
                            variant="h5"
                          >
                            {filterCenters?.allotedSeats || 0}
                          </Typography>
                        </StyledFlexBox>
                      )}
                      {filterCenters?.availableSeats >= 0 && (
                        <StyledFlexBox
                          width="100%"
                          gap={1}
                          justifyContent="left"
                        >
                          <Typography
                            color={theme.palette.success.main}
                            variant="h5"
                          >
                            Available Seats:
                          </Typography>
                          <Typography
                            color={theme.palette.success.main}
                            variant="h5"
                          >
                            {filterCenters?.availableSeats || 0}
                          </Typography>
                        </StyledFlexBox>
                      )}
                    </StyledFlexBox>
                  </StyledFlexBox>
                </Box>
                <Box mt={5}>
                  <StyledHead head="Mandatory Documents" />
                  <Box mt={2}>
                    <StyledFlexBox justifyContent="space-between" gap={6}>
                      <Box>
                        <Typography>
                          Upload Photo{" "}
                          <span style={{ color: "#F4347F" }}>*</span>
                        </Typography>
                        <Typography variant="caption">
                          *JPEG or PNG format Size less than 5 MB
                        </Typography>
                      </Box>
                      <UploadImage
                        pic={pic?.s3Response?.url}
                        setPic={setPic}
                        labelName="photo"
                        accept="image/*"
                        type="STUDENT"
                        subType="DOCS"
                      />
                    </StyledFlexBox>
                    <StyledFlexBox justifyContent="space-between" gap={6}>
                      <Box>
                        <Typography>
                          Upload Aadhar Copy{" "}
                          <span style={{ color: "#F4347F" }}>*</span>
                        </Typography>
                        <Typography variant="caption">
                          *Other Options Driving License/Passport (JPEG or PNG
                          format Size 5 MB)
                        </Typography>
                      </Box>
                      <UploadImage
                        pic={aadhar?.s3Response?.url}
                        setPic={setAadhar}
                        labelName="aadhar"
                        accept="/*"
                        type="STUDENT"
                        subType="DOCS"
                      />
                    </StyledFlexBox>
                    <StyledFlexBox justifyContent="space-between" gap={6}>
                      <Box>
                        <Typography>
                          Upload 10th Marks Sheet{" "}
                          <span style={{ color: "#F4347F" }}>*</span>
                        </Typography>
                        <Typography variant="caption">
                          *PDF format Size less than 5 MB
                        </Typography>
                      </Box>
                      <UploadImage
                        pic={markSheet?.s3Response?.url}
                        setPic={setMarkSheet}
                        labelName="markSheet"
                        accept="/*"
                        type="STUDENT"
                        subType="DOCS"
                      />
                    </StyledFlexBox>
                    <StyledFlexBox justifyContent="space-between" gap={6}>
                      <Box>
                        <Typography>
                          Upload Signature{" "}
                          <span style={{ color: "#F4347F" }}>*</span>
                        </Typography>
                        <Typography variant="caption">
                          *JPEG or PNG format Size less than 5 MB
                        </Typography>
                      </Box>
                      <UploadImage
                        pic={signature?.s3Response?.url}
                        setPic={setSignature}
                        labelName="signature"
                        accept="/*"
                        type="STUDENT"
                        subType="DOCS"
                      />
                    </StyledFlexBox>
                  </Box>
                </Box>
                <Box mt={3} textAlign="center">
                  <Button variant="contained" type="submit">
                    Preview
                  </Button>
                </Box>
              </form>
            </Grid>
          )}
          {value === "preview" && (
            <Grid item xs={12} sm={5} overflow="auto" maxHeight="700px">
              <Preview
                formData={formData}
                AllData={allData}
                setValue={setValue}
                setResponse={setResponse}
              />
            </Grid>
          )}
          {value === "successScreen" && (
            <Grid item xs={12} sm={5}>
              <SuccessScreen response={response} />
            </Grid>
          )}
        </Grid>
      </AlignItems>
    </Box>
  );
}

export default SignUp;
