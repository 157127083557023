import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getStatistics } from "../../../api/services";
import { BlogPost } from "../../../assets";

function BlendedLMS() {
  const { data } = useQuery("userweb-statistics", getStatistics);
  return (
    <Box
      sx={{
        height: { xs: "auto", md: `calc( 100vh - ${"60px"} )` },
        py: { xs: 5, md: 4 },
        bgcolor: "#2F468C",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "white",
            fontSize: { xs: "26px", sm: "26px", md: "30px", lg: "34px" },
            pb: { xs: 4, sm: 3, md: 3, lg: 0 },
          }}
        >
          About Blended LMS
        </Typography>
      </Box>
      <Stack
        direction={"row"}
        sx={{
          marginY: "auto",
          width: { xs: "95%", sm: "90%", md: "90%", lg: "80%", xl: "80%" },
          justifyContent: "space-between",
        }}
      >
        <Stack gap={"40px"}>
          <StyledBox
            title={"Number of Students"}
            number={
              data?.data?.student > 50
                ? `${Math.round(data?.data?.student / 50) * 50}+`
                : data?.data?.student
            }
          />
          <StyledBox title={"Number of Trades"} number={data?.data?.trade} />
          <StyledBox
            title={"Number of Affiliated ITI's"}
            number={data?.data?.centres}
          />
        </Stack>
        <Stack sx={{ marginTop: "3%" }}>
          <img
            src={BlogPost}
            width={"70%"}
            // height={"70%"}
            style={{
              objectFit: "contain",
              margin: "auto",
            }}
            alt="Banner"
          />
        </Stack>
        <Stack gap={"40px"}>
          <StyledBox
            title={"Hours of Content "}
            number={Math.round(data?.data?.hours)}
          />
          <StyledBox title={"Number of E-Books"} number={data?.data?.ebooks} />
          <StyledBox
            title={"Number of OnlineTests"}
            number={data?.data?.exams}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

export default BlendedLMS;

export const StyledBox = ({ title, number }: any) => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        borderRadius: "10px",
        padding: "10px 20px 10px 20px",
        width: {
          xs: "120px",
          sm: "170px",
          md: "200px",
          lg: "230px",
          xl: "250px",
        },
        border: "3px solid #16ACAB",
      }}
    >
      <Typography
        variant="body1"
        fontFamily={"figtree_semibold"}
        sx={{ opacity: 0.7, fontSize: { xs: "10px", sm: "13px", md: "15px" } }}
      >
        {title}
      </Typography>
      <Typography
        variant="h3"
        sx={{ fontSize: { xs: "20px", sm: "25px", md: "35px", lg: "40px" } }}
      >
        {number}
      </Typography>
    </Box>
  );
};
