import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { loginImage } from "../../assets";
import FormInput from "../../components/FormFields/FormInput";
import FormPasswordInput from "../../components/FormFields/FormPasswordInput";
import { StyledHead } from "../../components/styledHead";
import { loginDefaultValues } from "../../components/validations/loginForm";
import { AlignItems } from "../../styles";

function Blended() {
  const theme = useTheme();
  const { control } = useForm({
    defaultValues: loginDefaultValues,
    mode: "onChange",
  });

  return (
    <AlignItems mt={30}>
      <Grid container>
        <Grid item xs={6}>
          <img src={loginImage} alt="login" width={650} height={500} />
        </Grid>
        <Grid mt={5} item xs={6}>
          <StyledHead head="Login" />
          <form>
            <Box mt={3} maxWidth="450px">
              <Box>
                <FormInput control={control} name="username" label="Username" />
              </Box>
              <Box mt={3}>
                <FormPasswordInput
                  control={control}
                  name="password"
                  label="Password"
                />
              </Box>
              <Box sx={{ cursor: "pointer" }} mt={0.5} textAlign="right">
                <Typography color={theme.palette.primary.main}>
                  Forgot Password
                </Typography>
              </Box>
            </Box>
            <Box maxWidth={"200px"} mt={2}>
              <Button type="submit" variant="contained" fullWidth>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </AlignItems>
  );
}

export default Blended;
