import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import {
  StyledLeftNavContainer,
  StyledMainContentContainer,
} from "../pages/HomePage/styles";
import MyLearning from "../pages/MyLearningPage";
import MyLearningSideNav from "../pages/MyLearningPage/components/MyLearningSideNav";
import Header from "../pages/SignInPages/Header";

export const ListOfCoursesLayout = () => {
  return (
    <>
      <Header />
      <StyledLeftNavContainer
        sx={{
          width: "350px",
          background: "white",
          top: "70px",
          height: `calc(100vh - ${"70px"})`,
          boxShadow: "0px 3px 6px #00000029",
        }}
      >
        <MyLearningSideNav />
      </StyledLeftNavContainer>
      <StyledMainContentContainer
        sx={{ width: `calc(100vw - ${"350px"})`, left: "350px", top: "70px" }}
      >
        <MyLearning />
      </StyledMainContentContainer>
    </>
  );
};

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: "20px",
  textDecoration: "none",
}));
