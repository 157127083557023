import { Box, Typography } from "@mui/material";
import moment from "moment";

const NoteCard = ({ data }) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 10px #0000001A",
        px: 2,
        py: 1,
        borderRadius: "5px",
      }}
    >
      <Typography sx={{ fontSize: "14px", opacity: 0.5 }}>
        {`Noted on ${moment(data?.createdAt).format("DD MMM, YYYY - hh:mm A")}`}
      </Typography>
      <Typography sx={{ fontSize: "14px" }} variant="body1">
        {data?.notesDescription}
      </Typography>
    </Box>
  );
};

export default NoteCard;
