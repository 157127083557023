import GetAppIcon from "@mui/icons-material/GetApp";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import generatePDF, { usePDF } from "react-to-pdf";
import { getResult } from "../../api/services/test";
import { icons, logo } from "../../assets";
import CircularValueIndicator from "../../components/CircularWithValueLabel";
import ResultLoading from "./resultLoading";

const Results = () => {
  const { id } = useParams();
  const [view, setView] = useState(false);
  const { targetRef } = usePDF({ filename: "result.pdf" });

  const options = {
    page: {
      margin: {
        top: 5,
        right: 5,
        bottom: 10,
        left: 5,
      },
      format: "A4",
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
        ignoreElements: (element) => {
          return element.id === "buttonGroup";
        },
      },
    },
  };

  const getTargetElement = () => document.getElementById("pdf-content");

  const { data, isLoading } = useQuery(["getResult", id], getResult);

  return (
    <>
      {isLoading ? (
        <ResultLoading />
      ) : (
        <Box
          id="pdf-content"
          ref={targetRef}
          sx={{
            width: "100%",
            py: 3,
            minHeight: `calc(100vh - ${"64px"})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: !view ? "center" : "flex-start",
            gap: 3,
          }}
        >
          <Box
            id="overview"
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
              boxShadow: "0px 6px 16px rgba(33, 70, 131, 0.16)",
              border: "1px solid #cecece74",
              borderRadius: "15px",
              width: "100%",
              maxWidth: "1440px",
              p: 3,
              gap: 1,
              mx: "auto",
            }}
          >
            <img src={logo} width={180} height={70} alt="logo" />
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Test Results
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                py: 1,
                alignItems: "center",
                flexDirection: { sx: "column", sm: "row", lg: "row" },
              }}
            >
              <Card
                title={"Questions attempted"}
                value={`${data?.data?.attemptedQuestions}/${data?.data?.totalQuestions}`}
              />
              <Box
                className="card"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  boxShadow: "0px 6px 16px rgba(33, 70, 131, 0.16)",
                  px: 3,
                  height: "250px",
                  justifyContent: "center",
                  borderRadius: "10px",
                  width: "250px",
                  alignItems: "center",
                }}
              >
                <CircularValueIndicator
                  percentage={Math.round(data?.data?.percentage * 100) / 100}
                />
                <Typography sx={{ fontFamily: "figtree_semibold" }}>
                  {"Your Score"}
                </Typography>
              </Box>

              <Card
                title={"Result"}
                value={`${data?.data?.result}`}
                color={data?.data?.result === "P" ? "green" : "red"}
              />
            </Box>
            <Box
              sx={{
                width: "90%",
                py: 1,
                alignItems: "flex-start",
                textAlign: "start",
              }}
            >
              <Typography
                sx={{ color: "#2F468C", fontFamily: "figtree_semibold" }}
                variant="body1"
              >
                Test Details
              </Typography>
              <Grid container spacing={2} sx={{ py: 1 }}>
                <Grid item xs={12} sm={6} lg={3}>
                  <LabelValue
                    label={"Test name :"}
                    value={data?.data?.exam?.name}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <LabelValue
                    label={"Duration :"}
                    value={`${data?.data?.exam?.duration} Min`}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <LabelValue
                    label={"Total Questions:"}
                    value={data?.data?.exam?.maxQuestions}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <LabelValue
                    label={"Pass Percentage:"}
                    value={
                      Math.round(data?.data?.exam?.passPercentage * 100) / 100
                    }
                  />
                </Grid>
                {data?.data?.exam?.parts?.map((item: any, index: any) => (
                  <Grid item xs={12} sm={6} lg={3} key={index}>
                    <LabelValue
                      label={`Part-${index + 1}:`}
                      value={item?.title}
                    />
                  </Grid>
                ))}
              </Grid>
              <Box
                id="buttonGroup"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  pt: 2,
                  justifyContent: "flex-end",
                }}
              >
                {!view && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "max-content",
                      px: 2,
                      fontSize: "14px",
                      fontWeight: 200,
                    }}
                    onClick={() => setView(true)}
                  >
                    View Full Test Report
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{ width: "max-content", px: 2, fontSize: "14px" }}
                  startIcon={<GetAppIcon sx={{ ml: 1 }} />}
                  // onClick={() => toPDF()}
                  onClick={() => generatePDF(getTargetElement, options)}
                >
                  Download Test Report
                </Button>
              </Box>
            </Box>
          </Box>
          {view && (
            <>
              {data?.data?.questionResponses?.map((response: any) => (
                <Box
                  className="part"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 6px 16px rgba(33, 70, 131, 0.16)",
                    borderRadius: "15px",
                    width: "100%",
                    maxWidth: "1440px",
                    p: 3,
                    gap: 2,
                    mx: "auto",
                  }}
                >
                  <Typography
                    sx={{ color: "#F4347F", fontSize: "22px", fontWeight: 600 }}
                  >
                    {response?.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid #2222221A",
                      borderRadius: "10px",
                      p: 2,
                    }}
                  >
                    <PartLabelValue
                      label={"Questions attempted"}
                      value={`${response?.partQuestionsAttempted}/${response?.partTotalQuestions}`}
                      color={"#2F468C"}
                    />
                    <PartLabelValue
                      label={"Correct answers"}
                      value={`${response?.partCorrectAnswers}`}
                      color={"#7CB343"}
                    />
                    <PartLabelValue
                      label={"Wrong answers"}
                      value={`${response?.partWrongAnswers}`}
                      color={"#E53935"}
                    />
                    <PartLabelValue
                      label={`${response?.title} Percentage`}
                      value={`${
                        Math.round(response?.partPercentage * 100) / 100
                      }%`}
                      color={"#2F468C"}
                    />
                  </Box>
                  {response?.questionAnswerData?.map(
                    (question: any, index: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          border: "1px solid #2222221A",
                          borderRadius: "10px",
                          p: 2,
                        }}
                      >
                        <Typography variant="body2">{`Question ${
                          index + 1
                        }`}</Typography>
                        <Typography variant="body1">
                          {question?.question?.question}
                        </Typography>
                        <Grid container spacing={1} sx={{ py: 1 }}>
                          {question?.question?.options?.map(
                            (option: any, index: any) => (
                              <Grid
                                item
                                xs={6}
                                key={index}
                                sx={{
                                  display: "flex",
                                  gap: "3px",
                                  alignItems: "baseline",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  color={
                                    option?.isCorrect
                                      ? "green"
                                      : !option?.isCorrect &&
                                        question?.userAnswer === option?.option
                                      ? "red"
                                      : "black"
                                  }
                                >{`${index + 1}. ${
                                  option?.option
                                }`}</Typography>
                                {option?.isCorrect &&
                                question?.userAnswer === option?.option ? (
                                  <img
                                    src={icons.correct}
                                    alt="correct"
                                    height={"11px"}
                                    width={"12px"}
                                  />
                                ) : !option?.isCorrect &&
                                  question?.userAnswer === option?.option ? (
                                  <img
                                    src={icons.wrong}
                                    alt="wrong"
                                    height={"11px"}
                                    width={"12px"}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            )
                          )}
                        </Grid>
                        <Divider sx={{ opacity: 0.5, my: 1 }} />
                        <Typography
                          sx={{ color: "#2F468C", fontSize: "16px", pt: 1 }}
                          variant="h5"
                        >
                          Explanation
                        </Typography>
                        <Typography sx={{ py: 1 }}>
                          {question?.question?.explanation}
                        </Typography>
                      </Box>
                    )
                  )}
                </Box>
              ))}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default Results;

const Card = ({ title, value, color = "#2F468C" }) => {
  return (
    <Box
      className="card"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        boxShadow: "0px 6px 16px rgba(33, 70, 131, 0.16)",
        border: "1px solid #cecece76",
        px: 3,
        height: "180px",
        justifyContent: "center",
        borderRadius: "10px",
        width: "210px",
      }}
    >
      <Typography
        variant="h4"
        sx={{ color: color, fontWeight: 700, fontSize: "26px" }}
      >
        {value}
      </Typography>
      <Typography sx={{ fontFamily: "figtree_semibold" }}>{title}</Typography>
    </Box>
  );
};

const LabelValue = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
      }}
    >
      <Typography
        variant="body1"
        sx={{ opacity: 0.8, fontSize: "16px", fontFamily: "figtree_semibold" }}
      >
        {label}
      </Typography>
      <Typography variant="body1" sx={{ fontFamily: "figtree_semibold" }}>
        {value}
      </Typography>
    </Box>
  );
};

const PartLabelValue = ({ label, value, color }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <Typography
        variant="body1"
        sx={{ fontSize: "16px", fontFamily: "figtree_semibold" }}
      >
        {label}
      </Typography>
      <Typography
        variant="h4"
        sx={{ fontFamily: "figtree_semibold", color: { color } }}
      >
        {value}
      </Typography>
    </Box>
  );
};
