import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { logo } from "../assets";

function Appbar() {
  const navigate = useNavigate();
  const theme = useTheme();

  const isActive = (path: string) => {
    return window.location.pathname === path;
  };

  const NavMenu = [
    {
      name: "Trade Timetable",
      path: "/trade-timetable",
    },
    {
      name: "Examination Guidelines",
      path: "/examination-guidelines",
    },
    {
      name: "Nearby: Practical's Slot Booking",
      path: "/practical-slot-booking",
    },
    {
      name: "Live Sessions",
      path: "/student-live-sessions",
    },
    {
      name: "Discussion Forum",
      path: "/discussion-forum",
    },
    {
      name: "BLMS Login",
      path: "/blms-login",
    },
  ];

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          boxShadow: "0px 6px 16px rgba(29, 40, 139, 0.16)",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 2,
          py: 1,
        }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "1680px",
            padding: "0 70px",
            "@media (max-width: 1300px)": {
              padding: "0 50px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "60px",
              justifyContent: "space-between",
            }}
          >
            <img
              style={{
                cursor: "pointer",
                maxHeight: "56px",
                maxWidth: "150px",
              }}
              onClick={() => navigate("/")}
              src={logo}
              alt="logo"
              width={220}
              height={80}
            />
            <Stack direction={{ xs: "row" }} spacing={{ xs: 3 }}>
              <a href="https://digitaliti.in/" style={{ cursor: "pointer" }}>
                <Typography>Home</Typography>
              </a>
              {NavMenu?.map((item, index) => (
                <Box
                  onClick={() => navigate(item?.path)}
                  sx={{ cursor: "pointer" }}
                  key={index}
                >
                  <Typography
                    color={
                      isActive(item?.path) ? theme.palette.primary.main : "#000"
                    }
                  >
                    {item?.name}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box>{<Outlet />}</Box>
    </>
  );
}

export default Appbar;
