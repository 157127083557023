import { Box, Divider, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { getCourses } from "../../../../../api/services/learning";
import "../../../../../styles/loader.css";
import { CourseCard } from "./CourseCard";

export const LearingBundleTab = ({ data }: any) => {
  const [checked, setChecked] = useState(false);

  const { data: courseData, isLoading } = useQuery(
    [
      "getCourses",
      data._id,
      {
        status: checked ? "COMPLETED" : "",
      },
    ],
    getCourses
  );

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 6px 16px #21468329",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ p: 2, fontWeight: 600 }}>Active Courses</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pr: 2,
          }}
        >
          <Switch value={checked} onChange={() => setChecked(!checked)} />
          <Typography
            sx={{
              fontSize: "12px",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            Show only Completed Courses
          </Typography>
        </Box>
      </Box>
      <Divider />
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className="loader" />
        </Box>
      ) : courseData?.data?.courses?.length > 0 ? (
        courseData?.data?.courses?.map((item: any, index: any) => (
          <>
            <CourseCard data={item} bundle={data?._id} />
            {index < courseData?.data?.courses?.length - 1 && <Divider />}
          </>
        ))
      ) : (
        <Typography
          sx={{ py: 4, textAlign: "center", fontSize: "16px", fontWeight: 600 }}
        >
          No Data
        </Typography>
      )}
    </Box>
  );
};
