import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getCourses } from "../../../api/services";
import { NoData } from "../../../assets";
import FormOutlinedSearch from "../../../components/FormFields/FormOutlinedSearch";
import { Loader } from "../../../components/loader";
import useDebounce from "../../../components/Searchdebouncehook";
import { Height } from "@mui/icons-material";

function Search() {
  const [search, setSearch] = useState<any>("");
  const { control, watch } = useForm();
  const { data, isLoading } = useQuery(
    ["getCourses", { search: useDebounce(watch("search"), 1000) }],
    getCourses
  );
  return (
    <Box
      sx={{
        mx: "auto",
        mt: 3,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "60vw" }}>
        <FormOutlinedSearch control={control} name="search" size="small" placeholder="Search for a course"/>
      </Box>
      {isLoading ? (
        <Loader />
      ) : data?.data?.count > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              alignContent: "center",
              width: "1250px",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
              },
              pb: { xs: 8, sm: 8, md: 8, lg: 0 },
            }}
          >
            {data?.data?.data?.map((item: any, index: any) => (
              <Card bundle={item} key={index} />
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ alignItems: "center", width: "500px", pt: 2 }}>
          <img src={NoData} alt="No Data" height={"100%"} width={"100%"} />
          <Typography sx={{ textAlign: "center", p: 2 }}>
            No Courses Found
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Search;

function Card({ bundle, sx }: any) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 6px #11234B29",
        border: "1px solid #cecece76",
        width: "300px",
        minHeight: "280px",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "15px",
        ...sx,
      }}
      onClick={() =>
        navigate(
          `/bundles/${bundle?.bundle?.trade?._id}/course-curriculum/${bundle?._id}`,
          {
            state: { bundleId: bundle?.bundle?._id },
          }
        )
      }
    >
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box sx={{ width: "300px", height: "180px" }}>
          <img
            src={bundle?.imageUrl}
            alt="course img"
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "contain", borderRadius: "15px" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "#2F468C",
            padding: "6px",
            borderRadius: "0px 15px",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "12px", px: 1 }}>
            ITI_{bundle?.bundle?.trade?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: "#2F468C",
            p: "6px",
            borderRadius: "0px 15px 0px 0px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "12px" }}>
            Online Course
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3px",
          p: 1,
          px: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontSize: "16px" }}>
          {`${bundle?.bundle?.trade?.code} - ${bundle?.title} ${bundle?.bundle?.year?.name}`}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "16px" }}>
          {`Duration: ${Math.round((bundle?.duration / 60) * 100) / 100} Min`}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "16px" }}>
          {`Instructor: ${bundle?.instructor?.name}`}
        </Typography>
        {/* 
        <Box
          sx={{
            fontFamily: "figtree_medium",
            "& *": { p: 0, m: 0 },
            fontSize: "14px",
            opacity: 0.8,
            textOverflow: "ellipsis",
            pt: "3px",
          }}
          dangerouslySetInnerHTML={{
            __html:
              bundle?.description?.length > 40
                ? bundle?.description.substring(0, 40) + "..."
                : bundle?.description,
          }}
        ></Box> */}
      </Box>
    </Box>
  );
}
