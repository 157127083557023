import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginBanner from "../../assets/images/loginbanner.png";
import { login } from "./service";
import { StyledText } from "./styles";
const LoginContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundImage: `url(${LoginBanner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { mutate } = useMutation(login, {
    onSuccess: (res) => {
      navigate("/");
    },
  });
  const handleSubmit = () => {
    if (!username && !password) {
      return toast.error("Please enter a username && password");
    }
    mutate({ username, password, type: "STUDENT" });
  };
  return (
    <>
      <LoginContainer container>
        <Box sx={{ paddingLeft: "10%" }}>
          <StyledText sx={{ fontSize: "54px" }}>
            Digital ITI -Blended
          </StyledText>
          <StyledText sx={{ fontSize: "54px" }}>
            Learning Management System
          </StyledText>
          <StyledText sx={{ opacity: "0.8", fontSize: "20px" }}>
            When the student is ready, the master appears.
          </StyledText>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            width: "30vw",
            height: "45%",
            borderRadius: "10px",
            padding: "25px",
            marginRight: "5%",
          }}
        >
          <Typography variant="h4">Login</Typography>
          <Stack gap={4} mt={5}>
            <TextField
              label="User Name"
              placeholder="Enter UserName"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <TextField
              label="Password"
              placeholder="Enter Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Stack>
          <Stack mt={4}>
            <Button
              variant="contained"
              sx={{ height: "50px" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </LoginContainer>
    </>
  );
}

export default LoginPage;
