import { Box } from "@mui/material";
import TabsContainer from "../../../../components/TabsContainer";
import DET from "./components/DET";
import Trade from "./components/Trade";

const MyNoticeboard = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <TabsContainer
        tabs={[
          {
            component: <DET />,
            key: "DET Notice Board",
            label: "DET Notice Board",
          },
          {
            component: <Trade />,
            key: "Trade Specific Notice Board",
            label: "Trade Specific Notice Board",
          },
        ]}
      />
    </Box>
  );
};

export default MyNoticeboard;
