import { Box, Divider, Typography } from "@mui/material";

import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getStudentLiveSessions } from "../../../../../api/services/liveSession";
import { Loader } from "../../../../../components/loader";
import Continue from "../../../components/Continue";
import CalendarComponent from "./CalendarComponent";

const DETLive = () => {
  const [events, setEvents] = useState<any>([]);
  const { data, isLoading } = useQuery(
    [
      "live-session",
      {
        type: "DET",
      },
    ],
    getStudentLiveSessions
  );

  useEffect(() => {
    const eventData = data?.data?.sessions?.map((item) => {
      return {
        start: moment(item?.startTime).toDate(),
        end: moment(item?.endTime).toDate(),
        title: item?.title,
        link: item?.link,
      };
    });

    setEvents(eventData || []);
  }, [data]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box sx={{ boxShadow: "0px 6px 16px #21468329" }}>
        <Typography sx={{ p: 2, fontWeight: 600 }}>
          DET Live Sessions
        </Typography>
        <Divider />
        {isLoading ? <Loader /> : <CalendarComponent events={events} />}
      </Box>
      <Continue />
    </Box>
  );
};

export default DETLive;
