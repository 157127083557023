export const DashboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.068"
    height="21.068"
    viewBox="0 0 21.068 21.068"
  >
    <g id="category-2" transform="translate(0.5 0.5)">
      <path
        id="Vector"
        d="M2.509,6.689H4.181A2.217,2.217,0,0,0,6.689,4.181V2.509A2.217,2.217,0,0,0,4.181,0H2.509A2.217,2.217,0,0,0,0,2.509V4.181A2.217,2.217,0,0,0,2.509,6.689Z"
        transform="translate(11.706 1.672)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M2.509,6.689H4.181A2.217,2.217,0,0,0,6.689,4.181V2.509A2.217,2.217,0,0,0,4.181,0H2.509A2.217,2.217,0,0,0,0,2.509V4.181A2.217,2.217,0,0,0,2.509,6.689Z"
        transform="translate(1.672 11.706)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M6.689,3.345A3.345,3.345,0,1,1,3.345,0,3.345,3.345,0,0,1,6.689,3.345Z"
        transform="translate(1.672 1.672)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M6.689,3.345A3.345,3.345,0,1,1,3.345,0,3.345,3.345,0,0,1,6.689,3.345Z"
        transform="translate(11.706 11.706)"
        fill="none"
        stroke="#fff"
        stroke-width="1"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M0,0H20.068V20.068H0Z"
        fill="none"
        stroke="#fff"
        stroke-width="1"
        opacity="0"
      />
    </g>
  </svg>
);

export const MyLearning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="book"
    width="20.068"
    height="20.068"
    viewBox="0 0 20.068 20.068"
  >
    <path
      id="Vector"
      d="M16.724,11.771V1.679A1.647,1.647,0,0,0,14.909.015h-.05A15.763,15.763,0,0,0,8.947,2L8.8,2.088a.926.926,0,0,1-.886,0L7.71,1.963A15.684,15.684,0,0,0,1.806.006,1.645,1.645,0,0,0,0,1.67v10.1a1.718,1.718,0,0,0,1.455,1.656l.242.033A21.484,21.484,0,0,1,7.919,15.5l.033.017a.9.9,0,0,0,.8,0,21.29,21.29,0,0,1,6.238-2.057l.276-.033A1.718,1.718,0,0,0,16.724,11.771Z"
      transform="translate(1.672 2.226)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M0,0V12.543"
      transform="translate(10.034 4.591)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M1.881,0H0"
      transform="translate(4.599 7.099)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M2.509,0H0"
      transform="translate(4.599 9.608)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M0,0H20.068V20.068H0Z"
      transform="translate(20.068 20.068) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const MyTest = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="note-2"
    width="20.07"
    height="20.07"
    viewBox="0 0 20.07 20.07"
  >
    <path
      id="Vector"
      d="M12.285,6.638l-.82,3.5c-.7,3.019-2.091,4.24-4.7,3.989A8.792,8.792,0,0,1,5.412,13.9l-1.4-.334C.52,12.735-.559,11.012.26,7.517l.82-3.5A8.759,8.759,0,0,1,1.7,2.173C2.677.149,4.341-.394,7.134.266l1.4.326C12.035,1.412,13.1,3.143,12.285,6.638Z"
      transform="translate(5.827 2.092)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M10.933,11.95a7.837,7.837,0,0,1-1.965.9l-1.321.435c-3.32,1.07-5.068.176-6.146-3.144L.43,6.84C-.641,3.521.246,1.764,3.566.694L4.887.259A8.549,8.549,0,0,1,5.865,0a8.759,8.759,0,0,0-.619,1.84l-.82,3.5c-.82,3.5.259,5.218,3.746,6.046l1.4.334A8.792,8.792,0,0,0,10.933,11.95Z"
      transform="translate(1.661 4.265)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M0,0,4.056,1.029"
      transform="translate(10.57 7.133)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M0,0,2.425.619"
      transform="translate(9.75 10.369)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M0,0H20.07V20.07H0Z"
      transform="translate(20.07 20.07) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const MyEbook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="book"
    width="19.112"
    height="19.112"
    viewBox="0 0 19.112 19.112"
  >
    <path
      id="Vector"
      d="M0,12.741V3.982C0,.8.8,0,3.982,0H9.556c3.185,0,3.982.8,3.982,3.982v7.963c0,.111,0,.223-.008.334"
      transform="translate(2.787 1.593)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M2.27,0H13.538V2.787a2.79,2.79,0,0,1-2.787,2.787H2.787A2.79,2.79,0,0,1,0,2.787V2.27A2.274,2.274,0,0,1,2.27,0Z"
      transform="translate(2.787 11.945)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M0,0H6.371"
      transform="translate(6.371 5.574)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M0,0H3.982"
      transform="translate(6.371 8.362)"
      fill="none"
      stroke="#fff"
      stroke-width="1"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M0,0H19.112V19.112H0Z"
      transform="translate(19.112 19.112) rotate(180)"
      fill="none"
      opacity="0"
    />
  </svg>
);

export const ProfileIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g id="mode-portrait_1_" data-name="mode-portrait (1)" opacity="0.5">
          <path
            id="Path_17999"
            data-name="Path 17999"
            d="M23,16a1,1,0,0,0-1,1v2a3,3,0,0,1-3,3H17a1,1,0,0,0,0,2h2a5.006,5.006,0,0,0,5-5V17A1,1,0,0,0,23,16Z"
          />
          <path
            id="Path_18000"
            data-name="Path 18000"
            d="M1,8A1,1,0,0,0,2,7V5A3,3,0,0,1,5,2H7A1,1,0,1,0,7,0H5A5.006,5.006,0,0,0,0,5V7A1,1,0,0,0,1,8Z"
          />
          <path
            id="Path_18001"
            data-name="Path 18001"
            d="M7,22H5a3,3,0,0,1-3-3V17a1,1,0,1,0-2,0v2a5.006,5.006,0,0,0,5,5H7a1,1,0,0,0,0-2Z"
          />
          <path
            id="Path_18002"
            data-name="Path 18002"
            d="M19,0H17a1,1,0,0,0,0,2h2a3,3,0,0,1,3,3V7a1,1,0,0,0,2,0V5A5.006,5.006,0,0,0,19,0Z"
          />
          <path
            id="Path_18003"
            data-name="Path 18003"
            d="M12,11A4,4,0,1,0,8,7a4,4,0,0,0,4,4Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,12,5Z"
          />
          <path
            id="Path_18004"
            data-name="Path 18004"
            d="M18,20a1,1,0,0,0,1-1,6.006,6.006,0,0,0-6-6H11a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0,4,4,0,0,1,4-4h2a4,4,0,0,1,4,4A1,1,0,0,0,18,20Z"
          />
        </g>
      </svg>
    </>
  );
};

export const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.504"
    height="18.505"
    viewBox="0 0 18.504 18.505"
  >
    <path
      id="a6885eb6e001cb4ac46645863295fba3"
      d="M18.171,16.032c-.571,1.736-2.809,2.6-4.408,2.457A14.058,14.058,0,0,1,7.4,15.858,20.958,20.958,0,0,1,.819,8C-.2,5.83-.433,3.156,1.088,1.173A2.616,2.616,0,0,1,3.173,0C4.441-.058,4.619.667,5.054,1.8c.324.845.757,1.707,1,2.583.453,1.635-1.13,1.7-1.33,3.038-.123.843.9,1.973,1.358,2.573A12.466,12.466,0,0,0,9.3,12.957c.7.443,1.836,1.241,2.64.8,1.239-.678,1.123-2.767,2.853-2.06a27.868,27.868,0,0,1,2.621,1.353c1.324.712,1.262,1.45.757,2.982h0"
      transform="translate(0)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);

export const MailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.648"
    height="13.36"
    viewBox="0 0 19.648 13.36"
  >
    <g id="b764bc22ebe699cd0c80825f63030573" transform="translate(-2 -6)">
      <path
        id="Path_682"
        data-name="Path 682"
        d="M2,7.42V18.558l5.569-5.569Z"
        transform="translate(0 -0.304)"
        fill="#fff"
      />
      <path
        id="Path_683"
        data-name="Path 683"
        d="M3.408,6l7.05,7.05a2.414,2.414,0,0,0,3.334,0L20.842,6Z"
        transform="translate(-0.301)"
        fill="#fff"
      />
      <path
        id="Path_684"
        data-name="Path 684"
        d="M14.906,16.285a3.927,3.927,0,0,1-5.556,0l-.365-.365L3.42,21.484H20.836L15.271,15.92Z"
        transform="translate(-0.304 -2.124)"
        fill="#fff"
      />
      <path
        id="Path_685"
        data-name="Path 685"
        d="M19.914,12.989l5.569,5.569V7.42Z"
        transform="translate(-3.835 -0.304)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const LocationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20.025"
    viewBox="0 0 16 20.025"
  >
    <path
      id="_13138301ca03497429dfccb20d76e61b"
      data-name="13138301ca03497429dfccb20d76e61b"
      d="M12,2a8.024,8.024,0,0,0-8,8c0,5.4,7,11.5,7.3,11.8a1.051,1.051,0,0,0,1.3,0C13,21.5,20,15.4,20,10A8.024,8.024,0,0,0,12,2Zm0,12a4,4,0,1,1,4-4A4.012,4.012,0,0,1,12,14Z"
      transform="translate(-4 -2)"
      fill="#fff"
    />
  </svg>
);

export const VideoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="play-alt"
      d="M19,24H5a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H19a5.006,5.006,0,0,1,5,5V19A5.006,5.006,0,0,1,19,24ZM5,2A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3ZM9.342,17.005a2.368,2.368,0,0,1-1.186-.323,2.313,2.313,0,0,1-1.164-2.021V9.339a2.337,2.337,0,0,1,3.5-2.029L15.77,9.945a2.336,2.336,0,0,1,.049,4.084l-5.376,2.687a2.2,2.2,0,0,1-1.1.289Zm-.025-8a.314.314,0,0,0-.157.042.327.327,0,0,0-.168.292v5.322a.337.337,0,0,0,.5.293l5.376-2.688a.314.314,0,0,0,.12-.266.325.325,0,0,0-.169-.292L9.545,9.073a.462.462,0,0,0-.228-.068Z"
      opacity="0.7"
    />
  </svg>
);

export const BlueStare = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19.363"
    viewBox="0 0 20 19.363"
  >
    <path
      id="star"
      d="M19.868,7.361a2.65,2.65,0,0,0-2.556-1.855H13.67L12.564,2.059a2.69,2.69,0,0,0-5.122,0L6.336,5.506H2.694a2.69,2.69,0,0,0-1.584,4.861l2.964,2.167-1.127,3.49a2.69,2.69,0,0,0,4.154,3L10,16.884l2.9,2.133a2.69,2.69,0,0,0,4.153-2.993l-1.127-3.49L18.9,10.367a2.648,2.648,0,0,0,.969-3.005ZM17.915,9.021l-3.454,2.525a.833.833,0,0,0-.3.93l1.313,4.059a1.023,1.023,0,0,1-1.579,1.138l-3.4-2.5a.833.833,0,0,0-.987,0l-3.4,2.5A1.023,1.023,0,0,1,4.53,16.534l1.317-4.059a.833.833,0,0,0-.3-.93L2.091,9.021a1.023,1.023,0,0,1,.6-1.848H6.945a.833.833,0,0,0,.793-.578L9.03,2.568a1.023,1.023,0,0,1,1.947,0l1.292,4.026a.833.833,0,0,0,.793.578h4.251a1.023,1.023,0,0,1,.6,1.848Z"
      transform="translate(-0.008 -0.19)"
      fill="#2f468c"
    />
  </svg>
);

// export const LeftArrow=()=>{
//   <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
//     <g id="Group_9303" data-name="Group 9303" transform="translate(-786 -3665)">
//       <rect id="Rectangle_7077" data-name="Rectangle 7077" width="50" height="50" rx="10" transform="translate(786 3665)" fill="#f2f2f2"/>
//       <path id="_05d672bf2ec15f434a544f7a2214100f" data-name="05d672bf2ec15f434a544f7a2214100f" d="M22.5,11.5l-7,7-7-7" transform="translate(826 3675) rotate(90)" fill="none" stroke="#f4347f" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
//     </g>
//   </svg>
// }
