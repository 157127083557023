import { Box, Typography } from "@mui/material";

export const TitleBox = ({ title, value, sx = {} }: any) => {
  return (
    <Box width="100%" sx={{ ...sx }}>
      <Typography variant="h4" fontSize="14px" sx={{ opacity: "0.6" }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: "16px", fontFamily: "figtree_semibold" }}>
        {value}
      </Typography>
    </Box>
  );
};
