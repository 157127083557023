import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import Banner from "../../../../assets/images/bundler.png";
import Courses from "./Courses";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOneCourseBundle } from "../../../../api/services";
import { Loader } from "../../../../components/loader";

function BundlesPage() {
  const { bundleid } = useParams();
  const { data: bundleData, isLoading } = useQuery(
    ["getOneCourseBundle", bundleid],
    getOneCourseBundle
  );

  function formatDuration(seconds) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    let formattedDuration = "";

    if (hrs > 0) {
      formattedDuration += `${hrs} Hrs `;
    }
    if (mins > 0) {
      formattedDuration += `${mins} Min `;
    }
    formattedDuration += `${secs} Sec`;

    return formattedDuration.trim();
  }

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <VideoPlayer
            tradeCode={bundleData?.data?.trade?.code}
            year={bundleData?.data?.year?.name}
            bundleid={bundleid}
            bundleName={bundleData?.data?.bundle?.name}
            tradename={bundleData?.data?.trade?.name}
            media={bundleData?.data?.imageUrl}
            course_count={bundleData?.data?.overview?.totalCourses}
            exams_count={bundleData?.data?.overview?.totalExams}
            videos_count={bundleData?.data?.overview?.totalVideos}
            duriation={formatDuration(
              bundleData?.data?.overview?.totalDuration
            )}
          />
          <Box
            sx={{
              width: "80%",
              margin: "auto",
              pb: { xs: 6, sm: 5, md: 3, lg: 1 },
            }}
          >
            <Courses
              description={bundleData?.data?.description}
              courses={bundleData?.data?.courses}
              bundleId={bundleid}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default BundlesPage;

export const CardLable = (props) => {
  return (
    <Stack sx={{ padding: "10px", gap: "3px" }}>
      <Typography
        sx={{
          color: "white",
          fontSize: {
            xs: "12.5px",
            sm: "19px",
            lg: "21px",
            xl: "21px",
          },
        }}
        variant="h5"
      >
        {props.name}
      </Typography>
      <Typography
        sx={{
          color: "white",
          fontSize: {
            xs: "15px",
            sm: "19px",
            lg: "21px",
            xl: "21px",
          },
        }}
        variant="h4"
      >
        {props.count}
      </Typography>
    </Stack>
  );
};

export const VideoPlayer = (props) => (
  <Container>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: {
          xs: "column",
          sm: "column",
          lg: "row",
          xl: "row",
        },
        justifyContent: {
          xs: "center",
          sm: "center",
          lg: "space-between",
          xl: "space-between",
        },
        gap: 2,
        width: "80%",
        marginX: "auto",
      }}
    >
      <Stack gap={"20px"}>
        <StyledBox>
          <Typography color={"white"}>{`ITI_${props.tradename}`}</Typography>
        </StyledBox>
        <Typography
          sx={{
            fontSize: {
              xs: "20px",
              sm: "30px",
              lg: "40px",
              xl: "30px",
            },
          }}
          color={"white"}
          variant="h5"
        >
          {`${props.tradeCode} - ${props.bundleName} ${props.year}`}
        </Typography>
      </Stack>

      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "70%",
            lg: "40%",
            xl: "40%",
          },
          marginBottom: {
            xs: "25px",
            sm: "25px",
            lg: "0",
            xl: "0",
          },
          backgroundColor: "white",
          height: { xs: "30vh", lg: "40vh" },
          borderRadius: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={props?.media}
          alt="Demo Video/Image We will Upload"
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "contain", borderRadius: "14px" }}
        />
      </Box>
    </Box>
    <StyledColorBox>
      <Grid container>
        <Grid item xs={3}>
          <CardLable name={"Total Courses"} count={props.course_count} />
        </Grid>
        <Grid item xs={3} sx={{ borderLeft: "1px solid white", px: 1 }}>
          <CardLable name={"Total Videos"} count={props.videos_count} />
        </Grid>
        <Grid item xs={3} sx={{ borderLeft: "1px solid white", px: 1 }}>
          <CardLable name={"Number of Exams"} count={props.exams_count} />
        </Grid>
        <Grid item xs={3} sx={{ borderLeft: "1px solid white", px: 1 }}>
          <CardLable name={"Duration"} count={props.duriation} />
        </Grid>
      </Grid>
    </StyledColorBox>
  </Container>
);

export const Container = styled(Box)(({ theme }) => ({
  height: "80vh",
  backgroundImage: `url(${Banner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  objectFit: "fill",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export const StyledBox = styled(Box)({
  borderRadius: "20px",
  backgroundColor: "#2F468C",
  color: "#FFFFFF",
  padding: "5px 20px 5px 20px",
  width: "max-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledColorBox = styled(Box)({
  backgroundColor: "#2F468C",
  height: "125px",
  position: "absolute",
  bottom: "-10%",
  borderRadius: "10px",
  minWidth: "80%",
  color: "white",
  padding: "10px",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
});
