import {
  Box,
  Divider,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { facebook, instagram, twitter, youtube } from "../../../assets";
import { LocationIcon, MailIcon, PhoneIcon } from "../../../assets/icons/Icons";
import logo from "../../../assets/images/whitelogo.png";

function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      sx={{
        backgroundColor: "#162658",
        position: "relative",
        paddingLeft: isSmallScreen ? "2%" : "7%",
        paddingY: { xs: 8, sm: 6, md: 4, lg: 3 },
      }}
    >
      <Stack>
        <Box sx={{ width: "90vw", margin: "auto" }}>
          <Box sx={{}}>
            <img src={logo} alt="logo" height={"50px"} />
          </Box>
        </Box>
      </Stack>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        height={"50%"}
        justifyContent={"space-between"}
        width={"90%"}
      >
        <Stack padding={3} paddingTop={4} justifyContent={"flex-start"}>
          <StyledHeader variant="h4">Contact Us</StyledHeader>
          <StyledBox gap={1} padding={1} alignItems={"center"}>
            <PhoneIcon />
            <Typography color={"white"}>+91 9012345678</Typography>
          </StyledBox>
          <StyledBox padding={1} alignContent={"center"}>
            <MailIcon />
            <Typography color={"white"}>info@digitaliti.in</Typography>
          </StyledBox>
          <StyledBox>
            <LocationIcon />
            <Typography color={"white"} sx={{ maxWidth: "190px" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </Typography>
          </StyledBox>
        </Stack>
        <Stack padding={3} paddingTop={4} justifyContent={"flex-start"}>
          <StyledHeader variant="h4">Social Links</StyledHeader>
          <Stack direction={"row"} gap={2} paddingTop={2} paddingRight={2}>
            <img src={twitter} alt="twitter" style={{ objectFit: "contain" }} />
            <img
              src={facebook}
              alt="facebook"
              style={{ objectFit: "contain" }}
            />
            <img
              src={instagram}
              alt="instagram"
              style={{ objectFit: "contain" }}
            />
            <img src={youtube} alt="youtube" style={{ objectFit: "contain" }} />
          </Stack>
          <StyledHeader paddingTop={4} variant="h4">
            About Us
          </StyledHeader>
          <StyledBox>
            <Link to="https://digitaliti.in/about-digital-iti/">
              <Typography color={"white"}>About Digital ITI</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/recognition/">
              <Typography color={"white"}>Recognition</Typography>
            </Link>
          </StyledBox>
        </Stack>
        <Stack padding={3} justifyContent={"flex-start"}>
          <StyledHeader variant="h4">Programs</StyledHeader>
          <StyledBox gap={1}>
            <Link to="https://digitaliti.in/iti-electrician/">
              <Typography color={"white"}>ITI Electrician</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/iti-fitter/">
              <Typography color={"white"}>ITI Fitter</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/iti-copa/">
              <Typography color={"white"}>ITI COPA</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/iti-diesel-mechanic/">
              <Typography color={"white"}>ITI Diesel Mechanic</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/iti-draughtsman-civil/">
              <Typography color={"white"}>ITI Draughtsm Civil</Typography>
            </Link>
          </StyledBox>
        </Stack>
      </Stack>
      <Stack
        direction={isSmallScreen ? "column" : "row"}
        height={"50%"}
        justifyContent={"space-between"}
        width={"89%"}
      >
        <Stack padding={3} paddingTop={4} justifyContent={"flex-start"}>
          <StyledHeader variant="h4">Student Corner</StyledHeader>
          <StyledBox gap={1} padding={1}>
            <Link to="https://www.blms.digitaliti.in/">
              <Typography color={"white"}>
                Learning Management System
              </Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/trade-timetable">
              <Typography color={"white"}>Trade Time Table</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/examination-guidelines">
              <Typography color={"white"}>Examination Guidelines</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/practical-slot-booking">
              <Typography color={"white"}>
                Choose your practcal center
              </Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/student-live-sessions">
              <Typography color={"white"}>Live Session</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/discussion-forum">
              <Typography color={"white"}>Discussion form</Typography>
            </Link>
          </StyledBox>
        </Stack>
        <Stack padding={3} paddingTop={4} justifyContent={"flex-start"}>
          <StyledHeader variant="h4">Admission</StyledHeader>
          <StyledBox>
            <Link to="https://digitaliti.in/online-admission-process/">
              <Typography color={"white"}>Online Admission Process</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/registration-process/">
              <Typography color={"white"}>Registration Process</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/fee-details/">
              <Typography color={"white"}>Fee Details</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/mandatory-documents/">
              <Typography color={"white"}>Mandatory Documents</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://digitaliti.in/admission-withdraw-process/">
              <Typography color={"white"}>Cancellation process</Typography>
            </Link>
          </StyledBox>
        </Stack>
        <Stack padding={3} justifyContent={"flex-start"}>
          <StyledHeader variant="h4">Quick Links</StyledHeader>
          <StyledBox gap={1}>
            <Link to="https://digitaliti.in/notice-board/">
              <Typography color={"white"}>Notice Borad</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/">
              <Typography color={"white"}>LMS Login</Typography>
            </Link>
          </StyledBox>
          <StyledBox>
            <Link to="https://www.blms.digitaliti.in/registration">
              <Typography color={"white"}>Sign UP</Typography>
            </Link>
          </StyledBox>
        </Stack>
      </Stack>
      <Divider
        sx={{
          mt: 0.5,
          mb: 1,
          border: "1px solid gray",
          width: "90%",
          alignSelf: "center",
        }}
      />
      <StyledBox
        sx={{ justifyContent: "center", padding: "20px", pb: { xs: 8, lg: 0 } }}
      >
        <Typography color={"white"}>
          @2023 Digital-ITI | Copyrights reserved
        </Typography>
      </StyledBox>
    </Stack>
  );
}

export default Footer;

const StyledHeader = styled(Typography)({
  color: "#16ACAB",
});

const StyledBox = styled(Box)({
  display: "flex",
  gap: "5px",
  alignItems: "flex-start",
  padding: "15px 0px 0px 0px",
});
