import { Typography, useTheme } from "@mui/material";
import { StyledFlexBox } from "../../../../styles";

export const TradeCard = ({ image, title, isActive, onClick }: any) => {
  const theme = useTheme();
  return (
    <StyledFlexBox
      sx={{ cursor: "pointer" }}
      justifyContent="center"
      flexDirection="column"
      bgcolor={isActive ? theme.palette.primary.main : "#F2F2F2"}
      borderRadius="15px"
      maxWidth="250px"
      p={2}
      onClick={onClick}
    >
      <img src={image} alt="trade-icon" height={50} width={50} />
      <Typography color={isActive ? "#fff" : "#000"} mt={1}>
        {title}
      </Typography>
    </StyledFlexBox>
  );
};
