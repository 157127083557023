import { Cell, Pie, PieChart } from "recharts";

const CircularValueIndicator = ({ percentage }) => {
  const data = [{ value: percentage }, { value: 100 - percentage }];

  const COLORS = ["#EC407A", "#F8BBDE"];

  return (
    <PieChart width={160} height={160}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        startAngle={90}
        endAngle={-270}
        innerRadius={70}
        outerRadius={80}
        dataKey="value"
        fill="#8884d8"
        stroke="none"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index]} />
        ))}
      </Pie>
      <text
        x="50%"
        y="45%"
        textAnchor="middle"
        dominantBaseline="central"
        color="#2F468C"
        fontSize={26}
        fill="#2F468C"
        fontFamily="figtree_semibold"
        dy={8}
      >
        {`${percentage}%`}
      </text>
    </PieChart>
  );
};

export default CircularValueIndicator;
