import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { icons } from "../assets";

function MyTimer({ expiryTimestamp, EndExam }: any) {
  const { id } = useParams();
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      EndExam(id);
      alert("Test Has Ended!!");
    },
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <img src={icons.chronometer} alt="clock" width={"20px"} height={"20px"} />
      <Box sx={{ fontSize: "20px" }}>
        <Typography display="inline" color={"#f4347f"}>
          {hours}
        </Typography>
        :
        <Typography display="inline" color={"#f4347f"}>
          {minutes}
        </Typography>
        :
        <Typography display="inline" color={"#f4347f"}>
          {seconds}
        </Typography>
      </Box>
    </Box>
  );
}

export default MyTimer;
