import { Box, Divider, Typography } from "@mui/material";
import Continue from "../../components/Continue";
import ExamTable from "./components/ExamTable";

const MyExamTimetable = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box
        sx={{
          boxShadow: "0px 6px 16px #21468329",
          borderRadius: "12px",
          width: "100%",
        }}
      >
        <Typography sx={{ p: 2, fontWeight: "600" }}>
          Download Exam Timetable
        </Typography>
        <Divider />
        <ExamTable />
      </Box>
      <Continue />
    </Box>
  );
};

export default MyExamTimetable;
