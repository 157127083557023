import { http } from "../http";

export const getQuizData = ({ queryKey }: any) => {
  return http.get(`/quiz/${queryKey[1]}`);
};

export const beginQuiz = (postBody: any) => {
  return http.post(`/quiz/begin`, postBody);
};


export const getQuiz = (id: any) => {
  return http.get(`/quiz/user-quiz/${id}`);
};

export const SubmitTheQuiz = (id) => {
  return http.patch(`/quiz/end-quiz/${id}`);
};

export const getQuizStates = (id: any) => {
  return http.get(`/quiz/questions/statistics/${id}`).then((res) => res.data);
};


export const getStudentProfile = () => {
  return http.get(`/students/profile`).then((res) => res.data);
};

export const saveAnswer = (postBody: any) => {
  return http.post("/quiz/user-response", postBody);
};

export function numberToAlphabet(number: any) {
  const alphabetIndex = number + 65;
  return String.fromCharCode(alphabetIndex);
}

export const getExams = ({ queryKey }: any) => {
  return http.get(`/quiz/student/${queryKey[1]}`, {
    params: {
      ...queryKey[2],
    },
  });
};



export const getResponse = ({ queryKey }: any) => {
  return http.get(`/quiz/response`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getResult = ({ queryKey }: any) => {
  return http.get(`/quiz/results/${queryKey[1]}`);
};
