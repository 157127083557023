import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAllDigitalLibrary,
  getStudentTestimonial,
  login,
} from "../../api/services";
import HomeSection from "../../assets/images/headerSection.png";
import LoginBanner from "../../assets/images/newbanner.png";
import FormOutlinedInput from "../../components/FormFields/FormOutlinedInput";
import FormOutlinedPassword from "../../components/FormFields/FormOutlinedPassword";
import { StyledText } from "../LoginPage/styles";
import { StyledMainContentContainer } from "../SignInPages/Sign-in-Layout";
import BlendedLMS from "./Components/BlendedLMS";
import ExploreSection from "./Components/ExploreSection";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import StudentTestimonials from "./Components/StudentTestimonials";

const LoginContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundImage: `url(${LoginBanner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

function NewLoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { control } = useForm();

  const { data: studentTestimonial } = useQuery(
    "student-testimonial",
    getStudentTestimonial
  );
  const { data: exploreData } = useQuery(
    "get-all-digital-library",
    getAllDigitalLibrary
  );

  const { mutate } = useMutation(login, {
    onSuccess: (data) => {
      localStorage.setItem("access_token", data?.data?.access_token);
      window.location.href = "/my-dashboard";
      toast.success("Login Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const res = {
      username: username,
      password: password,
      type: "STUDENT",
    };
    mutate(res);
  };

  return (
    <Box>
      <LoginContainer
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "space-around",
            xl: "space-around",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <Stack
          width={"80%"}
          sx={{
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "center",
              lg: "space-between",
              xl: "space-between",
            },
            pt: { xs: 2, sm: 2, md: 0 },
          }}
        >
          <Stack>
            <StyledText
              sx={{
                fontSize: {
                  xs: "18px",
                  sm: "22px",
                  lg: "24px",
                  xl: "24px",
                },
                textAlign: "left",
                mt: "20px",
                mb: "3px",
                padding: "2.5px",
              }}
            >
              Digital ITI - India's first Digital Vocation Training Initiative
              by Govt. of Telangana
            </StyledText>
          </Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "space-between",
                lg: "space-between",
                xl: "space-between",
              },
            }}
            direction={"row"}
            alignContent={"start"}
          >
            <Stack
              sx={{
                justifyContent: { xs: "center", sm: "center", md: "start" },
                alignItems: { xs: "center", sm: "center", md: "start" },
              }}
            >
              <StyledText
                sx={{ mb: "20px", opacity: "80%", textAlign: "left" }}
              >
                When the student is ready, the master appears.
              </StyledText>
              <Box
                sx={{
                  width: {
                    xs: "90%",
                    sm: "90%",
                    md: "90%",
                    lg: "450px",
                    xl: "550px",
                  },
                }}
              >
                <img
                  style={{
                    width: "100%",
                  }}
                  src={HomeSection}
                  alt="header section"
                />
              </Box>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                alignItems: "center",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "70%",
                  lg: "400px",
                  xl: "450px",
                },
                height: {
                  xs: "60vh",
                  sm: "70vh",
                  lg: "70vh",
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  justifyContent: "center",
                  width: {
                    xs: "90%",
                    sm: "90%",
                    md: "90%",
                    lg: "400px",
                    xl: "450px",
                  },
                  borderRadius: "10px",
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Typography variant="h4" fontSize={"18px"}>
                  Student Login
                </Typography>
                <FormOutlinedInput
                  label="User Name"
                  placeholder="Enter your User Name..."
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  control={control}
                  name="userName"
                />
                <FormOutlinedPassword
                  control={control}
                  label="Password"
                  name="password"
                  placeholder="Enter your Password..."
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <Box
                  textAlign={"center"}
                  sx={{ marginTop: "10px", width: "100%" }}
                >
                  <Button
                    variant="contained"
                    sx={{ height: "50px", width: "100%", mb: 1 }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </LoginContainer>
      <Box>
        <ExploreSection data={exploreData} />
        <BlendedLMS />
        <StudentTestimonials data={studentTestimonial?.data} />
        <Footer />
      </Box>
    </Box>
  );
}

export default NewLoginPage;

export const LoginPageLayout = () => {
  return (
    <>
      <Header />
      <StyledMainContentContainer
        sx={{
          width: `100vw`,
          top: "64px",
          left: 0,
          height: `calc(100vh - ${"64px"})`,
          overflowX: "hidden",
        }}
      >
        <Outlet />
      </StyledMainContentContainer>
    </>
  );
};
