import { Box, Button, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addDoubt,
  getDoubts,
  getTopicComponents,
} from "../../../api/services/learning";
import FormOutlinedInput from "../../../components/FormFields/FormOutlinedInput";
import { Loader } from "../../../components/loader";
import { StyledHead } from "../../../components/styledHead";
import DoubtCard from "./DoubtCard";

const Doubts = () => {
  const { bundleId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryClient = useQueryClient();

  const { control, setValue, getValues } = useForm({
    defaultValues: {
      doubt: "",
    },
  });
  const [checked, setChecked] = useState(false);

  const topicId = searchParams.get("topicId");
  const sectionId = searchParams.get("sectionId");
  const chapterId = searchParams.get("chapterId");

  const { data: doubts, isLoading } = useQuery(
    [
      "getDoubts",
      topicId,
      {
        status: checked ? "RESOLVED" : "UNRESOLVED",
      },
    ],
    getDoubts
  );

  const { mutate } = useMutation(addDoubt, {
    onSuccess: (res: any) => {
      toast.success("Doubt posted successfully");
      setValue("doubt", "");
      queryClient.invalidateQueries("getDoubts");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { data: topicData } = useQuery(
    ["getTopicComponents", topicId],
    getTopicComponents
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handlePostDoubt = () => {
    if (getValues("doubt") === "") {
      toast.error("Enter the Doubt before submiting");
    }
    const postData = {
      bundleId: bundleId,
      subjectId: topicData?.data?.[0]?.subjectId,
      chapterId: chapterId,
      sectionId: sectionId,
      topicId: topicId,
      comment: getValues("doubt"),
    };
    mutate(postData);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              py: 1,
              pb: 3,
              borderBottom: "1px solid #cecece47",
            }}
          >
            <StyledHead head={"Doubts"} color="black" sx={{ height: "30px" }} />
            <FormOutlinedInput
              control={control}
              name="doubt"
              placeholder="Start writing here…"
              multiline={5}
            />
            <Button
              sx={{ width: "150px" }}
              variant="contained"
              onClick={() => handlePostDoubt()}
            >
              Submit
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              py: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 600, color: "#2F468C" }}
            >
              Doubts posted in this course
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Switch value={checked} onChange={handleChange} />
              <Typography
                sx={{
                  fontSize: "12px",
                  alignItems: "center",
                  fontWeight: 600,
                }}
              >
                Show only answered doubts
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {doubts?.data?.count === 0 ? (
              <Typography
                sx={{
                  p: 2,
                  py: 5,
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                No Doubts Found
              </Typography>
            ) : (
              doubts?.data?.data?.map((item: any) => <DoubtCard data={item} />)
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Doubts;
