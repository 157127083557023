import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium";
  control: any;
  style?: any;
  onChange?: (value: any) => void;
  required?: boolean;
  sx?: any;
  readonly?: boolean;
  placeholder?: string;
}

function FormOutlinedPassword(props: Props) {
  const {
    name,
    size = "small",
    control,
    label = "",
    style,
    onChange,
    required = false,
    sx,
    readonly = false,
    placeholder = "",
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ ...sx }}>
          <InputLabel
            sx={{
              opacity: "100%",
              pb: "3px",
            }}
            id={`label-${name}`}
          >
            {label}
          </InputLabel>
          <TextField
            {...field}
            required={required}
            error={Boolean(error)}
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            size={size}
            style={style}
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: readonly }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  style={{ cursor: "pointer" }}
                  onClick={togglePassword}
                  position="start"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Typography
              variant="caption"
              sx={{ pl: "2px", display: "block" }}
              color="rgb(211, 47, 47)"
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}

export default FormOutlinedPassword;
