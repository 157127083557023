import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { getExamTimetable } from "../../../../../api/services";
import { Loader } from "../../../../../components/loader";

const StyledTableCell = (props) => (
  <TableCell
    {...props}
    style={{
      border: "1px solid #e0e0e0",
      textAlign: "center",
      color: "white",
      ...props.sx,
    }}
  />
);

const ExamTable = () => {
  const { data, isLoading } = useQuery("examTimetable", getExamTimetable);

  return (
    <Box sx={{ p: 2 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: "#FF4081" }}>
                <StyledTableCell rowSpan={2}>S.No</StyledTableCell>
                <StyledTableCell rowSpan={2}>Trade Name</StyledTableCell>
                <StyledTableCell colSpan={2}>Regular</StyledTableCell>
                <StyledTableCell colSpan={2}>Supplementary</StyledTableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: "#FF4081" }}>
                <StyledTableCell>Year 1</StyledTableCell>
                <StyledTableCell>Year 2</StyledTableCell>
                <StyledTableCell>Year 1</StyledTableCell>
                <StyledTableCell>Year 2</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell sx={{ color: "black" }}>1</StyledTableCell>
                <StyledTableCell sx={{ color: "black" }}>
                  {data?.data?.trade?.name}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.data?.regular?.[0]?.exams === "" ? (
                    <Typography color={"black"}>Not Uploaded</Typography>
                  ) : (
                    <Button
                      variant="text"
                      disableRipple
                      color="primary"
                      sx={{ p: 0 }}
                      onClick={() => {
                        window.open(data?.data?.regular?.[0]?.exams, "_black");
                      }}
                    >
                      Download
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.data?.regular?.[1]?.exams === "" ? (
                    <Typography color={"black"}>Not Uploaded</Typography>
                  ) : (
                    <Button
                      variant="text"
                      disableRipple
                      color="primary"
                      sx={{ p: 0 }}
                      onClick={() => {
                        window.open(data?.data?.regular?.[1]?.exams, "_black");
                      }}
                    >
                      Download
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.data?.supplementary?.[0]?.exams === "" ? (
                    <Typography color={"black"}>Not Uploaded</Typography>
                  ) : (
                    <Button
                      variant="text"
                      disableRipple
                      color="primary"
                      sx={{ p: 0 }}
                      onClick={() => {
                        window.open(
                          data?.data?.supplementary?.[0]?.exams,
                          "_black"
                        );
                      }}
                    >
                      Download
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {data?.data?.supplementary?.[1]?.exams === "" ? (
                    <Typography color={"black"}>Not Uploaded</Typography>
                  ) : (
                    <Button
                      variant="text"
                      disableRipple
                      color="primary"
                      sx={{ p: 0 }}
                      onClick={() => {
                        window.open(
                          data?.data?.supplementary?.[1]?.exams,
                          "_black"
                        );
                      }}
                    >
                      Download
                    </Button>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ExamTable;
