import { Box, Button, Collapse, Grid, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { getCourse, getOneCourseBundle } from "../../../../api/services";
import { Loader } from "../../../../components/loader";
import LineText from "../../Components/LineText";
import {
  CardLable,
  Container,
  StyledBox,
  StyledColorBox,
} from "../BundlesPage/BundlesPage";
import Curriculum, { AboutInstructor } from "./Curriculum";

function CourseCurriculumPage() {
  const loactionData = useLocation();
  const bundleId = loactionData?.state?.bundleId;
  const { courseid } = useParams();

  const { data: bundleData } = useQuery(
    ["getOneCourseBundle", bundleId],
    getOneCourseBundle
  );

  const { data: courseData, isLoading } = useQuery(
    ["getCourse", courseid],
    getCourse
  );

  function formatDuration(seconds) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    let formattedDuration = "";

    if (hrs > 0) {
      formattedDuration += `${hrs} Hrs `;
    }
    if (mins > 0) {
      formattedDuration += `${mins} Min `;
    }
    formattedDuration += `${secs} Sec`;

    return formattedDuration.trim();
  }

  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <VideoPlayer
            tradeCode={bundleData?.data?.trade?.code}
            year={bundleData?.data?.year?.name}
            tradename={bundleData?.data?.trade?.name}
            title={courseData?.data?.title}
            media={courseData?.data?.imageUrl}
            bundlename={bundleData?.data?.bundle?.name}
            exams_count={courseData?.data?.overview?.totalExams}
            videos_count={courseData?.data?.overview?.totalVideos}
            duriation={formatDuration(
              courseData?.data?.overview?.totalDuration
            )}
          />
          <Box
            sx={{
              width: "80%",
              margin: "auto",
              pb: { xs: 8, sm: 5, md: 3, lg: 0 },
            }}
          >
            <Curriculum />
            <AboutInstructor />
          </Box>
        </>
      )}
    </Box>
  );
}

export default CourseCurriculumPage;
export const VideoPlayer = (props) => (
  <Container>
    <Box
      sx={{
        alignSelf: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: {
          xs: "column",
          sm: "column",
          lg: "row",
          xl: "row",
        },
        justifyContent: {
          xs: "space-around",
          sm: "space-around",
          lg: "space-between",
          xl: "space-between",
        },
        height: "80vh",
        width: "80%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <StyledBox sx={{ mb: 1 }}>
          <Typography color={"white"}>{`ITI_${props.tradename}`}</Typography>
        </StyledBox>
        <Typography color={"white"} variant="h3">
          {props.title}
        </Typography>
        <Typography color={"white"} variant="body1" sx={{ fontSize: "18px" }}>
          {`${props.tradeCode} - ${props.bundlename} ${props.year}`}
        </Typography>
      </Box>

      <Box
        sx={{
          width: {
            xs: "95%",
            sm: "50%",
            lg: "40%",
            xl: "40%",
          },
          marginBottom: {
            xs: "100px",
            sm: "100px",
            lg: "0",
            xl: "0",
          },
          backgroundColor: "white",
          height: {
            xs: "40%",
            sm: "40%",
            lg: "50%",
            xl: "50%",
          },
          borderRadius: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={props?.media}
          alt="Demo Video/Image We will Upload"
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "contain", borderRadius: "14px" }}
        />
      </Box>
    </Box>
    <StyledColorBox>
      <Grid container>
        <Grid item xs={3}>
          <CardLable name={"Course Languages"} count={"1"} />{" "}
        </Grid>
        <Grid item xs={3} sx={{ borderLeft: "1px solid white", px: 1 }}>
          <CardLable name={"Total Videos"} count={props.videos_count} />
        </Grid>
        <Grid item xs={3} sx={{ borderLeft: "1px solid white", px: 1 }}>
          <CardLable name={"Number of Exams"} count={props.exams_count} />
        </Grid>
        <Grid item xs={3} sx={{ borderLeft: "1px solid white", px: 1 }}>
          <CardLable name={"Duration"} count={props.duriation} />
        </Grid>
      </Grid>
    </StyledColorBox>
  </Container>
);

export const Description = ({ description }) => {
  const convertHtmlToString = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const lines = convertHtmlToString(description).split("\n");

  return (
    <Box sx={{ marginTop: "160px" }}>
      <LineText title={"Description"} />
      <Box sx={{ marginY: "20px" }}>
        <Typography>
          <Collapse in={true}>
            {lines.map((line, index) => (
              <div key={index}>{index < 3 || expanded ? line : null}</div>
            ))}
          </Collapse>
        </Typography>
        {lines.length > 3 && !expanded && (
          <Button
            onClick={toggleExpanded}
            variant="outlined"
            sx={{
              marginTop: "10px",
              fontSize: "14px",
              padding: "10px",
            }}
          >
            Show More
          </Button>
        )}
        {expanded && (
          <Button
            onClick={toggleExpanded}
            sx={{
              marginTop: "10px",
              fontSize: "14px",
              padding: "10px",
            }}
            variant="outlined"
            size="small"
          >
            Show Less
          </Button>
        )}
      </Box>
    </Box>
  );
};
export const StyledButton = styled(Box)({
  width: "150px",
  height: "50px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
});

function convertSecondsToHoursAndMinutes(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  return { hours, minutes };
}
