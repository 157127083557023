import {
  Box,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium";
  control: any;
  options: Array<{ label: string; value: string }>;
  style?: any;
  onChange?: (value: any) => void;
  required?: boolean;
  sx?: any;
  color?: string; // Add the color prop
  defaultValue?: string;
}

function FormSelect(props: Props) {
  const {
    name,
    size = "small",
    options,
    control,
    label = "",
    style,
    onChange,
    required = false,
    sx,
    defaultValue,
    color,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ ...sx }}>
          <InputLabel
            sx={{
              opacity: "100%",
              color: color === "white" ? "white" : "inherit", // Apply color prop if it's white
              pb: "3px",
            }}
            id="demo-simple-select-label"
          >
            {label}
          </InputLabel>
          <TextField
            error={Boolean(error)}
            variant="outlined"
            select
            fullWidth
            required={required}
            size={size}
            style={style}
            defaultValue={defaultValue}
            InputLabelProps={{ shrink: false }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                  },
                },
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: color === "white" ? "white" : "inherit",
                },
                "&:hover fieldset": {
                  borderColor: color === "white" ? "white" : "inherit",
                },
                "&.Mui-focused fieldset": {
                  borderColor: color === "white" ? "white" : "inherit",
                },
                color: color === "white" ? "white" : "inherit",
              },
              "& .MuiSvgIcon-root": {
                color: color === "white" ? "white" : "inherit",
              },
            }}
            value={field.value}
            onChange={(e) => {
              if (onChange) onChange(e.target.value);
              field.onChange(e.target.value);
            }}
          >
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          {error && (
            <Typography
              variant="caption"
              sx={{ pl: "2px", display: "block" }}
              color="rgb(211, 47, 47)"
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}

export default FormSelect;
