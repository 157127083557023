import { Avatar } from "@mui/material";

export const Answered = ({ label, onClick = () => {} }) => {
  return (
    <Avatar
      sx={{
        bgcolor: "#689F38",
        width: 34,
        height: 34,
        fontSize: 16,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const NotAnswered = ({ label, onClick = () => {} }) => {
  return (
    <Avatar
      sx={{
        bgcolor: "#A94542",
        width: 34,
        height: 34,
        fontSize: 16,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const Review = ({ label, onClick = () => {} }) => {
  return (
    <Avatar
      sx={{
        bgcolor: "#0093E9",
        width: 34,
        height: 34,
        fontSize: 16,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const NotVisited = ({ label, onClick = () => {} }) => {
  return (
    <Avatar
      sx={{
        bgcolor: "#80d0c7",
        width: 34,
        height: 34,
        fontSize: 16,
        borderRadius: "5px",
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const AnsweredAndReview = ({ label, onClick = () => {} }) => {
  return (
    <Avatar
      sx={{
        bgcolor: "#0393EA",
        width: 34,
        height: 34,
        fontSize: 16,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};
