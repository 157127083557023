import { Box, Button, Stack, Typography, styled } from "@mui/material";
import LineText from "../../../Components/LineText";

function EBooksComponent() {
  return (
    <Box sx={{ marginY: "20px" }}>
      <LineText title={"E-Books"} />
      <EBookCard />
    </Box>
  );
}

export default EBooksComponent;

const EBookCard = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          height: "160px",
          padding: "10px",
          marginY: "20px",
        }}>
        <Box>
          <Box sx={{ display: "flex", gap: "30px" }}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: "120px",
                  sm: "120px",
                  lg: "220px",
                },
                borderRadius: "10px",
                backgroundColor: "#3E348E29",
                height: "140px",
              }}>
              <StyledAbsoleteButton>
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      lg: "20px",
                    },
                  }}>
                  E-Book
                </Typography>
              </StyledAbsoleteButton>
            </Box>
            <Stack
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"10px"}>
              <Box
                sx={{
                  backgroundColor: "#2F468C",

                  padding: "10px",
                  borderRadius: "10px",
                }}>
                <Typography color={"white"}> ITI_Electrician</Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    lg: "20px",
                  },
                }}
                variant="h5">
                E-Book Title
              </Typography>
              <Typography
                sx={{ fontSize: "14px", color: "rgba(34, 34, 34, 0.5)" }}
                variant="subtitle2">
                Started on 23 Jan, 2022
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Button variant="contained" sx={{ paddingX: "30px", paddingY: "23px" }}>
          Start Reading
        </Button>
      </Box>
    </>
  );
};

export const StyledAbsoleteButton = styled(Box)({
  backgroundColor: "#2F468C",
  borderRadius: "0px 10px",
  position: "absolute",
  left: 0,
  bottom: 0,
  padding: "5px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
