import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Avatar, Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getStudentProfile } from "../api/services";
import { getTest, SubmitTheTest } from "../api/services/test";
import ConfirmDialog from "../components/ConfirmDialog";
import MyTimer from "../components/Timer";
import { closeFullscreen, handleKeyDown } from "../pages/Test/instructions";

function TestHeader() {
  const navigate = useNavigate();
  const { id, examId } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDiagol] = useState<boolean>(false);

  const { data } = useQuery("get-single-test", () => getTest(id));

  const time = new Date();
  time.setSeconds(time.getSeconds() + data?.data?.exam?.duration * 60);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    setAnchorEl(null);
    localStorage.clear();
    navigate("/");
  };

  const { data: studentData } = useQuery(
    "studentProfile",
    getStudentProfile
  );

  const { mutate: EndExam } = useMutation(SubmitTheTest, {
    onSuccess: () => {
      toast.success("Test Submitted Successfully");
      closeFullscreen();
      window.removeEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
      window.removeEventListener("keydown", handleKeyDown, true);
      navigate(`/result/${examId}`);
    },
  });

  const handleConfirm = () => {
    EndExam(id);
    setOpenDiagol(false);
    window.removeEventListener("contextmenu", (e) => e.preventDefault());
    window.removeEventListener("keydown", handleKeyDown);
  };

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          boxShadow: "0px 3px 6px #11234B29",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 999,
          px: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="text"
            sx={{
              color: "black",
              p: 0,
              ":hover": { backgroundColor: "transparent" },
            }}
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => {
              setOpenDiagol(true);
            }}
          >
            {data?.data?.exam?.name}
          </Button>
          {data?.data?.exam?.duration && (
            <MyTimer expiryTimestamp={time} EndExam={EndExam} />
          )}
          <Box>
            <IconButton
              disableRipple
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
              sx={{
                fontSize: "2.5rem",
                gap: 1,
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Avatar
                alt={studentData?.data?.name}
                src={studentData?.data?.imageUrl}
              />
              {studentData?.data?.name && (
                <Button
                  disableRipple
                  variant="text"
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{ px: 0, ":hover": { backgroundColor: "transparent" } }}
                >
                  {studentData?.data?.name}
                </Button>
              )}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-trade-timetable");
                }}
              >
                My Dashboard
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-trade-timetable");
                }}
              >
                My Trade Timetable
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-learning");
                }}
              >
                My Learnings
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/online-test");
                }}
              >
                My CBT/Online Test
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/doubts");
                }}
              >
                My Doubts
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/e-books");
                }}
              >
                My E-Books
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/live-sessions");
                }}
              >
                My Live Sessions
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-discussion-forum");
                }}
              >
                My Discussion Forum
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-trade-timetable");
                }}
              >
                My Notice Board
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-trade-timetable");
                }}
              >
                My Exam Timetable
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate("/my-trade-timetable");
                }}
              >
                My Account Details
              </MenuItem>
              <MenuItem onClick={handleSignout} sx={{ color: "#F4347F" }}>
                Sign out
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <ConfirmDialog
          handleClose={() => setOpenDiagol(false)}
          handleConfirm={handleConfirm}
          message={" Are you sure you want to Exit this Test?"}
          open={openDialog}
        />
      </Box>
    </>
  );
}

export default TestHeader;
