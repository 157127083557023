import { AccordionDetails, Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  getCourses,
  getCurriculum,
} from "../../../../../api/services/learning";
import FormOutlinedSelect from "../../../../../components/FormFields/FormOutlinedSelect";
import Continue from "../../../components/Continue";
import { Curriculums } from "./Curriculums";

export const BundleTab = ({ data }: any) => {
  const [course, setCourse] = useState<any>();

  const { data: courses } = useQuery(["getCourses", data?._id], getCourses);
  const courseOptions = courses?.data?.courses?.map((item: any) => {
    return {
      value: item?._id,
      label: item?.title,
    };
  });

  const { control, setValue, getValues } = useForm({
    defaultValues: {
      course: courseOptions?.length > 0 ? courseOptions?.[0]?.value : "",
    },
  });

  useEffect(() => {
    setValue("course", courseOptions?.[0]?.value);
  }, [courses]);

  const { data: curriculum, isLoading } = useQuery(
    ["get-curriculum", course || courseOptions?.[0]?.value],
    getCurriculum
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box>
        <Grid container sx={{ pb: 2 }} spacing={2}>
          <Grid item xs={4}>
            <FormOutlinedSelect
              control={control}
              name="course"
              label="Select Course"
              options={courseOptions || []}
              defaultValue={courseOptions?.[0]?.value || ""}
              onChange={(value) => {
                setCourse(value);
              }}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            backgroundColor: "#2F468C",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 1,
            alignItems: "center",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Typography color={"white"} sx={{ fontSize: "18px" }}>
            {`Curriculum`}
          </Typography>
          {/* <FormSelect
            control={control}
            name="year"
            options={[{ label: "string", value: "string" }]}
            sx={{ width: "175px" }}
            color="white"
          /> */}
        </Box>
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box className="loader" />
          </Box>
        ) : (
          <Curriculums
            data={curriculum?.data?.subjects
              ?.map((item: any) => item.chapters)
              ?.flat()}
            bundleId={data?._id}
            courseId={getValues("course")}
          />
        )}
      </Box>
      <Continue />
    </Box>
  );
};
export const Sections = (data: any) => {
  const navigate = useNavigate();

  return data?.data?.map((cls: any) => (
    <AccordionDetails
      sx={{
        backgroundColor: "#FFFFFF",
        m: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          alignItems: "center",
          // paddingX: "10px",
          cursor: "pointer",
          gap: "10px",
        }}
        onClick={() => {
          navigate(
            `/bundles/course-curriculum/65660618358eb3656d8aea14?topicId=6565d1a3d7e731f1a9d9650f`
          );
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "18px" }}>
          {cls}
        </Typography>
        {/* <Typography>09:04</Typography> */}
      </Box>
    </AccordionDetails>
  ));
};
