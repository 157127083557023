import { Box, Typography, useTheme } from "@mui/material";
import { StyledFlexBox } from "../styles";

export const StyledHead = ({ head, sx, fontSize, color }: any) => {
  const theme = useTheme();
  return (
    <StyledFlexBox gap={2}>
      <Box
        sx={{
          height: "40px",
          width: "4px",
          borderRadius: "0 5px 5px 0",
          bgcolor: theme.palette.primary.main,
          ...sx,
        }}
      />
      <Typography
        variant="h4"
        color={color || theme.palette.primary.main}
        fontSize={fontSize}
      >
        {head}
      </Typography>
    </StyledFlexBox>
  );
};

export const StyledTitle = ({ title }: any) => {
  const theme = useTheme();

  return (
    <StyledFlexBox gap={2}>
      <Box
        sx={{
          height: "80px",
          width: "8px",
          borderRadius: "0 5px 5px 0",
          bgcolor: theme.palette.primary.main,
        }}
      />
      <Typography fontSize="54px">{title}</Typography>
    </StyledFlexBox>
  );
};
