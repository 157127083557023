import { Box, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { getTopicComponents } from "../../../api/services";
import { NoData } from "../../../assets";
import { Loader } from "../../../components/loader";
import { StyledHead } from "../../../components/styledHead";
import Card from "./Card";

const AssignmentAndProject = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const topicId = searchParams.get("topicId");

  const { data, isLoading } = useQuery(
    ["getTopicComponents", topicId],
    getTopicComponents
  );

  const textData = data?.data?.filter((item: any) => item?.type === "TEXT");
  const resourceData = data?.data?.filter(
    (item: any) => item?.type === "RESOURCE"
  );
  const linkData = data?.data?.filter((item: any) => item?.type === "LINK");
  const experimentData = data?.data?.filter(
    (item: any) => item?.type === "EXPERIMENT"
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        ((textData?.length === 0 &&
          resourceData?.length === 0 &&
          linkData?.length === 0 &&
          experimentData?.length === 0) ||
          !data) && (
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {isLoading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80vh",
                }}
              >
                <Box sx={{ alignItems: "center", width: "500px" }}>
                  <img
                    src={NoData}
                    alt="No Data"
                    height={"100%"}
                    width={"100%"}
                  />
                </Box>
                <Typography variant="h4" sx={{ py: 1 }}>
                  No Data Found
                </Typography>
              </Box>
            )}
          </Box>
        )
      )}

      <Box sx={{ display: "flex", flexDirection: "column", px: 2, gap: 1 }}>
        {textData?.length > 0 && (
          <>
            <StyledHead head={"Texts"} color="black" sx={{ height: "30px" }} />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}
            >
              {textData?.map((item: any, index: any) => {
                return (
                  <>
                    <Card data={item} />
                    {index < textData.length - 1 && <Divider />}
                  </>
                );
              })}
            </Box>
          </>
        )}

        {resourceData?.length > 0 && (
          <>
            <StyledHead
              head={"Resources"}
              color="black"
              sx={{ height: "30px" }}
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}
            >
              {resourceData?.map((item: any, index: any) => {
                return (
                  <>
                    <Card data={item} />
                    {index < resourceData.length - 1 && <Divider />}
                  </>
                );
              })}
            </Box>
          </>
        )}

        {linkData?.length > 0 && (
          <>
            <StyledHead head={"Links"} color="black" sx={{ height: "30px" }} />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}
            >
              {linkData?.map((item: any, index: any) => {
                return (
                  <>
                    <Card data={item} />
                    {index < linkData.length - 1 && <Divider />}
                  </>
                );
              })}
            </Box>
          </>
        )}

        {experimentData?.length > 0 && (
          <>
            <StyledHead
              head={"Experiment"}
              color="black"
              sx={{ height: "30px" }}
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, py: 1 }}
            >
              {experimentData?.map((item: any, index: any) => {
                return (
                  <>
                    <Card data={item} />
                    {index < experimentData.length - 1 && <Divider />}
                  </>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default AssignmentAndProject;
