import { SearchOutlined } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium";
  control: any;
  style?: any;
  onChange?: (value: any) => void;
  required?: boolean;
  sx?: any;
  readonly?: boolean;
  type?: any;
  placeholder?: string;
  endIcon?: any;
}

function FormOutlinedSearch(props: Props) {
  const {
    name,
    control,
    style,
    onChange,
    required = false,
    sx,
    readonly = false,
    type = "text",
    placeholder = "",
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ ...sx }}>
          <TextField
            {...field}
            error={Boolean(error)}
            variant="outlined"
            sx={{
              "& ::placeholder": {
                fontSize: "14px",
              },
            }}
            fullWidth
            required={required}
            placeholder={placeholder}
            type={type}
            size="small"
            style={style}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: readonly }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
          {error && (
            <Typography
              variant="caption"
              sx={{ pl: "2px", display: "block" }}
              color="rgb(211, 47, 47)"
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}

export default FormOutlinedSearch;
