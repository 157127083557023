import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getStudentProfile, logout } from "../../api/services";
import logo from "../../assets/images/logo.png";

function Header() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate } = useMutation(logout, {
    onSuccess: () => {
      toast.success("Logged out successfully");
      setAnchorEl(null);
      localStorage.clear();
      navigate("/");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: studentData } = useQuery("studentProfile", getStudentProfile);

  return (
    <Box
      sx={{
        height: "60px",
        boxShadow: "0px 3px 3px #11234B29",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{ width: "max-content", cursor: "pointer", pl: 2 }}
        onClick={() => navigate("/")}
      >
        <img
          src={logo}
          alt="logo"
          height={"65px"}
          style={{ objectFit: "cover", padding: "10px" }}
        />
      </Box>
      <Box sx={{ pr: 2 }}>
        <IconButton
          disableRipple
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          sx={{
            fontSize: "2.5rem",
            gap: 1,
            ":hover": { backgroundColor: "transparent" },
          }}
        >
          {/* <AccountCircle sx={{ fontSize: "inherit" }} /> */}
          <Avatar
            alt={studentData?.data?.name}
            src={studentData?.data?.imageUrl}
          />
          {studentData?.data?.name && (
            <Button
              disableRipple
              variant="text"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ px: 0, ":hover": { backgroundColor: "transparent" } }}
            >
              {studentData?.data?.name}
            </Button>
          )}
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-dashboard");
            }}
          >
            My Dashboard
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-trade-timetable");
            }}
          >
            My Trade Timetable
          </MenuItem>

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/online-test");
            }}
          >
            My Test
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/doubts");
            }}
          >
            My Doubts
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/e-books");
            }}
          >
            My E-Books
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-learning");
            }}
          >
            My Learnings
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-discussion-forum");
            }}
          >
            My Discussion Forum
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/live-sessions");
            }}
          >
            My Live Sessions
          </MenuItem>

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/noticeboard");
            }}
          >
            My Notice Board
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/exam-timetable");
            }}
          >
            My Exam Timetable
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/profile");
            }}
          >
            User Profile
          </MenuItem>
          <MenuItem onClick={() => mutate()} sx={{ color: "#F4347F" }}>
            Sign out
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}

export default Header;
