import { http } from "../http";

export const getTest = (id: any) => {
  return http.get(`/exams/user-exam/${id}`);
};

export const SubmitTheTest = (id) => {
  return http.patch(`/exams/end-exam/${id}`);
};

export const getExamStates = (id: any) => {
  return http.get(`/exams/questions/statistics/${id}`).then((res) => res.data);
};

export const beginTest = (postBody: any) => {
  return http.post(`/exams/begin`, postBody).then((res) => res.data);
};

export const getStudentProfile = () => {
  return http.get(`/students/profile`).then((res) => res.data);
};

export const saveAnswer = (postBody: any) => {
  return http.post("/exams/user-response", postBody);
};

export function numberToAlphabet(number: any) {
  const alphabetIndex = number + 65;
  return String.fromCharCode(alphabetIndex);
}

export const getExams = ({ queryKey }: any) => {
  return http.get(`/exams/student/${queryKey[1]}`, {
    params: {
      ...queryKey[2],
    },
  });
};

export const getExamData = ({ queryKey }: any) => {
  return http.get(`/exams/${queryKey[1]}`);
};

export const getResponse = ({ queryKey }: any) => {
  return http.get(`/exams/response`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getResult = ({ queryKey }: any) => {
  return http.get(`/exams/results/${queryKey[1]}`);
};
