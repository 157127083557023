import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../assets";
import { StyledAcordian } from "./styles";

const updateSearchParams = (key: any, value: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  return searchParams.toString();
};

function ListOfCurriculums({ data }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const chapterId = searchParams.get("chapterId");

  const [expandedChapter, setExpandedChapter] = useState<any>("");

  useEffect(() => {
    setExpandedChapter(chapterId);
  }, [chapterId]);

  return (
    <Box sx={{ overflowY: "scroll" }}>
      {data?.map((item: any, index: any) => (
        <>
          <StyledAcordian
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{ padding: 0 }}
            expanded={expandedChapter === item?._id}
          >
            <AccordionSummary
              sx={{
                backgroundColor: "#F2F7FF",
                borderBottom: "1px solid #cecece",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                expandedChapter === item._id
                  ? setExpandedChapter("")
                  : setExpandedChapter(item._id);
                return navigate(
                  `?${updateSearchParams("chapterId", item?._id)}`
                );
              }}
            >
              <Box>
                <Typography fontSize={"14px"}>{item?.title}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pr: 2,
                  }}
                >
                  <Typography fontSize={"12px"} sx={{ opacity: 0.8 }}>{`${
                    index + 1
                  }/${data?.length}`}</Typography>
                  <Typography fontSize={"12px"} sx={{ opacity: 0.8 }}>{`${moment
                    .duration(item?.duration, "seconds")
                    .format("mm:ss", {
                      trim: false,
                    })}`}</Typography>
                </Box>
              </Box>
            </AccordionSummary>

            {item?.sections?.map((lesson: any, index: any) => (
              <Lessons
                data={lesson}
                index={index}
                key={index}
                total={item?.sections?.length}
              />
            ))}
          </StyledAcordian>
        </>
      ))}
    </Box>
  );
}

export default ListOfCurriculums;

export const Lessons = ({ data, index, total }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sectionId = searchParams.get("sectionId");

  useEffect(() => {
    setExpandedSection(sectionId);
  }, [sectionId]);

  const [expandedSection, setExpandedSection] = useState<any>("");

  return (
    <>
      <AccordionDetails
        sx={{
          margin: 0,
          paddingY: "0px",
          borderBottom: "1px solid #cecece45",
          px: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <StyledAcordian
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{ width: "100%" }}
            expanded={expandedSection === data._id}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#F2F7FF", width: "auto" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                expandedSection === data._id
                  ? setExpandedSection("")
                  : setExpandedSection(data._id);
                return navigate(
                  `?${updateSearchParams("sectionId", data?._id)}`
                );
              }}
            >
              <Box>
                <Typography fontSize={"13px"}>{data?.title}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pr: 2,
                  }}
                >
                  <Typography fontSize={"12px"} sx={{ opacity: 0.8 }}>{`${
                    index + 1
                  }/${total}`}</Typography>
                  <Typography fontSize={"12px"} sx={{ opacity: 0.8 }}>{`${moment
                    .duration(data?.duration, "seconds")
                    .format("mm:ss", {
                      trim: false,
                    })}`}</Typography>
                </Box>
              </Box>
            </AccordionSummary>

            {data?.topics?.map((classItem: any, index: any) => (
              <AccordionDetails
                key={classItem._id}
                sx={{ borderBottom: "1px solid #cecece45" }}
                onClick={() => {
                  if (classItem?.status !== "LOCKED") {
                    return navigate(
                      `?${updateSearchParams("topicId", classItem?._id)}`
                    );
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography fontSize={"14px"}>{classItem.title}</Typography>
                  {classItem?.status === "LOCKED" && (
                    <img
                      src={icons.lock}
                      alt="lock"
                      width={"14px"}
                      height={"16px"}
                    />
                  )}
                </Box>
              </AccordionDetails>
            ))}
          </StyledAcordian>
        </Box>
      </AccordionDetails>
    </>
  );
};
