import { Box, Button, Divider, Typography } from "@mui/material";
import { logo } from "../../assets";

var elem: any = document.documentElement;

export function disableRightClick(event) {
  event.preventDefault();
}

export function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
  document.addEventListener("keydown", handleKeyDown);
}

export function closeFullscreen() {
  if (elem.exitFullscreen) {
    elem.exitFullscreen();
  } else if (elem.webkitExitFullscreen) {
    elem.webkitExitFullscreen();
  } else if (elem.msExitFullscreen) {
    elem.msExitFullscreen();
  }
  if (document.exitFullscreen) document?.exitFullscreen();
}

export const handleKeyDown = (e: KeyboardEvent) => {
  if (
    e.ctrlKey ||
    e.key === "Escape" ||
    (e.key.startsWith("F") && e.key.length === 2 && !isNaN(Number(e.key[1]))) ||
    (e.key.startsWith("F") &&
      e.key.length === 3 &&
      !isNaN(Number(e.key.slice(1))))
  ) {
    e.preventDefault();
  }
};

export const Instructions = ({ setValue }: any) => {
  return (
    <Box
      sx={{
        height: `100vh`,
        width: "100%",
        py: 5,
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "0px 6px 16px #21468329",
          borderRadius: "15px",
          maxWidth: "1440px",
          p: 3,
          gap: 2,
          margin: "auto",
        }}
      >
        <img src={logo} width={220} height={80} />
        <Typography fontSize={"18px"} fontWeight={600}>
          Please read the instructions carefully
        </Typography>
        <Box
          sx={{
            my: 3,
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: "80%",
            gap: 2,
          }}
        >
          <Typography
            sx={{ textDecoration: "underline", fontFamily: "figtree_semibold" }}
            variant="h5"
          >
            General Instructions
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            1. Aliquam faucibus, odio nec commodo aliquam, neque felis placerat
            dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed
            condimentum est. Mauris arcu odio, vestibulum quis dapibus sit amet,
            finibus id turpis. Aliquam semper fringilla semper.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            1. Aliquam faucibus, odio nec commodo aliquam, neque felis placerat
            dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed
            condimentum est. Mauris arcu odio, vestibulum quis dapibus sit amet,
            finibus id turpis. Aliquam semper fringilla semper.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            1. Aliquam faucibus, odio nec commodo aliquam, neque felis placerat
            dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed
            condimentum est. Mauris arcu odio, vestibulum quis dapibus sit amet,
            finibus id turpis. Aliquam semper fringilla semper.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            1. Aliquam faucibus, odio nec commodo aliquam, neque felis placerat
            dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed
            condimentum est. Mauris arcu odio, vestibulum quis dapibus sit amet,
            finibus id turpis. Aliquam semper fringilla semper.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            1. Aliquam faucibus, odio nec commodo aliquam, neque felis placerat
            dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed
            condimentum est. Mauris arcu odio, vestibulum quis dapibus sit amet,
            finibus id turpis. Aliquam semper fringilla semper.
          </Typography>
        </Box>
        <Box sx={{ width: "100%", display: "absolute" }}>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button
              onClick={() => {
                openFullscreen();
                setValue("aboutExam");
              }}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
