import {
  Box,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

interface Props {
  label?: string;
  name: string;
  size?: "small" | "medium";
  control: any;
  style?: any;
  onChange?: (value: any) => void;
  required?: boolean;
  sx?: any;
  readonly?: boolean;
  type?: any;
  placeholder?: string;
  multiline?: number;
  endIcon?: any;
}

function FormOutlinedInput(props: Props) {
  const {
    name,
    size = "small",
    control,
    label = "",
    style,
    onChange,
    required = false,
    sx,
    readonly = false,
    type = "text",
    placeholder = "",
    multiline = 1,
    endIcon,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ ...sx }}>
          {label && (
            <InputLabel
              sx={{
                opacity: "100%",
                pb: "3px",
              }}
              id={`label-${name}`}
            >
              {label}
            </InputLabel>
          )}
          <TextField
            {...field}
            error={Boolean(error)}
            variant="outlined"
            sx={{ "& ::placeholder": { fontSize: "14px" } }}
            fullWidth
            required={required}
            multiline={multiline ? true : false}
            rows={multiline}
            placeholder={placeholder}
            type={type}
            size={size}
            style={style}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e);
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ readOnly: readonly }}
            InputProps={{
              endAdornment: endIcon ? (
                <IconButton>{endIcon}</IconButton>
              ) : (
                <></>
              ),
            }}
          />
          {error && (
            <Typography
              variant="caption"
              sx={{ pl: "2px", display: "block" }}
              color="rgb(211, 47, 47)"
            >
              {error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
}

export default FormOutlinedInput;
