import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { exam, icons } from "../../../../../assets";
import { StyledAbsoleteButton } from "../../../../NewLoginPage/Pages/Curriculum/Tabs/EBooks";

export const ExamCard = ({ data }: any) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (data?.examResponse?.status === "COMPLETE") {
      navigate("/online-test-report");
    } else {
      navigate(`/test/${data?._id}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "170px",
        padding: "10px",
        px: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: "30px" }}>
        <Box
          sx={{
            position: "relative",
            width: {
              xs: "130px",
              sm: "220px",
              lg: "220px",
            },
            borderRadius: "10px",
            background: `url(${exam})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: {
              xs: "130px",
              sm: "220px",
              lg: "220px",
            },
            height: "140px",
          }}
        >
          <StyledAbsoleteButton
            sx={{
              width: "110px",
              gap: 1,
            }}
          >
            <img
              src={icons.test}
              alt="test"
              style={{ width: "12px", height: "15px" }}
            />
            <Typography color={"white"} fontSize={"13px"}>
              Online Test
            </Typography>
          </StyledAbsoleteButton>
        </Box>
        <Stack alignItems={"flex-start"} justifyContent={"center"} gap={"3px"}>
          {data?.trade?.name && (
            <Typography
              color={"white"}
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "15px",
                  lg: "15px",
                },
                mb: 2,
                width: "max-content",
                px: "15px",
                py: "5px",
                backgroundColor: "#2F468C",
                borderRadius: "20px",
                textAlign: "center",
              }}
            >
              {`ITI_${data?.trade?.name}`}
            </Typography>
          )}
          <Typography sx={{ fontSize: "18px" }} variant="h5">
            {data?.name}
          </Typography>
          <Typography
            sx={{ fontSize: "13px", color: "rgba(34, 34, 34, 0.5)" }}
            variant="subtitle2"
          >
            {`Duration: ${data?.duration} Min`}
          </Typography>
        </Stack>
      </Box>

      {false && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: "13px", color: "rgba(34, 34, 34, 0.5)" }}
              variant="subtitle2"
            >
              Time Taken
            </Typography>
            <Typography
              sx={{ fontSize: "17px", color: "#2F468C" }}
              variant="h5"
            >
              1 Hr 20Min
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: "13px", color: "rgba(34, 34, 34, 0.5)" }}
              variant="subtitle2"
            >
              Score
            </Typography>
            <Typography
              sx={{ fontSize: "17px", color: "#2F468C" }}
              variant="h5"
            >
              20
            </Typography>
          </Box>
        </>
      )}

      <Button
        variant="contained"
        sx={{
          padding: "5px",
          width: "150px",
          fontSize: "14px",
        }}
        onClick={
          data?.examResponse?.status === "COMPLETE"
            ? () => {
                navigate(`/result/${data?._id}`);
              }
            : handleClick
        }
      >
        {data?.examResponse?.status === "COMPLETE"
          ? "View Report"
          : "Start Test"}
      </Button>
    </Box>
  );
};
