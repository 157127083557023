import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { exam, icons } from "../../../assets";
import { StyledAbsoleteButton } from "../../NewLoginPage/Pages/Curriculum/Tabs/EBooks";

export const QuizCard = ({ data }: any) => {
  const searchParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const { bundleId, courseId } = useParams();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "140px",
          padding: "10px",
          pl: 2,
          pr: 4,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", gap: "25px" }}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: "130px",
                  sm: "220px",
                  lg: "220px",
                },
                borderRadius: "10px",
                background: `url(${exam})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: {
                  xs: "130px",
                  sm: "220px",
                  lg: "220px",
                },
                height: "120px",
              }}
            >
              <StyledAbsoleteButton
                sx={{
                  width: {
                    xs: "100px",
                    sm: "130px",
                    lg: "100px",
                  },
                  gap: 1,
                }}
              >
                <img
                  src={icons.test}
                  alt="test"
                  style={{ width: "12px", height: "15px" }}
                />
                <Typography color={"white"} sx={{ fontSize: "14px" }}>
                  {"Quiz"}
                </Typography>
              </StyledAbsoleteButton>
            </Box>
            <Stack
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              pt={1}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Typography sx={{ fontSize: "18px" }} variant="h5">
                  {data?.name}
                </Typography>
                <Chip
                  label={data?.difficultyLevel}
                  color={
                    data?.difficultyLevel === "EASY"
                      ? "success"
                      : data?.difficultyLevel === "MEDIUM"
                      ? "warning"
                      : "error"
                  }
                  sx={{
                    fontSize: "12px",
                    height: "25px",
                    fontFamily: "figtree_medium",
                  }}
                />
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                  fontFamily: "figtree_medium",
                }}
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></Box>
              <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "13px", opacity: 0.6 }}
                >{`Max Marks: ${data?.maxMarks}`}</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "13px", opacity: 0.6 }}
                >{`Pass Percentage: ${data?.maxMarks}`}</Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Button
          variant="contained"
          sx={{
            px: 2,
            py: 2,
            width: "130px",
          }}
          onClick={() => {
            data?.status === "COMPLETE"
              ? navigate(
                  `/learnings/bundle/${bundleId}/course/${courseId}/quiz/result/${
                    data?._id
                  }?${searchParams.toString()}`
                )
              : navigate(
                  `/learnings/bundle/${bundleId}/course/${courseId}/quiz/${
                    data?._id
                  }?${searchParams.toString()}`
                );
          }}
        >
          {data?.status === "COMPLETE" ? "View Result" : "Start Test"}
        </Button>
      </Box>
    </>
  );
};
