import { Avatar, Box, Typography } from "@mui/material";
import moment from "moment";

const DoubtCard = ({ data }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 2,
        boxShadow: "0px 6px 16px #21468329",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Avatar alt={data?.student?.name} src={data?.student?.imageUrl} />
        <Box>
          <Typography variant="body1" fontSize={"14px"} fontWeight={600}>
            {data?.student?.name}
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.5 }}>
            Posted on {moment(data?.createdAt).format("DD MMM, YYYY - hh:mm A")}
          </Typography>
        </Box>
      </Box>

      <Typography variant="body1" fontSize={"14px"}>
        {data?.comment}
      </Typography>
      {data?.status === "RESOLVED" && (
        <Box
          sx={{
            backgroundColor: "#0E125E05",
            p: 1,
            border: "1px solid #cecece",
            fontFamily: "figtree_medium",
            fontSize: "14px",
            "& *": { p: 0, m: 0 },
          }}
          dangerouslySetInnerHTML={{ __html: data?.reply?.comment }}
        ></Box>
      )}
    </Box>
  );
};

export default DoubtCard;
