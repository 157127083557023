import { Avatar, Box, Typography } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const MediaCard = ({ data }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 6px 16px #21468329",
        borderRadius: "10px",
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          pb: "5px",
        }}
      >
        <Avatar
          sx={{
            height: "27px",
            width: "27px",
            fontSize: "14px",
            backgroundColor: "#f4347f",
          }}
        >
          {data?.user?.name?.[0]}
        </Avatar>
        <Typography
          variant="body1"
          sx={{ fontSize: "15px", fontFamily: "figtree_semibold" }}
        >
          {data?.user?.name}
        </Typography>
      </Box>
      <Typography
        fontFamily={"figtree_semibold"}
        fontSize="15px"
        fontWeight={500}
      >
        {data?.title}
      </Typography>
      <Box
        sx={{
          fontFamily: "figtree_medium",
          fontSize: "14px",
          "& *": { p: 0, m: 0 },
        }}
        dangerouslySetInnerHTML={{ __html: data?.description }}
      ></Box>
      <Box sx={{ pt: 1 }}>
        {data?.images?.length > 0 && (
          <Box sx={{ width: "350px" }}>
            {data?.images?.length === 1 ? (
              <Box
                sx={{
                  objectFit: "contain",
                }}
              >
                <img
                  src={data?.images?.[0]?.s3Response?.url}
                  style={{ objectFit: "contain" }}
                />
              </Box>
            ) : (
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                autoplay={{ delay: 500, disableOnInteraction: false }}
                pagination={{ clickable: true }}
                style={{ height: "100%" }}
              >
                {data?.images?.map((item: any, index: any) => (
                  <SwiperSlide style={{ height: "100%" }}>
                    <Box
                      sx={{
                        height: "200px",
                        width: "400px",
                        objectFit: "contain",
                      }}
                      key={index}
                    >
                      <img
                        src={item?.s3Response?.url}
                        height={"100%"}
                        width={"100%"}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MediaCard;
