import { Avatar, Box, Divider, Switch, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { getDoubts } from "../../../../../api/services/doubts";
import { Loader } from "../../../../../components/loader";
import Continue from "../../../components/Continue";
import DoubtCard from "./DoubtCard";

const DoubtTab = ({ data }: any) => {
  const [toggle, setToggle] = useState<any>(false);
  const { data: doubts, isLoading } = useQuery(
    [
      "getDoubts",
      data._id,
      {
        status: toggle ? "RESOLVED" : "UNRESOLVED",
      },
    ],
    getDoubts
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            boxShadow: "0px 6px 16px #21468329",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Typography variant="h5" fontWeight={600}>
              Doubts Posted
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Switch
                size="small"
                checked={toggle}
                onChange={() => {
                  setToggle(!toggle);
                }}
              />
              <Typography variant="subtitle2">
                Show only Solved Doubts
              </Typography>
            </Box>
          </Box>
          <Divider />
          {doubts?.data?.count > 0 ? (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, p: 2 }}
            >
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Avatar
                  alt={doubts?.data?.data?.[0]?.student?.name}
                  src={doubts?.data?.data?.[0]?.student?.imageUrl}
                />
                <Box>
                  <Typography
                    variant="body1"
                    fontSize={"14px"}
                    fontWeight={600}
                  >
                    {doubts?.data?.data?.[0]?.student?.name}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.5 }}>
                    Posted on{" "}
                    {moment(doubts?.data?.data?.[0]?.createdAt).format(
                      "DD MMM, YYYY - hh:mm A"
                    )}
                  </Typography>
                </Box>
              </Box>

              <Typography variant="body1" fontSize={"14px"}>
                {doubts?.data?.data?.[0]?.comment}
              </Typography>
              {doubts?.data?.data?.[0]?.status === "RESOLVED" && (
                <Box
                  sx={{
                    backgroundColor: "#0E125E05",
                    p: 1,
                    border: "1px solid #cecece",
                    fontFamily: "figtree_medium",
                    fontSize: "14px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: doubts?.data?.data?.[0]?.reply?.comment,
                  }}
                ></Box>
              )}
            </Box>
          ) : (
            <Typography sx={{ textAlign: "center", p: 2, py: 4 }}>
              No Doubts Avalilable
            </Typography>
          )}
        </Box>
      )}

      {doubts?.data?.data?.slice(1)?.map((item: any) => (
        <DoubtCard data={item} />
      ))}
      <Continue />
    </Box>
  );
};

export default DoubtTab;
