import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { verifyOtp, withdrawRequestOtp } from "../api/services";
import WithdrawBg from "../assets/images/withdrawBg.png";
import { AlignItems, StyledFlexBox } from "../styles";

function AdmissionWithdraw() {
  const [addNumber, setAddNumber] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState("");
  const [data, setData] = useState<any>({});

  const { mutate: requestOtp } = useMutation(withdrawRequestOtp, {
    onSuccess: (data: any) => {
      setToken(data?.data?.token);
      toast.success("OTP sent successfully");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const { mutate: verifyOtpMutate } = useMutation(verifyOtp, {
    onSuccess: (data: any) => {
      toast.success(data?.data?.message);
      setData(data?.data?.data);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const handleSendOtp = (e: any) => {
    e.preventDefault();
    const res = {
      data: addNumber,
      reason: "ADMISSION_WITHDRAWAL",
    };
    requestOtp(res);
  };

  const handleVerifyOtp = (e: any) => {
    e.preventDefault();
    const res = {
      otp: otp,
      token: token,
    };
    verifyOtpMutate(res);
  };

  return (
    <>
      <Box
        sx={{
          height: "70vh",
          backgroundImage: `url(${WithdrawBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <AlignItems position="absolute" top="50%" left={"8%"}>
          <Typography fontSize="60px" color="white">
            Admission Withdraw Process
          </Typography>
        </AlignItems>
      </Box>
      <AlignItems my={7}>
        <Typography variant="h3">Guidelines for Refund</Typography>
        <ul className="list-guideline">
          <li className="guideline-li">
            If Student is not eligible for admission; student is eligible for
            100% refund.
          </li>
          <li className="guideline-li">
            100% Refund (If cancellation request received is 15 days or less
            after the allotment of admission number and LMS details shared to
            registered number).
          </li>
          <li className="guideline-li">
            0% Refund (If cancellation request received more than 15 days after
            the allotment of admission number and LMS details shared to
            registered number).
          </li>
          <li className="guideline-li">
            Eligible Refund Cases would take minimum 45 working days from date
            of receipt of cancellation request. Cancellation and refunds are at
            the discretion of the Digital ITI.
          </li>
        </ul>
        <Box mt={3}>
          <Typography variant="h3">Admission withdraw Request</Typography>
          <Card sx={{ p: 3, mt: 2 }}>
            <StyledFlexBox gap={3}>
              <FormControl fullWidth>
                <FormLabel>Registered Admission Number</FormLabel>
                <form onSubmit={handleSendOtp}>
                  <StyledFlexBox gap={2} maxWidth="600px">
                    <TextField
                      fullWidth
                      placeholder="Enter Admission Number"
                      value={addNumber}
                      onChange={(e) => setAddNumber(e.target.value)}
                      required
                    />
                    <Button
                      sx={{ padding: "0", width: "60%" }}
                      fullWidth
                      variant="contained"
                      type="submit"
                    >
                      Send OTP
                    </Button>
                  </StyledFlexBox>
                </form>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>OTP Verification</FormLabel>
                <form onSubmit={handleVerifyOtp}>
                  <StyledFlexBox gap={2} maxWidth="600px">
                    <TextField
                      fullWidth
                      placeholder="Enter the OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      type="number"
                      required
                      inputProps={{ maxLength: 4 }}
                    />
                    <Button
                      sx={{ padding: "0", width: "60%" }}
                      fullWidth
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </StyledFlexBox>
                </form>
                {/* <Typography>
                Did not receive OTP ?{" "}
                <Box display="inline" color={"#F4347F"}>
                  Resend
                </Box>
              </Typography> */}
              </FormControl>
            </StyledFlexBox>
            <>
              <Divider sx={{ my: 3 }} />
              <Typography fontSize="34px" variant="h4">
                Cancellation Form
              </Typography>
              <Grid mt={1} container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Date</FormLabel>
                    <TextField
                      value={moment(data?.createdAt).format("DD-MM-YYYY")}
                      fullWidth
                      placeholder="Date"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Name</FormLabel>
                    <TextField
                      value={data?.name}
                      fullWidth
                      placeholder="name"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Mobile Number</FormLabel>
                    <TextField
                      value={data?.mobile}
                      fullWidth
                      placeholder="mobile number"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Trade Name</FormLabel>
                    <TextField
                      value={"nkw"}
                      fullWidth
                      placeholder="trade name"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Course Fee</FormLabel>
                    <TextField
                      value={"100"}
                      fullWidth
                      placeholder="course fee"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Admission Date</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="admission date"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Total Fees Paid</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="total fees paid"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Balance Fee Remaining</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="balance fee remaining"
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Refund % as per Policy below</FormLabel>
                    <TextField fullWidth placeholder="refund %" disabled />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Reason for cancellation</FormLabel>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      placeholder="Enter your reason here.."
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />
              <Typography fontSize="34px" variant="h4">
                My Bank Details
              </Typography>
              <Grid mt={1} container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Account Number</FormLabel>
                    <TextField fullWidth placeholder="account number" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Bank Name</FormLabel>
                    <TextField fullWidth placeholder="bank name" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>IFSC Code</FormLabel>
                    <TextField fullWidth placeholder="ifsc code" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel>Branch Name</FormLabel>
                    <TextField fullWidth placeholder="branch name" />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          </Card>
        </Box>
      </AlignItems>
    </>
  );
}

export default AdmissionWithdraw;
