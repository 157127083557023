import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { beginQuiz, getQuizData } from "../../api/services/quiz";
import { getStudentProfile } from "../../api/services/test";
import { logo } from "../../assets";
import { Loader } from "../../components/loader";
import { TitleBox } from "../../components/TitleBox";
import { disableRightClick, handleKeyDown } from "./instructions";

export const AboutExam = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const { bundleId, courseId } = useParams();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const { id } = useParams();

  const { data: examData, isLoading: examLoading } = useQuery(
    ["getQuizData", id],
    getQuizData
  );

  const { data: studentData } = useQuery("student-profile", getStudentProfile);

  const { mutate, isLoading } = useMutation(beginQuiz, {
    onSuccess: (data: any) => {
      navigate(
        `/learnings/bundle/${bundleId}/course/${courseId}/begin-test/quiz/${id}/response/${
          data?.data?.QuizResponse?._id
        }/?${searchParams.toString()}`
      );
    },
    onError: (e: any) => {
      toast.error(e.response.data.message || "Something went wrong");
      setTimeout(() => {
        navigate(
          `/learnings/bundle/${bundleId}/course/${courseId}/?${searchParams.toString()}`
        );
      }, 3000);
    },
  });

  const handleSubmit = () => {
    try {
      const postBody = {
        userId: studentData?._id,
        quizId: id,
      };
      mutate(postBody);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("contextmenu", disableRightClick);
  }, []);

  return (
    <Box
      sx={{
        height: `100vh`,
        width: "100%",
        py: 5,
        alignContent: "center",
      }}
    >
      {examLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            boxShadow: "0px 6px 16px #21468329",
            borderRadius: "15px",
            maxWidth: "1440px",
            p: 3,
            gap: 2,
            margin: "auto",
          }}
        >
          <img src={logo} width={220} height={80} alt="logo" />

          <Grid
            container
            spacing={1}
            rowGap={2}
            sx={{ textAlign: "left", width: "70%" }}
          >
            <Grid item xs={12} sm={4}>
              <TitleBox title="Exam Title" value={examData?.data?.name} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleBox
                title="Pass Percentage"
                value={`${examData?.data?.passPercentage} %`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleBox
                title="Toatl Questions"
                value={`${examData?.data?.addedQuestions}`}
              />
            </Grid>

            {examData?.data?.parts?.map((item: any, index: any) => (
              <Grid item xs={12} sm={4} key={index}>
                <TitleBox title={`Part ${index + 1}`} value={item?.title} />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography variant="h4" fontSize="14px" sx={{ opacity: "0.6" }}>
                Short Description
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "figtree_semibold",
                  "& *": { p: 0, m: 0 },
                }}
                dangerouslySetInnerHTML={{
                  __html: examData?.data?.description,
                }}
              ></Typography>
            </Grid>
          </Grid>

          <Box
            sx={{ pt: 6, pb: 2, bottom: 10, width: "100%", textAlign: "left" }}
          >
            <Divider sx={{ opacity: 0.4 }} />
            <Box sx={{ px: 8 }}>
              <Typography sx={{ pt: 2, fontSize: "16px", fontWeight: 600 }}>
                Declaration
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  py: "3px",
                  gap: 1,
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ p: 0, m: 0 }}
                />

                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "figtree_semibold",
                    "& *": {
                      margin: 0,
                      padding: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: examData?.data?.instructions,
                  }}
                ></Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
              }}
            >
              <Button
                disabled={!checked || isLoading}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                sx={{ fontSize: "14px", px: 2 }}
              >
                I am ready to begin
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
