import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  getTradeById,
  getTradeTimeTable,
  getTrades,
} from "../../../api/services";
import { StyledTitle } from "../../../components/styledHead";
import { AlignItems } from "../../../styles";

import { Loader } from "../../../components/loader";
import { TradeCard } from "./components/tradeCard";
import { TimeTableYear } from "./timeTableYear";

function TradeTimeTable() {
  const [selected, setSelected] = useState("");

  const { data: tradeData } = useQuery("trades", getTrades);

  const { data: timeTableData, isLoading } = useQuery(
    [
      "tradeTimeTable",
      {
        tradeId: selected,
      },
    ],
    getTradeTimeTable
  );

  const { data: tradeById } = useQuery(["tradeById", selected], getTradeById);

  const handleSelect = (id: any) => {
    setSelected(id);
  };

  useEffect(() => {
    if (tradeData?.data?.data?.length) {
      setSelected(tradeData?.data?.data[0]?._id);
    }
  }, [tradeData]);

  const itiCourseStructure = [
    {
      id: 1,
      name: "Professional Skill (Trade Practical)",
      firstYear: 840,
      secondYear: 840,
    },
    {
      id: 2,
      name: "Professional Knowledge (Trade Theory)",
      firstYear: 240,
      secondYear: 300,
    },
    {
      id: 3,
      name: "Employability Skills",
      firstYear: 120,
      secondYear: 60,
    },
  ];

  if (isLoading) return <Loader />;

  return (
    <AlignItems mt={15} mb={5}>
      <StyledTitle title={"Trade Timetable"} />
      <Grid container spacing={2} mt={3}>
        {tradeData?.data?.data?.map((item: any) => (
          <Grid key={item?._id} item xs={2.3}>
            <TradeCard
              isActive={selected === item?._id}
              title={item?.name}
              image={item?.imageUrl}
              onClick={() => handleSelect(item?._id)}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={7}>
        <Typography variant="h3">
          ITI {tradeById?.data?.name} Course Structure
        </Typography>
        <Typography mt={0.5} sx={{ opacity: "0.85" }} variant="h6">
          Table below depicts the distribution of training hours across various
          course elements during a period of two-years:-
        </Typography>
      </Box>
      <Box mt={3}>
        <table width="100%" cellPadding={13}>
          <tr>
            <th rowSpan={2}>S.No</th>
            <th rowSpan={2}>Trade Name</th>
            <th colSpan={2}>Notional Training Hours</th>
          </tr>
          <tr>
            <th>1st Year</th>
            <th>2nd Year</th>
          </tr>
          {itiCourseStructure.map((item: any) => (
            <tr key={item?.id}>
              <td>{item?.id}</td>
              <td>{item?.name}</td>
              <td>{item?.firstYear}</td>
              <td>{item?.secondYear}</td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td>
              <Typography variant="h2" fontSize="18px">
                {" "}
                Total
              </Typography>
            </td>
            <td>
              <Typography variant="h2" fontSize="18px">
                {" "}
                1200
              </Typography>
            </td>
            <td>
              <Typography variant="h2" fontSize="18px">
                {" "}
                1200
              </Typography>
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>On the Job Training (OJT) / Group Project</td>
            <td>150</td>
            <td>150</td>
          </tr>
        </table>
      </Box>
      <Box mt={7}>
        <Typography variant="h3">
          ITI {tradeById?.data?.name} Trade Timetable{" "}
          {timeTableData?.data?.data[0]?.year?.name}
        </Typography>
        <TimeTableYear data={timeTableData?.data} />
      </Box>
    </AlignItems>
  );
}

export default TradeTimeTable;
