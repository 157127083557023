import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { signUp } from "../api/services";
import { StyledHead } from "../components/styledHead";
import { StyledFlexBox } from "../styles";

function Preview({ formData, AllData, setValue, setResponse }: any) {
  const [checked, setChecked] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const { mutate } = useMutation(signUp, {
    onSuccess: (res: any) => {
      toast.success("Student created successfully");
      setValue("successScreen");
      setResponse(res);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleSubmit = (e: any) => {
    const res = {
      ...AllData,
    };
    mutate(res);
  };

  return (
    <Box>
      <StyledFlexBox gap={1} mb={2} sx={{ cursor: "pointer" }}>
        <ArrowBackIcon color="primary" onClick={() => setValue("signUp")} />
        <Typography
          onClick={() => setValue("signUp")}
          variant="h5"
          color="primary"
        >
          Back
        </Typography>
      </StyledFlexBox>
      <StyledHead head="Student Details" />
      <Grid mt={1} container spacing={2}>
        <Grid display="flex" flexDirection="column" gap={2} item xs={12} sm={6}>
          <Typography variant="h5">Name:</Typography>
          <Typography variant="h5">Mobile Number:</Typography>
          <Typography variant="h5">Email Id:</Typography>
          <Typography variant="h5">Father Name:</Typography>
          <Typography variant="h5">Date of Birth:</Typography>
          <Typography variant="h5">Aadhar Number:</Typography>
          <Typography variant="h5">District:</Typography>
          <Typography variant="h5">Language:</Typography>
        </Grid>
        <Grid display="flex" flexDirection="column" gap={2} item xs={12} sm={6}>
          <Typography variant="h5">{AllData?.name}</Typography>
          <Typography variant="h5">{AllData?.mobile}</Typography>
          <Typography variant="h5">{AllData?.email}</Typography>
          <Typography variant="h5">{AllData?.fatherName}</Typography>
          <Typography variant="h5">
            {moment(AllData?.dateOfBirth).format("DD-MM-YYYY")}
          </Typography>
          <Typography variant="h5">{AllData?.aadharNumber}</Typography>
          <Typography variant="h5">{formData?.district}</Typography>
          <Typography variant="h5">{formData?.language}</Typography>
        </Grid>
      </Grid>
      <Box mt={3}>
        <StyledHead head="Trade Details" />
        <Grid mt={1} container spacing={2}>
          <Grid
            display="flex"
            flexDirection="column"
            gap={2}
            item
            xs={12}
            sm={6}
          >
            <Typography variant="h5">Trade:</Typography>
            <Typography variant="h5">Practical Center Type:</Typography>
            <Typography variant="h5">Practical Center:</Typography>
            {/* <Typography variant="h6">Practical Slots Available:</Typography> */}
          </Grid>
          <Grid
            display="flex"
            flexDirection="column"
            gap={2}
            item
            xs={12}
            sm={6}
          >
            <Typography variant="h5">{formData?.trade}</Typography>
            <Typography variant="h5">{formData?.type}</Typography>
            <Typography variant="h5">{formData?.center || "N/A"}</Typography>
            {/* <Typography variant="h4">
              {formData?.practicalSlotsAvailable}
            </Typography> */}
          </Grid>
        </Grid>
      </Box>
      <Box my={3}>
        <StyledHead head="Mandatory Documents" />
        <Grid mt={1} container spacing={2}>
          {AllData?.imageKey && (
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Photo:</Typography>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/${AllData?.imageKey}`}
                alt="photo"
                height={200}
                width={200}
              />
            </Grid>
          )}
          {AllData?.aadharKey && (
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Aadhar Copy:</Typography>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/${AllData?.aadharKey}`}
                alt="photo"
                height={200}
                width={200}
              />
            </Grid>
          )}
          {AllData?.sscKey && (
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">10th Marks Sheet:</Typography>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/${AllData?.sscKey}`}
                alt="photo"
                height={200}
                width={200}
              />
            </Grid>
          )}
          {AllData?.signatureKey && (
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Signature:</Typography>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/${AllData?.signatureKey}`}
                alt="photo"
                height={200}
                width={200}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Typography variant="body2">
        By agreeing to the terms and conditions I acknowledge that giving false
        or misleading information is a serious offense and will lead to
        rejection of application and may be prosecuted as per the law.
      </Typography>
      <StyledFlexBox gap={1} my={2}>
        <Checkbox
          sx={{ p: 0, m: 0 }}
          color="primary"
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography variant="body2">
          I agree to the{" "}
          <Box
            onClick={() => setOpenDialog(true)}
            display="inline"
            color="#1876D1"
            sx={{ cursor: "pointer" }}
          >
            Terms and Conditions
          </Box>
        </Typography>
      </StyledFlexBox>
      <Box mt={3} textAlign="center">
        <Button
          disabled={checked ? false : true}
          onClick={handleSubmit}
          variant="contained"
          type="submit"
        >
          Submit
        </Button>
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContent>
          <Typography variant="subtitle2">
            A. Services Offered This Website/ Portal is used for dissemination
            of information regarding Digital ITI and various Trades offered. The
            Website/ Portal also provides a service of Self Paced Blended
            Learning for selective ITI Trades for interested students to apply
            Self Paced Blended Learning Mode ITI traders offered. The interested
            students must adhere to all the Norms and Regulations of Digital ITI
            to get them enrolled successfully.
          </Typography>
          <Typography variant="subtitle2">
            B. Terms & Conditions of Service Digital ITI provides the facility
            for Self Paced Blended Learning for selective ITI Trades through
            Hybring Delivery Mode. Digital ITI imposed some set of rules for
            such service. The special conditions and the terms of service
            applicable are detailed in this document.
          </Typography>
          <Typography variant="subtitle2">
            The following terms and conditions will apply if you wish to use the
            Digital ITI portal for Self Paced Blended Learning for selective ITI
            Trades offered through the Digital ITI Website/ Portal. Please go
            through the conditions carefully and if you accept them, you may
            register and get benefited upon approval for a seat in a specific
            trade. No User/ Student can register more than once on the site.
            Please note that once you register yourself on the Digital ITI site,
            you are deemed to have agreed to the terms and conditions set forth
            below. If you do not agree with all these terms and conditions, you
            must not register on Digital ITI portal. Once you have clicked the
            'I Agree' button/s at the bottom of the application, you have
            entered into a formal agreement with Digital ITI for the purpose of
            any transaction or activity on this Website/ Portal.
          </Typography>
          <Typography variant="subtitle2">
            Digital ITI 's performance of this agreement is subject to existing
            laws and legal processes of Government Of India, and nothing
            contained in this agreement is in derogation of Digital ITI 's right
            to comply with law enforcement requests or requirements relating to
            your use of this Web Site or information provided to or gathered by
            Digital ITI with respect to such use. You agree that Digital ITI may
            provide details of your use of the Web Site to regulators or police
            or to any other third party, or in order to resolve disputes or
            complaints which relate to the Web Site, at Digital ITI ’s complete
            discretion. tive ITI Trades offered through the Digital ITI Website/
            Portal:
          </Typography>
          <Typography variant="subtitle2">
            If any part of this agreement is determined to be invalid or
            unenforceable pursuant to applicable law including, but not limited
            to, the warranty disclaimers and liability limitations set forth
            herein, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision and the remainder of
            the agreement shall continue in effect.
          </Typography>
          <Typography variant="subtitle2">
            This agreement constitutes the entire agreement between the User/
            Student and Digital ITI with respect to this Web Site and it
            supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral, or written, between the User/
            Student and Digital ITI with respect to this Web Site. A printed
            version of this agreement and of any notice given in electronic form
            shall be admissible in judicial or administrative proceedings based
            upon or relating to this agreement to the same extent and subject to
            the same conditions as other business documents and records
            originally generated and maintained in printed form this agreement
            is made between:
          </Typography>
          <Typography my={1} variant="subtitle2">
            Digital ITI (“Us”) and The User/ Student (“You”), the individual,
            whose details are set out in the User/ Student Registration page.
            Scope of Service: Digital ITI makes no guarantee that any service
            will be uninterrupted, timely, secure or error-free, however Digital
            ITI strives to provide service uninterrupted. Rules Governing the
            Grant of Recognition to students by Digital ITI through Internet:
            All rules and regulations applicable for Recognition by DGT issued
            NCVT/NAC Certification or by DET issued SCVT Certification will also
            apply to Self Paced Blended Learning for selective ITI Trades for
            Recognition through Blended Learning offered by Digital ITI.
            Recognition shall be provided by Digital ITI after completion of
            prerequisites by the students as per the respective governing
            authorities.
          </Typography>
          <Typography variant="subtitle2">
            Your Obligations <br /> General Obligations: <br /> You shall access
            our website/portal only for lawful purposes and you shall be
            responsible for complying with all applicable laws, statutes and
            regulations in connection with the use of our website/ portal. This
            Website/ Portal is for your personal and non-commercial use for your
            own Trade Completion and Certification. You shall not modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license,
            create derivative works from, transfer or sell any information,
            products or services obtained from this Website/ Portal. You shall
            not create a hypertext link to the Website/ Portal or "frame" the
            Website/ Portal, except with the express advance written permission
            of Digital ITI . Student Responsibility: <br /> You confirm to us
            that you have provided legitimate information and took permission
            from your parents/ legal guardian and have the legal capacity to
            enter into this Agreement with us and that all information you
            provide in respect of your use of the service is true and accurate
            to the best of your knowledge and belief. You are responsible for
            the security of your password and for all transactions undertaken
            using your password through our service. The Password entered by you
            is transmitted in one- way encrypted form to our database and stored
            as such. Thus the Password will not be known even to Digital ITI.{" "}
            <br /> You confirm that you are the authorized holder of the cards
            you use to make payment or the original account holder/ authorized
            to use the account that is used in the transactions you make using
            the site and you alone use the "id" and "password" you have created
            in the site. Digital ITI will not be responsible for any financial
            loss, inconvenience or mental agony resulting from misuse of your
            ID/password/credit card number/ debit card number /account details
            number for payment for application fee or any other fee on this
            website/ portal.
          </Typography>
          <Typography variant="subtitle2">
            Limitation of Liability: <br /> Digital ITI shall not be liable to
            you save as expressly provided for in these terms and conditions and
            shall have no other obligations, duties or liabilities whatsoever in
            contract, tort or otherwise arising out of the use of the service or
            connection to the website. To the maximum extent permitted by law,
            Digital ITI and/or its respective suppliers, its service providers
            and its vendors hereby disclaims all warranties, terms and
            conditions with regard to this information & services including all
            implied warranties, terms and conditions, by statute, collaterally
            or otherwise, of satisfactory quality, fitness for a particular
            purpose, title and non-infringement in no event, shall Digital ITI
            and / or its suppliers be liable loss of opportunity, loss of
            business, loss of revenue or wages, wasted time, wasted costs,
            indirect, incidental, special or consequential loss arising out of,
            or in any way connected with the use of this Website/ Portal or with
            the delay or inability to use this Website/ Portal or for any
            information, products and services obtained through this Website/
            Portal or otherwise arising out of the use of this Website/ Portal
            whether based on contract, tort, strict liability or otherwise, even
            if Digital ITI or any of its suppliers has been advised of the
            possibility of damages.
          </Typography>
          <Typography variant="subtitle2">
            Accuracy of Information: <br /> We shall use reasonable endeavors to
            check the accuracy of the information published on this
            website/portal. We give no warranty as to the accuracy of such
            information given on our website/portal and reserve the right to
            amend and vary the contents of this website/portal from time to time
            without notice. The Digital ITI Sites/Services may contain links to
            third party Web sites ("Linked Sites"). The Linked Sites are not
            under the control of Digital ITI and Digital ITI is not responsible
            for the contents of any Linked Site, including without limitation
            any link contained in a Linked Site, or any changes or updates to a
            Linked Site. Digital ITI is not responsible for webcasting or any
            other form of transmission received from any Linked Site nor is
            Digital ITI responsible if the Linked Site is not working
            appropriately. Digital ITI is providing these links to you only as a
            convenience, and the inclusion of any link does not imply
            endorsement by Digital ITI of the site or any association with its
            operators. You are responsible for viewing and abiding by the
            privacy statements and terms of use posted at the Linked Sites.
          </Typography>
          <Typography variant="subtitle2">
            Exclusion of Liability: <br /> We shall not be liable to you for any
            of the following types of loss or damage arising out of your use of
            our website/portal and the service whether in contract or tort
            (including any liability for negligence howsoever arising out of or
            in connection with the performance of our obligations in the
            provision of the website/portal and this service): <br />
            <ol className="list-guideline">
              <li className="terms-li">
                Loss of wages, household revenue or any anticipated savings.
              </li>
              <li className="terms-li">
                Any indirect or consequential loss however it is arising.
              </li>
              <li className="terms-li">
                Delay in completion of the trade due to unforeseen reasons.
              </li>
            </ol>
            Exclusion of other Warranties/Remedies: You acknowledge and agree
            that in entering into the Agreement, you do not rely on and shall
            have no remedy in respect of any statement, representation or
            warranty given by any person (including as to condition, quality and
            fitness for purpose). ALL warranties implied by statute, common law,
            custom or otherwise as to the standard of the service and the
            accuracy of any information (other than fraudulent
            misrepresentation) provided on this website are hereby excluded.
          </Typography>
          <Typography variant="subtitle2">
            Force Majeure: <br /> We shall not be liable to you in respect of
            any delay in performance of these terms and conditions or delay in
            performance or breach of the terms and conditions due to any event
            or circumstances beyond our reasonable control. Termination: <br />
            Our Termination Rights: We may at any time at our sole discretion
            and without giving any reason or any prior notice terminate or
            temporarily suspend your access to all or any part of the
            website/portal, however a reasonable alternative will be provided as
            per the guidelines available/ provided by the Government of
            Telangana. Governing Law: <br />
            Indian Law and the courts of Telangana, India govern this Agreement
            and your use of the website/portal. You hereby irrevocably consent
            to the exclusive jurisdiction and venue of courts in Telangana,
            India in all disputes arising out of or relating to the use of the
            Digital ITI websites/portal and Services. Use of the Digital ITI
            websites/Services is unauthorized in any jurisdiction that does not
            give effect to all provisions of these terms and conditions,
            including without limitation this paragraph. Entire Agreement:{" "}
            <br />
            This Agreement including any document referred to herein constitutes
            the entire agreement between us and you in respect of your use of
            this service. Third Party Rights: <br />
            Nothing in this Agreement shall be taken as granting any rights
            expressly or implicitly whether contractual or statutory to persons
            other than you or us. Website/ Portal Contents: <br />
            All trade/ course content and software that is made available to
            view and/or download in connection with the Digital ITI
            website/portal, excluding content and/or software that may be made
            available by end-User/ Students through a Communication Service,
            ("Software") is owned by and is the copyrighted work of Digital ITI
            and/or its suppliers and is protected by copyright laws and
            international treaty provisions. Your use of the Software is
            governed by the terms of the end User/ Student license agreement, if
            any, which accompanies or is included with the Software ("License
            Agreement"). No part of this site shall be reproduced in any form
            whether electronically or otherwise without the prior consent of
            Digital ITI Ltd. other than temporarily in the course of using the
            Digital ITI service or to keep a record of transactions entered into
            using the service. Materials Provided to Digital ITI : <br />
            Digital ITI does not claim ownership of the materials you provide to
            Digital ITI (including feedback and suggestions) or post, upload,
            input or submit to any Digital ITI Site/Service. However, by
            posting, uploading, inputting, providing or submitting your
            Submission you are granting Digital ITI , its authorities and
            necessary officers, sub committees, vendors and suppliers permission
            to use your Submission in connection with the operation of their
            responsibilities or content offering including, without limitation,
            the rights to: copy, distribute, transmit, publicly display,
            publicly perform, reproduce, edit, translate and reformat your
            Submission; and to publish your name in connection with your
            Submission. No compensation will be paid with respect to the use of
            your Submission, as provided herein. Digital ITI is under no
            obligation to post or use any Submission you may provide and Digital
            ITI may remove any Submission at any time in its sole discretion. By
            posting, uploading, inputting, providing or submitting your
            Submission you warrant and represent that you own or otherwise
            control all of the rights to your Submission as described in this
            section including, without limitation, all the rights necessary for
            you to provide, post, upload, input or submit the Submissions.
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            No partnership: <br />
            Nothing in this Agreement shall operate so as to create employment
            of any kind between you and us. All rights not expressly granted
            herein are reserved.
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            Disclaimer: <br />
            Digital ITI is not responsible for wrong application due to
            incorrect details furnished by the User/ Student.
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            Privacy Policy <br />
            Digital ITI is committed to protecting personally-identifiable
            information that we handle in the course of providing our
            authentication-related services ("Industrial Training Institution
            Information/Personal Information"). This Privacy Policy describes
            our information-handling practices. This Privacy Policy applies only
            to information relating to those Digital ITI services (the
            "Services") that are provided via the www.DigitalITI.in and its
            subdomains Internet web sites (the "Web Sites" or The “Portals”), as
            described in the applicable Certificate Policy Statement. This
            policy describes: <br />
            What Personal Information we collect in offering and providing the
            Services;How we use and to whom we disclose Personal
            Information;Your choices concerning our uses of Personal
            Information;The security measures we use to protect Personal
            Information;Your rights to correct and update Personal Information;
            and Information Collection & Use: <br />
            Digital ITI is the sole owner of the Personal Information collected
            through our Web Sites or in connection with the Services.
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            Data collected from Unregistered User/ Students: may visit the Web
            Sites without providing any Personal Information. <br />
            Data Collected from Registered User/ Students: <br />
            We need to collect Personal Information such as name, phone number,
            email address, state, and country in order for you to register for
            our Services or to provide content, slots booking for practicals,
            examination preparation guides, required syllabus etc. that you may
            submit to us through the Website/portal. We use this information to
            process your requests, to administer the Services and to provide
            information to you relating to our Services. We use this information
            to analyze interest in the portal and to improve the content we make
            available through the websites. External Links: <br />
            Our Web Site may contain links to other sites that are not owned or
            controlled by Digital ITI . Please be aware that we are not
            responsible for the privacy practices of such other sites that you
            may access from our Web Site. We encourage you to be aware when you
            leave our site and to read the privacy statements of each and every
            Web site that collects personally identifiable information. <br />
            Automated Web Site Tracking: <br />
            When you visit one of our websites, statistics about your visit are
            automatically collected. This information does not identify you
            personally, but rather identifies information about a visit to the
            Web Site. This information may include how many people visit the Web
            Site, the User/ Student's Internet Protocol (IP) address, which
            pages are visited, from which domains our visitors come and which
            types of browsers these persons use. We use these statistics about
            your visit for aggregate, analytical purposes only, to help us
            improve the performance and effectiveness of our portal. Cookies:{" "}
            <br />
            A cookie is a small text file that is stored on a User/ Student's
            computer for record-keeping purposes. We use cookies on our Web
            Sites. We do/do not link the information we store in cookies to any
            Personal Information you submit through our Web Sites. We use both
            session cookies and permanent cookies. A session cookie expires when
            you close your browser. A permanent cookie remains on your hard
            drive for an extended period of time. You may decline to accept
            cookies or may remove permanent cookies by following directions
            provided in your Internet browser's "help" file. <br />
            We use temporary cookies to help your browser navigate our Website,
            to determine which visitors reach our website from sites of our
            business partners, and to record during a User/ Student's session
            that the User/ Student is properly authenticated. We use permanent
            cookies to recall your information to save you time in filling out
            forms and/or revisiting our Web Site and to determine which visitors
            to our Web Sites have visited before and if so, for what purposes.
            If you reject cookies, you may still generally access the
            informational material on our Websites, but you may be unable to
            obtain some of our Services or access some portions of our Web
            Sites. Security: <br />
            We consider the protection of all personal information we receive
            from our Web Site visitors, Subscribers, Relying Parties as a core
            responsibility. We have security measures in place to protect
            against the loss, misuse, and alteration of Personal Information. As
            with any transmission over the Internet, however, there is always
            some element of risk involved in sending personal information. In
            order to try to minimize this risk, we encrypt information that you
            submit to us electronically when submitting requests. <br />
            We implement data security safeguards based upon generally accepted
            industry standards to protect your Personal Information from
            unauthorized access, use, disclosure, alteration, or loss.
            Nonetheless, no data security techniques are 100% effective, and we
            cannot guarantee its security. Privacy Policy Changes: <br />
            If we decide to change this Privacy Policy, we will post the revised
            Privacy Policy on our Website. We may change this Privacy Policy at
            any time without prior notice. If we make material revisions to this
            Policy that change the ways in which we use or disclose previously
            collected Personal Information, we will post a notice on our Web
            Site and, if appropriate, by email. <br />
            Each of the content components is accompanied by meta data, source
            and validity date. For some of the components the validity date may
            not be known i.e., the content is stated to be perpetual. Under this
            scenario, the validity date should be ten years hence. Under no
            circumstances, any content is to be displayed on the Website after
            the validity date. For few of the components like announcements,
            tenders, only the live content whose validity date is after the
            current date is shown on the Website. For other components like
            documents, schemes, services, forms, websites and contact directory
            there is a need for timely review of the same as per the Content
            Review Policy. List of content contributed is sent to the content
            contributor two weeks prior to the validity date to revalidate the
            content and if required modify the validity date. In case of no
            response, then a reminder is sent a week before the validity date
            and therefore the content is archived and no longer published on the
            Website. For the retrieval of content which has expired, there is a
            need to archive the content. Government Websites, Forms, Services
            and Contact Directory which are discontinued should be expunged.
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            Content Archival Policy (CAP): <br />
            The content Element on the Website has different Entry/Exit Policy
            and Archival Policy as enlisted below:- <br />
            <ol className="list-guideline">
              <li className="terms-li">
                About Government Department related information: Perpetual (10
                years)
              </li>
              <li className="terms-li">
                Information of discontinued trades: Five (05) years since the
                date of discontinuation.
              </li>
              <li className="terms-li">
                Circulars/ Notifications: Five (05) years.
              </li>
              <li className="terms-li">
                Documents/Publications/Reports: Perpetual (10 years)
              </li>
              <li className="terms-li">
                Course Content: Not required Not Applicable.
              </li>
              <li className="terms-li">
                Student Details such as attendance, activity, prerequisites
                fulfillment etc: Five (05) Years{" "}
              </li>
              <li className="terms-li">Student Certificates: Perpetual </li>
            </ol>
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            Do you have additional questions? <br /> Do contact us at
            mail@digitaliti.in
          </Typography>
          <Typography my={1.5} variant="subtitle2">
            Refund and Cancellation Policy: <br />
            As per the withdrawal process and policy setout in the Admissions
            Process.
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Preview;
