import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getStudentProfile } from "../../../../api/services";
import { updateStudent } from "../../../../api/services/userProfile";
import FormOutlinedInput from "../../../../components/FormFields/FormOutlinedInput";
import FormOutlinedPassword from "../../../../components/FormFields/FormOutlinedPassword";
import { StyledHead } from "../../../../components/styledHead";
import MediaCard from "./MediaCard";

const UserProfile = () => {
  const { data, isLoading } = useQuery("studentProfile", getStudentProfile);
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      mobile: "",
      email: "",
      password: "",
      trade: "",
      centreType: "",
      centre: "",
    },
  });
  useEffect(() => {
    if (data?.data) {
      const { name, mobile, email, trade, centre, centreType, ...restData } =
        data?.data;
      reset({
        name,
        mobile,
        email,
        trade: trade?.name,
        centreType: centreType?.name,
        centre: centre?.name,
      });
    }
  }, [data]);

  const { mutate } = useMutation(updateStudent, {
    onSuccess: (res: any) => {
      toast.success("Student data updated successfully");
      queryClient.invalidateQueries("studentProfile");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onFormSubmit = (formData: any) => {
    if (formData.mobile?.length !== 10) {
      toast.error("Phone Number should be 10 digits");
      return;
    }
    if (formData.name?.length <= 0) {
      toast.error("Name should not be empty");
      return;
    }

    mutate({
      id: data?.data?._id,
      data: {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        password: formData.password,
      },
    });
  };

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "40vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ p: 3, width: "100%" }}>
      <StyledHead
        head={"Student Details"}
        sx={{ height: "35px" }}
        fontSize={"18px"}
      />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Box sx={{ maxWidth: "750px", py: 2, pl: 2, pb: 4 }}>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={6}>
              <FormOutlinedInput control={control} name="name" label="Name" />
            </Grid>
            <Grid item xs={6}>
              <FormOutlinedInput
                control={control}
                name="email"
                label="Email"
                type={"email"}
              />
            </Grid>
            <Grid item xs={6}>
              <FormOutlinedInput
                type={"number"}
                control={control}
                name="mobile"
                label="Mobile"
              />
            </Grid>
            <Grid item xs={6}>
              <FormOutlinedPassword
                control={control}
                name="password"
                label="Password"
                placeholder="Enter New Password"
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" sx={{ mt: 3 }}>
            Update
          </Button>
        </Box>
      </form>
      <StyledHead
        head={"Trade Details"}
        sx={{ height: "35px" }}
        fontSize={"18px"}
      />
      <Box sx={{ maxWidth: "750px", py: 2, pl: 2, pb: 4 }}>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid item xs={6}>
            <FormOutlinedInput
              control={control}
              name="trade"
              label="Trade"
              readonly
            />
          </Grid>
          <Grid item xs={6}>
            <FormOutlinedInput
              control={control}
              name="centreType"
              label="Practical Center Type"
              readonly
            />
          </Grid>
          <Grid item xs={6}>
            <FormOutlinedInput
              control={control}
              name="centre"
              label="Practical Center"
              readonly
            />
          </Grid>
        </Grid>
      </Box>
      <StyledHead
        head={"Documents"}
        sx={{ height: "35px" }}
        fontSize={"18px"}
      />
      <Box sx={{ maxWidth: "750px", py: 2, pl: 2 }}>
        <Grid container columnSpacing={4} rowSpacing={2}>
          {data?.data?.docs?.length > 0 ? (
            data?.data?.docs?.map((item: any) => (
              <Grid item xs={6}>
                <MediaCard data={item} />
              </Grid>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ opacity: "0.6", textAlign: "center" }}
            >
              No Documents Found
            </Typography>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default UserProfile;
