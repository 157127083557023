import { Box, Typography } from "@mui/material";
import DiscussionBg from "../../../assets/images/discussionBg.png";
import { AlignItems } from "../../../styles";

function DiscussionForum() {
  return (
    <>
      <Box
        sx={{
          height: "80vh",
          backgroundImage: `url(${DiscussionBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <AlignItems position="absolute" top="50%" left={"8%"}>
          <Typography fontSize="60px" color="white">
            Discussion Forum
          </Typography>
        </AlignItems>
      </Box>

      <AlignItems my={7}>
        <Box mt={5}>
          <table width="100%" cellPadding={20}>
            <tr>
              <th>S.No</th>
              <th>Trade</th>
              <th>Year</th>
              <th>Subject</th>
              <th>Module</th>
              <th>Month</th>
              <th>Date</th>
              <th>Time</th>
              <th>Action</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Electrician</td>
              <td>1</td>
              <td>Electrical</td>
              <td>Module 1</td>
              <td>September</td>
              <td>10</td>
              <td>10:00 AM</td>
              <td>View</td>
            </tr>
          </table>
        </Box>
      </AlignItems>
    </>
  );
}

export default DiscussionForum;
