import { StartQuiz } from "../pages/Quiz/startQuiz";
import { StyledMainContentContainer } from "../pages/SignInPages/Sign-in-Layout";
import QuizHeader from "./QuizHeader";

export const QuizLayout = () => {
  return (
    <>
      <QuizHeader />
      <StyledMainContentContainer
        sx={{
          width: `100vw`,
          top: "64px",
          left: 0,
          height: `calc(100vh - ${"64px"})`,
          overflowX: "hidden",
        }}
      >
        <StartQuiz />
      </StyledMainContentContainer>
    </>
  );
};
