import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Quill from "../../../../../components/Quill";
import LineText from "../../../Components/LineText";
import { postDoubts } from "./services";

function Doubts({ DoubtsData, setParams, params }: any) {
  const [data, setData] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const queryClient = useQueryClient();

  const { mutate } = useMutation(postDoubts, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-doubts");
      toast.success("Doubts Posted  Successfully");
      setData("");
    },
  });

  const handleSubmit = () => {
    const postBody = {
      topicId,
      doubt: data,
    };
    mutate(postBody);
  };

  return (
    <Box sx={{ marginY: "20px" }}>
      <LineText title={"Post Doubts"} />
      <Box sx={{ marginY: "20px" }}>
        <Quill
          placeholder="Start writing here…"
          data={data}
          handleChange={(value) => setData(value)}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
      <Box
        sx={{
          border: "1px solid rgba(34, 34, 34, 0.1)",
          marginY: "20px",
        }}
      ></Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#2F468C",
              fontSize: {
                xs: "14px",
                sm: "13px",
                lg: "18px",
              },
            }}
            variant="h4"
          >
            Doubts posted in this course
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              size="small"
              onClick={(e) => {
                // setParams((s: any) => {
                //   s.status = "RESOLVED";
                // });
              }}
            />
            <Typography
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "13px",
                  lg: "18px",
                },
              }}
            >
              Show only answered doubts
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {DoubtsData?.map((item: any) => (
          <DoubtCard data={item} />
        ))}
        <DoubtCard />
      </Box>
    </Box>
  );
}

export default Doubts;

export const DoubtCard = (data: any) => {
  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 3px 10px #0000001A",
          borderRadius: "15px",
          minHeight: "200px",
          marginTop: "20px",
          padding: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack gap={"10px"}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(34, 34, 34, 0.5)" }}
            >
              Posted on 21 November, 2023 at 02:53 PM
            </Typography>
            <Stack sx={{ width: "100%" }} gap={"15px"}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "13px",
                    lg: "18px",
                  },
                }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Typography>

              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "13px",
                    lg: "18px",
                  },
                }}
                color={"rgba(104, 159, 56, 1)"}
              >
                Answer : Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </Typography>
            </Stack>
          </Stack>
          <Typography
            sx={{
              color: "rgba(244, 52, 127, 0.75)",
              textDecoration: "underline",
              whiteSpace: "nowrap",
            }}
          >
            Go to topic
          </Typography>
        </Box>
      </Box>
    </>
  );
};
