import { http } from "../../../../../api/http";

export const Test = [];

export const postPersonalNotes = (postBody: any) => {
  return http.post("/personal-notes", postBody);
};

export const getPersonalNotes = (id: any) => {
  return http.get(`/personal-notes/topic/${id}`).then((res) => {
    return res.data;
  });
};

export const getDoubts = (params: any) => {
  return http
    .get(`/doubts/`, {
      params: { ...params },
    })
    .then((res) => res.data);
};

export const postDoubts = (postBody: any) => {
  return http.post("/doubts", postBody);
};
