import { Box, Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Calculations } from "./tableTabs/calculations";
import { Drawing } from "./tableTabs/drawing";
import { Skills } from "./tableTabs/skills";
import { TradePracticals } from "./tableTabs/tradePracticals";
import { TradeTheory } from "./tableTabs/tradeTheory";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,

    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const TimeTableYear = (data: any) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box mt={2}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs sx={{}} centered value={value} onChange={handleChange}>
          <Box sx={{ mr: 2 }} />
          <Tab label="Trade Theory" {...a11yProps(0)} />
          <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
          <Tab label="Trade Practicals" {...a11yProps(1)} />
          <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
          <Tab label="Workshop Calculations" {...a11yProps(2)} />
          <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
          <Tab label="Engineering Drawing" {...a11yProps(3)} />
          <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
          <Tab label="Employability Skills" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <TradeTheory data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TradePracticals data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Calculations data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Drawing data={data} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Skills data={data} />
      </CustomTabPanel>
    </Box>
  );
};
