import { Box, LinearProgress, Typography } from "@mui/material";
import { logo, resultsLoading } from "../../assets";

const ResultLoading = () => {
  return (
    <Box
      sx={{
        height: `calc(100vh - ${"64px"})`,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
    >
      <img src={logo} width={180} height={70} alt="logo" />
      <Box sx={{ width: "20%" }}>
        <img
          src={resultsLoading}
          alt="resultsLoading"
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Box sx={{ width: "30%" }}>
        <LinearProgress sx={{ height: "6px", borderRadius: "12px" }} />
      </Box>
      <Typography sx={{ fontFamily: "figtree_semibold", fontSize: "16px" }}>
        Please wait while we generate your Test results
      </Typography>
    </Box>
  );
};

export default ResultLoading;
