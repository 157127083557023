import { StyledMainContentContainer } from "../pages/SignInPages/Sign-in-Layout";
import { StartTest } from "../pages/Test/startTest";
import TestHeader from "./TestHeader";

export const TestLayout = () => {
  return (
    <>
      <TestHeader />
      <StyledMainContentContainer
        sx={{
          width: `100vw`,
          top: "64px",
          left: 0,
          height: `calc(100vh - ${"64px"})`,
          overflowX: "hidden",
        }}
      >
        <StartTest />
      </StyledMainContentContainer>
    </>
  );
};
