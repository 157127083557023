import { Accordion, AccordionSummary, Box, styled } from "@mui/material";

export const StyledAcordian = styled(Accordion)(() => ({
  "&.MuiPaper-root:before": {
    opacity: 0,
  },
}));
export const StyledTitle = styled(AccordionSummary)(() => ({
  "&.MuiButtonBase-root": {
    minHeight: "50px",
    maxHeight: "50px",
    padding: "5px",
  },
  "& > .Mui-expanded": {
    margin: "10px 0px",
  },
}));

export const StyledIntoCardcontainer = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  border: theme.palette.primary.dark,
  padding: "10px",
  marginTop: "15px",
}));
