import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { getGuidelines } from "../../../api/services";
import ExamBg from "../../../assets/images/examBg.png";
import { StyledTitle } from "../../../components/styledHead";
import { AlignItems } from "../../../styles";

function ExamGuidelines() {
  const { data: guidelinesData } = useQuery("guidelines", getGuidelines);

  return (
    <>
      <Box
        sx={{
          height: "80vh",
          backgroundImage: `url(${ExamBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <AlignItems position="absolute" top="50%" left={"8%"}>
          <Typography fontSize="60px" color="white">
            Examination Guidelines
          </Typography>
        </AlignItems>
      </Box>
      <AlignItems my={7}>
        <StyledTitle title="Download Exam Timetable" />
        <Box mt={5}>
          <table width="100%" cellPadding={13}>
            <tr>
              <th rowSpan={2}>S.No</th>
              <th rowSpan={2}>Trade Name</th>
              <th colSpan={2}>Regular</th>
              <th colSpan={2}>Supplementary</th>
            </tr>
            <tr>
              <th>Year 1</th>
              <th>Year 2</th>
              <th>Year 1</th>
              <th>Year 2</th>
            </tr>
            {guidelinesData?.data?.data?.map((item: any) => (
              <tr>
                <td>{guidelinesData?.data?.data?.indexOf(item) + 1}</td>
                <td>{item?.trade?.name}</td>
                {item?.regular?.map((regular: any) => (
                  <>
                    <td>
                      {regular?.fileUrl === null ? (
                        <Typography> Yet to be updated</Typography>
                      ) : (
                        <a href={regular?.fileUrl} download>
                          Download
                        </a>
                      )}
                    </td>
                  </>
                ))}
                {item?.supplementary?.map((supplementary: any) => (
                  <>
                    <td>
                      {supplementary?.fileUrl === null ? (
                        <Typography> Yet to be updated</Typography>
                      ) : (
                        <a href={supplementary?.fileUrl} download>
                          Download
                        </a>
                      )}
                    </td>
                  </>
                ))}
                {/* <td>
                  <a href={item?.supplementary?.firstYear} download>
                    Download
                  </a>
                </td>
                <td>
                  <a href={item?.supplementary?.secondYear} download>
                    Download
                  </a>
                </td> */}
              </tr>
            ))}
            {/* <tr>
              <td>1</td>
              <td>ITI Electrician</td>
              <td>
                <Button variant="outlined">Download</Button>
              </td>
              <td>
                <Button variant="outlined">Download</Button>
              </td>
              <td>
                <Button variant="outlined">Download</Button>
              </td>
              <td>
                <Button variant="outlined">Download</Button>
              </td>
            </tr> */}
          </table>
        </Box>
        <Box mt={5}>
          <Accordion sx={{ p: 3 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4">Assessment and Certification</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1">
                The trainee will be tested for his skill, knowledge and attitude
                during the period of course through formative assessment and at
                the end of the training programme through summative assessment
                as notified by the DGT from time to time.
              </Typography>
              <Typography mt={1} variant="subtitle1">
                a) The Continuous Assessment (Internal)during the period of
                training will be done by Formative Assessment Method by testing
                for assessment criteria listed against learning outcomes. The
                training institute has to maintain individual trainee portfolio
                as detailed in assessment guideline. The marks of internal
                assessment will be as per the formative assessment template
                provided on www.bharatskills.gov.in.
              </Typography>
              <Typography mt={1} variant="subtitle1">
                b) The final assessment will be in the form of summative
                assessment. The All India Trade Test for awarding NTC will be
                conducted by Controller of examinations, DGT as per the
                guidelines. The pattern and marking structure is being notified
                by DGT from time to time. The learning outcome and assessment
                criteria will be basis for setting question papers for final
                assessment. The examiner during final examination will also
                check individual trainee's profile as detailed in assessment
                guideline before giving marks for practical examination.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ p: 3 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4">Pass Regulation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1">
                For the purposes of determining the overall result, weightage of
                100% is applied for six months and one-year duration courses and
                50% weightage is applied to each examination for two years
                courses. The minimum pass percent for Trade Practical and
                Formative assessment is 60% & for all other subjects is 33%
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ p: 3 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4">Assessment Guidelines</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1">
                Appropriate arrangements should be made to ensure that there wit
                the artifical lanters to assessment. The nature of special needs
                should be taken into account while undertaking meant Due
                consideration to be green while waiting for team work,
                avoidance/reduction of strap/wastage and diesel of scarp/wastage
                as per procedure, behavioural attitude, sensitive to environment
                and regularity in training. The sensitivity towards OSH and
                seaming attitude to be considered while in company
              </Typography>
              <Typography mt={1} variant="subtitle1">
                Assessment will evidence based compting some of the following:
              </Typography>
              <div>
                <ul className="list-guideline">
                  <li>Job carried out in labs/workshop</li>
                  <li>Record book/ daily diary</li>
                  <li> Answer sheet of assessment</li>
                  <li>Viva-voce</li>
                  <li>Progress Chart</li>
                  <li>Attendance and Punctuality</li>
                  <li>Assignment</li>
                  <li>Project Work</li>
                  <li>Computer based multiple choice question examination </li>
                  <li>Practical Examination </li>
                </ul>
              </div>
              <Typography mt={1} variant="subtitle1">
                Evidences and records of internal (formative) assessments are to
                be preserved until forthcoming examination for audit and
                verification by examination note. The following marking pattern
                to be adopted for format assessment.
              </Typography>
              <Box mt={3}>
                <table width="100%" cellPadding={13}>
                  <tr>
                    <th>S.No</th>
                    <th>Assessment Guidelines</th>
                    <th>Performance Level</th>
                    <th>Evidence</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>
                      (a) Marks in the range of 60-75% to be allotted during
                      assessment
                    </td>
                    <td>
                      For performance in this grade, the candidate with
                      occasional guidance and showing due regard for safety
                      procedures and practices, has produced work which
                      demonstrates attainment of an acceptable standard of
                      craftsmanship.
                    </td>
                    <td>
                      <ul>
                        <li className="evidence">
                          Demonstration of good skill in the use of hand tools,
                          machine tools and workshop equipment.
                        </li>
                        <li className="evidence">
                          60-70% accuracy achieved while undertaking different
                          work with those demanded by the component/job.
                        </li>
                        <li className="evidence">
                          A fairly good level of neatness and consistency in the
                          finish
                        </li>
                        <li className="evidence">
                          Occasional support in completing the project/job.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      (b) Marks in the range of above 75% - 90% to be allotted
                      during assessment
                    </td>
                    <td>
                      For this grade, the candidate, with little guidance and
                      showing due regard for safety procedures and practices,
                      has produced work which demonstrates attainment of a
                      reasonable standard of craftsmanship.
                    </td>
                    <td>
                      <ul>
                        <li className="evidence">
                          Good skill levels in the use of hand tools, machine
                          tools and workshop equipment.
                        </li>
                        <li className="evidence">
                          70-80% accuracy achieved while undertaking different
                          work with those demanded by the component/job.
                        </li>
                        <li className="evidence">
                          A good level of neatness and consistency in the
                          finish.
                        </li>
                        <li className="evidence">
                          Little support in completing the project/job.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      (c) Marks in the range of above 90% to be allotted during
                      assessment
                    </td>
                    <td>
                      For performance in this grade, the candidate, with minimal
                      or no support in organization and execution and with due
                      regard for safety procedures and practices, has produced
                      work which demonstrates attainment of a high standard of
                      craftsmanship.
                    </td>
                    <td>
                      <ul>
                        <li className="evidence">
                          High skill levels in the use of hand tools, machine
                          tools and workshop equipment.
                        </li>
                        <li className="evidence">
                          Above 80% accuracy achieved while undertaking
                          different work with those demanded by the
                          component/job.
                        </li>
                        <li className="evidence">
                          A high level of neatness and consistency in the
                          finish.
                        </li>
                        <li className="evidence">
                          Minimal or no support in completing the project.
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </AlignItems>
    </>
  );
}

export default ExamGuidelines;
