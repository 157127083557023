import { http } from "../http";

export const getCurriculum = ({ queryKey }: any) => {
  return http.get(`/courses/student/${queryKey[1]}`);
};

export const getTopicComponents = ({ queryKey }: any) => {
  return http.get(`/subjects/chapters/topics/${queryKey[1]}`);
};

export const getCourses = ({ queryKey }: any) => {
  return http.get(`course-bundles/student/${queryKey[1]}`, {
    params: {
      ...queryKey[2],
    },
  });
};

export const addDoubt = (data: any) => {
  return http.post(`/doubts`, data);
};

export const getDoubts = ({ queryKey }: any) => {
  return http.get(`/doubts/student/topic/${queryKey[1]}`, {
    params: {
      ...queryKey[2],
    },
  });
};

export const addNotes = (data: any) => {
  return http.post(`/personal-notes`, data);
};

export const getNotes = ({ queryKey }: any) => {
  return http.get(`personal-notes/topic/${queryKey[1]}`);
};

export const startCourse = (data: any) => {
  return http.post("/course-logs", data);
};

export const videoLog = (data:any)=>{
  return http.post("/course-logs/update",data);
}

export const nextVideo = (data:any)=>{
  return http.post("/course-logs/next-topic", data);
}

export const postReview = (data:any)=>{
  return http.post("/students/testimonials", data);
}
