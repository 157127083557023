import { Box, Typography, useTheme } from "@mui/material";
import Lottie from "react-lottie-player";
import { success } from "../assets";
import { StyledFlexBox } from "../styles";

function SuccessScreen({ response }: any) {
  const theme = useTheme();
  return (
    <StyledFlexBox height="60vh" flexDirection="column" justifyContent="end">
      <Lottie
        loop
        animationData={success}
        play
        style={{ width: 200, height: 200 }}
      />
      <Typography variant="h4" sx={{ fontSize: "28px" }}>
        Thank you for applying
      </Typography>
      <Typography my={2} textAlign="center" variant="h5">
        Our team will provide you the credentials to log in to Digital ITI
        Classes.
      </Typography>
      <Box>
        <Typography textAlign="center" variant="subtitle1">
          Your Admission number :
        </Typography>
        <Typography color={theme.palette.primary.main} variant="h3">
          {" "}
          {response?.data?.student?.studentId}
        </Typography>
      </Box>
    </StyledFlexBox>
  );
}

export default SuccessScreen;
