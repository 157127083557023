import success from "../assets/success.json";

//images
import emptyDashboard from "./images/EmptyDashboard.png";
import NoData from "./images/NoData.png";
import bgImg from "./images/bgImg.png";
import examBg from "./images/examBg.png";
import loginImage from "./images/loginImage.png";
import logo from "./images/logo.png";
import pdf from "./images/pdf.png";
import image from "./images/image.png";
import quote from "./images/quote.png";
import facebook from "./images/facebook.png";
import twitter from "./images/twitter-sign.png";
import instagram from "./images/instagram.png";
import youtube from "./images/youtube.png";
import rightArrow from "./images/Group 9302.png";
import leftArrow from "./images/Group 9303.png";
import BlogPost from "./images/Blog post-bro.png";
import TempImage from "./images/TempImage.png";
import rating from "./images/rating.png";
import exam from "./images/exam.png";
import resultsLoading from "./images/resultsLoading.png";
import TradeTheory from "./images/theory.png";
import TradePractical from "./images/TradePractical.png";
import Workshop from "./images/Workshop.png";
import EmployabilitySkills from "./images/EmployabilitySkills.png";
import EngineeringDrawing from "./images/EngineeringDrawing.png";

//icons
import ItiFitter from "./icons/ItiFitter.svg";
import askDoubt from "./icons/askDoubt.png";
import eBook from "./icons/eBook.png";
import fitter from "./icons/fitter.png";
import learn from "./icons/learn.png";
import onlineExam from "./icons/onlineExam.png";
import personalNotes from "./icons/personalNotes.png";
import upload from "./icons/upload.png";
import book from "./icons/book.png";
import test from "./icons/test.png";
import doubt from "./icons/doubt.png";
import notes from "./icons/notes.png";
import learnBlack from "./icons/learnBlack.png";
import doubtSelected from "./icons/doubtSelected.png";
import noteSelected from "./icons/noteSelected.png";
import examSelected from "./icons/examSelected.png";
import bookSelected from "./icons/bookSelected.png";
import assignment from "./icons/assignment.png";
import assignmentSelected from "./icons/assignmentSelected.png";
import lock from "./icons/lock.png";
import play from "./icons/play.png";
import quiz from "./icons/quiz.png";
import completed from "./icons/completed.png";
import chronometer from "./icons/chronometer.png";
import correct from "./icons/correct.png";
import wrong from "./icons/wrong.png";
import MyDashboard from "./icons/MyDashboard.png";
import MyTradeTimetable from "./icons/MyTradeTimetable.png";
import MyLearning from "./icons/MyLearning.png";
import MyOnlineTest from "./icons/MyOnlineTest.png";
import MyDoubts from "./icons/MyDoubts.png";
import MyEBooks from "./icons/MyEBooks.png";
import MyLiveSessions from "./icons/MyLiveSessions.png";
import MyDiscussionForum from "./icons/MyDiscussionForum.png";
import MyNoticeBoard from "./icons/MyNoticeBoard.png";
import MyExamTimetable from "./icons/MyExamTimetable.png";
import MyAccountDetails from "./icons/MyAccountDetails.png";

export {
  TradeTheory,
  bgImg,
  emptyDashboard,
  NoData,
  examBg,
  loginImage,
  logo,
  pdf,
  success,
  quote,
  twitter,
  facebook,
  youtube,
  instagram,
  rightArrow,
  leftArrow,
  BlogPost,
  TempImage,
  image,
  rating,
  exam,
  resultsLoading,
  TradePractical,
  Workshop,
  EmployabilitySkills,
  EngineeringDrawing,
};

export const icons = {
  upload,
  fitter,
  ItiFitter,
  learn,
  onlineExam,
  personalNotes,
  eBook,
  askDoubt,
  book,
  test,
  doubt,
  notes,
  learnBlack,
  doubtSelected,
  noteSelected,
  examSelected,
  bookSelected,
  assignment,
  assignmentSelected,
  lock,
  play,
  quiz,
  completed,
  chronometer,
  correct,
  wrong,
  MyDashboard,
  MyTradeTimetable,
  MyLearning,
  MyOnlineTest,
  MyDoubts,
  MyEBooks,
  MyLiveSessions,
  MyDiscussionForum,
  MyNoticeBoard,
  MyExamTimetable,
  MyAccountDetails,
};
