import { Autocomplete, Box, Menu, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "react-query";
import { getLiveSessions, getTrades } from "../../../api/services";
import { Loader } from "../../../components/loader";
import { StyledTitle } from "../../../components/styledHead";
import { AlignItems, StyledFlexBox } from "../../../styles";

const localizer = momentLocalizer(moment);

function LiveSessions() {
  const [trade, setTrade] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: liveSessionsData, isLoading: liveSessionsLoading } = useQuery(
    "liveSessions",
    getLiveSessions
  );

  const { data: tradeData } = useQuery("trades", getTrades);

  const myListEvents = liveSessionsData?.data?.data?.map((item: any) => ({
    title: item?.resourcePerson,
    start: new Date(item?.startTime),
    end: new Date(item?.endTime),
  }));

  if (liveSessionsLoading) {
    return <Loader />;
  }

  return (
    <AlignItems mt={15}>
      <StyledTitle title="Live Sessions" />
      <StyledFlexBox maxWidth="300px" mt={5}>
        <Autocomplete
          fullWidth
          options={tradeData?.data?.data?.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }))}
          value={trade?.label}
          onChange={(event: any, newValue: any) => {
            setTrade(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select your trade" />
          )}
        />
      </StyledFlexBox>
      <Box my={5}>
        <Calendar
          localizer={localizer}
          events={myListEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          // selectable={true}
          // onSelectEvent={(e: any) => handleClick(e)}
          toolbar={true}
          onDoubleClickEvent={(e: any) => handleClick(e)}
          getDrilldownView={(targetDate: any, currentViewName: any) => {
            if (currentViewName === "month") {
              return "day";
            }
          }}
        />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
      </Menu>
    </AlignItems>
  );
}

export default LiveSessions;
