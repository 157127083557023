import { http } from "../http";

export const getBundleTypes = () => {
  return http.get("/students/bundle-types");
};

export const getDoubts = ({ queryKey }: any) => {
  return http.get(`/doubts/student/${queryKey[1]}`, {
    params: {
      ...queryKey[2],
    },
  });
};
