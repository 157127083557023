import { Box } from "@mui/material";
import { useState } from "react";
import { AboutExam } from "./aboutExam";
import { Instructions } from "./instructions";

function Quiz() {
  const [value, setValue] = useState("instructions");

  return (
    <>
      <Box>
        {value === "instructions" && <Instructions setValue={setValue} />}
        {value === "aboutExam" && <AboutExam />}
      </Box>
    </>
  );
}

export default Quiz;
