import axios from "axios";

export const login = async (body: any) => {
  return await axios
    .post("https://api.digitaliti.in/auth/login", body)
    .then((res) => {
      localStorage.setItem("access_token", res.data.access_token);
    })
    .catch((error) => error);
};
