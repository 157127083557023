import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { leftArrow, quote, rightArrow } from "../../../assets";
function StudentTestimonials({ data }) {
  return (
    <Box
      sx={{ marginY: 2, width: "80%", margin: "auto", position: "relative" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          gap: "5px",
          marginTop: "3rem",
          marginBottom: 1,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: "#F4347F",
            width: "4px",
            height: "32px",
            justifyContent: "left",
            borderRadius: "0px 5px 5px 0px",
          }}
        ></Box>
        <Typography variant="h5" fontSize={"24px"}>
          Student Testimonials
        </Typography>
      </Box>

      <Carousel data={data?.data} />
    </Box>
  );
}

const StyledCard = ({ data, sx }: any) => {
  return (
    <Box
      sx={{
        width: { xs: "300px", sm: "320px", lg: "380px" },
        minHeight: "250px",
        justifyContent: "center",
        cursor: "pointer",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{ alignSelf: "center", backgroundColor: "white", zIndex: 999 }}
        >
          <img
            src={quote}
            alt="quote"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "100%",
              boxShadow: "0px 6px 16px #21468329",
            }}
          />
        </Box>
        <Box
          sx={{
            p: 2,
            boxShadow: "0px 3px 6px #11234B29",
            border: "1px solid #cecece45",
            borderRadius: "10px",
            mt: -3,
            pt: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 1,
            height: "250px",
          }}
        >
          <Typography sx={{ fontSize: "14px" }} variant="caption">
            {data?.description}
          </Typography>
          <Box>
            <Typography variant="h3" sx={{ fontSize: "20px" }}>
              {data?.student?.name}
            </Typography>
            <Typography sx={{ fontSize: "14px", opacity: 0.8 }}>
              {data?.trade?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Carousel = ({ data }: any) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(
    getSlidesPerView(window.innerWidth)
  );

  function getSlidesPerView(width: number): number {
    return Math.max(1, Math.floor(width / 400));
  }

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(getSlidesPerView(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        marginBottom: "20px",
      }}
    >
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={5}
        slidesPerView={slidesPerView}
        loop={true}
        autoplay={{ delay: 500, disableOnInteraction: false }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
      >
        {data?.map((content: any, index: any) => (
          <SwiperSlide>
            <StyledCard sx={{ marginRight: 3 }} data={content} key={index} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton ref={prevRef}>
          <img
            style={{ width: "35px", height: "35px" }}
            src={leftArrow}
            alt="leftArrow"
          />
        </IconButton>
        {data?.map((_, bulletIdx) => (
          <Box
            key={bulletIdx}
            sx={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: "#F4347F",
              opacity: bulletIdx === activeIndex ? "100%" : "30%",
              margin: "15px 0px",
              cursor: "pointer",
            }}
          />
        ))}
        <IconButton ref={nextRef}>
          <img
            style={{ width: "35px", height: "35px" }}
            src={rightArrow}
            alt="rightArrow"
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default StudentTestimonials;
