import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "./Card";
import ExamCarousel from "./Examcard";
import { StyledHeading } from "./styles";

function ExploreSection({ data }: any) {
  const [selectedBtn, setSelectedBtn] = useState(data && data?.[0]?.name);
  const navigate = useNavigate();

  const Buttons =
    data?.map((item) => {
      return item.name;
    }) || [];

  useEffect(() => {
    if (data && data.length > 0) {
      setSelectedBtn(data[0].name);
    }
  }, [data]);

  const coursesData = data?.find((item: any) => item.name === selectedBtn);
  const examData = data?.find((item: any) => item.name === selectedBtn);

  return (
    <Box
      sx={{
        height: "auto",
        width: "100%",
        paddingLeft: "9%",
        paddingRight: "9%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          marginTop: "20px",
          alignItems: "center",
          py: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: "#F4347F",
            width: "4px",
            height: "32px",
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        ></Box>
        <Typography variant="h5" fontSize={"24px"}>
          Explore
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {Buttons.map((btn: string) => {
          return (
            <Button
              key={btn}
              variant={btn === selectedBtn ? "contained" : "outlined"}
              sx={{
                margin: "10px",
                height: "40px",
                width: "150px",
                fontSize: "14px",
                px: 1,
              }}
              onClick={() => setSelectedBtn(btn)}
            >
              {btn}
            </Button>
          );
        })}
      </Box>
      <StyledHeading sx={{ mt: 3 }}>
        <Typography variant="h4" sx={{ fontSize: "20px" }}>
          Online Courses
        </Typography>
        <Button
          sx={{
            ":hover": { backgroundColor: "transparent" },
            p: 0,
          }}
          onClick={() => navigate("view-more")}
        >
          <Typography
            sx={{
              color: "#F4347F",
            }}
          >
            View More
          </Typography>
        </Button>
      </StyledHeading>
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            width: "0.5em",
            height: "0.5em",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            borderRadius: "3px",

            "&:hover": {
              background: "rgba(255, 255, 255, 0.4)",
            },
          },
        }}
      >
        {coursesData && coursesData?.courseBundles?.length > 0 ? (
          <Carousel coursedata={coursesData} name={selectedBtn} />
        ) : (
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontSize: "1.2rem",
              color: "red",
              fontWeight: "bold",
              fontFamily: "figtree_semibold",
              p: 2,
            }}
          >
            No courses available
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="h4" sx={{ fontSize: "20px" }}>
          CBT/ Online Tests
        </Typography>
        <Box
          sx={{
            marginTop: 1,
            display: "flex",
            overflowX: "scroll",
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "3px",

              "&:hover": {
                background: "rgba(255, 255, 255, 0.4)",
              },
            },
          }}
        >
          {examData?.exams?.length > 0 ? (
            <ExamCarousel data={examData} tradeName={selectedBtn} />
          ) : (
            <Box sx={{ width: "100%", textAlign: "center", p: 4 }}>
              <Typography
                variant="h5"
                sx={{
                  alignSelf: "center",
                  fontSize: "1.2rem",
                  color: "red",
                  fontWeight: "bold",
                  fontFamily: "figtree_semibold",
                  p: 2,
                }}
              >
                No Tests Available
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ExploreSection;
