import { Box, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { getTopicComponents } from "../../../api/services";
import { NoData } from "../../../assets";
import { StyledHead } from "../../../components/styledHead";
import { QuizCard } from "./QuizCard";

const Quiz = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const topicId = searchParams.get("topicId");

  const { data } = useQuery(
    ["getTopicComponents", topicId],
    getTopicComponents
  );

  const quizData = data?.data?.filter((item: any) => item?.type === "QUIZ");

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", px: 2, gap: 1 }}>
        {quizData?.length > 0 ? (
          <>
            <StyledHead head={"Quizes"} color="black" sx={{ height: "30px" }} />
            {quizData?.map((item: any, index: any) => {
              return (
                <>
                  <QuizCard data={item?.quiz} />
                  {index < quizData.length - 1 && <Divider />}
                </>
              );
            })}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
            }}
          >
            <Box sx={{ alignItems: "center", width: "500px" }}>
              <img src={NoData} alt="No Data" height={"100%"} width={"100%"} />
            </Box>
            <Typography variant="h4" sx={{ py: 1 }}>
              No Quizes Found
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Quiz;
