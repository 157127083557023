import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getAllLanguages,
  getCourseById,
  getCourseData,
} from "../../../../api/services";
import { Loader } from "../../../../components/loader";
import { StyledAcordian } from "../CourseCurriculumPage/Curriculum";
var momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function SideBar(props) {
  const [PresentCourse, setPresentCourse] = useState("");
  const isLessThan1200 = useMediaQuery("(max-width:1200px)");
  return (
    <Box sx={{ width: isLessThan1200 ? "100%" : "100%" }}>
      <HeaderNavigator setOpenSidebar={props.setOpenSidebar} />
      <HeaderSelect
        PresentCourse={PresentCourse}
        setPresentCourse={setPresentCourse}
      />
      <CurriculumComponent PresentCourse={PresentCourse} />
    </Box>
  );
}

export default SideBar;

export const HeaderNavigator = (props) => {
  const isLessThan1200 = useMediaQuery("(max-width:1200px)");
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        m: "10px",
        mt: "80px",
      }}
    >
      <Box>
        <Link
          component={RouterLink}
          to="/my-learning"
          color="inherit"
          style={{
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <NavigateBeforeIcon style={{ marginRight: "4px" }} />
          <Typography sx={{ color: "rgba(18, 18, 18, 0.5)" }}>
            Go To Dashboard
          </Typography>
        </Link>
      </Box>
      <DrawerHeader>
        {isLessThan1200 ? (
          <IconButton
            onClick={() => {
              props.setOpenSidebar(false);
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        ) : (
          <></>
        )}
      </DrawerHeader>
      {/* <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <BlueStare />
      <Typography color={"#2F468C"} sx={{ fontSize: "16px" }} variant="h6">
        Leave a rating
      </Typography>
    </Box> */}
    </Box>
  );
};

export const HeaderSelect = (props) => {
  const { tradeid, bundleid, courseid } = useParams();
  const id = tradeid;
  const { data: Bundledata } = useQuery(
    ["getBundleswith-tradeid", id],
    getCourseData
  );
  const bundledataobject = Bundledata?.data
    ? Bundledata?.data?.data.find((item) => item.bundleId === bundleid)
    : null;

  const handleChange = (event: SelectChangeEvent) => {
    props.setPresentCourse(event.target.value as string);
  };
  useEffect(() => {
    props.setPresentCourse(courseid);
  });
  return (
    <Stack sx={{ marginY: "30px", padding: "10px" }}>
      <Typography fontSize={"15px"}>{bundledataobject?.bundleId}</Typography>
      <Box>
        <Box sx={{ minWidth: 120 }}>
          {/* <InputLabel ></InputLabel> */}
          <Typography
            id="demo-simple-select-label"
            sx={{ marginY: "10px", cursor: "pointer" }}
          >
            Select Course
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.PresentCourse}
              // label="Age"
              onChange={handleChange}
            >
              {bundledataobject?.courses.map((item, index) => {
                return (
                  <MenuItem key={index} value={item?._id}>
                    {item?.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Stack>
  );
};

export const CurriculumComponent = (props) => {
  const id = props.PresentCourse;
  const { data: languageData, isLoading: languageLoading } = useQuery(
    "languages",
    getAllLanguages
  );

  const [language, setLanguage] = useState<any>({
    label: languageData?.data?.data[0]?.name,
    value: languageData?.data?.data[0]?._id,
  });

  const { data: courseData, isLoading } = useQuery(
    [
      "courseById",
      id,
      {
        languageId: language?.value,
      },
    ],
    getCourseById
  );

  useEffect(() => {
    if (languageData?.data?.data?.length > 0) {
      setLanguage({
        label: languageData?.data?.data[0]?.name,
        value: languageData?.data?.data[0]?._id,
      });
    }
  }, [languageData, courseData]);

  if (isLoading || languageLoading) {
    return <Loader />;
  }

  return (
    <>
      <Stack
        gap={"10px"}
        sx={{
          backgroundColor: "#2F468C",
          padding: "20px",
          alignItems: "center",
        }}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Typography color={"white"} fontSize={"20 px"}>
          {courseData?.data?.title} (
          {moment.duration(courseData?.data?.duration, "seconds").humanize()})
        </Typography>
        <Box width="150px">
          <Autocomplete
            fullWidth
            options={languageData?.data?.data?.map((item: any) => ({
              label: item?.name,
              value: item?._id,
            }))}
            value={language?.label}
            onChange={(event: any, newValue: any) => {
              setLanguage(newValue);
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "white",
                " .MuiIconButton-root": {
                  color: "white",
                },
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select language" />
            )}
          />
        </Box>
      </Stack>
      <Box>
        {courseData?.data?.subjects?.map((item: any, index) => (
          <StyledAcordian
            key={index}
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
            sx={{ padding: "0px" }}
          >
            {/* <AccordionSummary
              sx={{ backgroundColor: "#EEF2FF" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography variant="h5">{item?.title}</Typography>
            </AccordionSummary> */}
            <Chapeters data={item?.chapters || []} />
          </StyledAcordian>
        ))}
      </Box>
    </>
  );
};

export const Chapeters = (data: any) => {
  return (
    <>
      {data?.data?.map((item: any, index) => {
        return (
          <>
            <StyledAcordian
              key={index}
              elevation={1}
              disableGutters
              TransitionProps={{ unmountOnExit: true }}
              sx={{ backgroundColor: "#fff" }}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "#F2F7FF",
                  // border: "1px solid #22222229",
                  // borderRadius: "15px",
                  // marginY: "10px",
                  // padding: "10px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Stack gap={"5px"}>
                  <Typography variant="h5">{item?.title}</Typography>
                </Stack>
              </AccordionSummary>
              {/* <Chapeters data={item?.chapters} /> */}
              {item?.sections?.map((cls: any) => (
                <Sections data={cls} sectionId={cls?._id} />
              ))}
            </StyledAcordian>
          </>
        );
      })}
    </>
  );
};

export const Sections = ({ data, sectionId }: any) => {
  return (
    <>
      <StyledAcordian
        elevation={0}
        disableGutters
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          ml: 1,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack gap={"5px"}>
            <Typography variant="h5">{data?.title}</Typography>
          </Stack>
        </AccordionSummary>
        {data?.topics?.map((item: any) => (
          <Topics data={item} sectionId={sectionId} topicId={item?._id} />
        ))}
      </StyledAcordian>
    </>
  );
};

export const Topics = ({ data, topicId, sectionId }: any) => {
  const theme = useTheme();
  const [search, setSearch] = useSearchParams();
  const isActive = search.get("topicId") === topicId;

  // function getTimeStr(seconds: any) {
  //   var duration = moment.duration(seconds, "seconds");
  //   return duration.format("d[d] h[h] m[m] s[s]");
  // }

  const handleClickTopic = (id: any) => {
    setSearch({ topicId: id });
  };
  return (
    <>
      <AccordionDetails
        sx={{
          backgroundColor: isActive ? theme.palette.primary.light : "#fff",
          borderRadius: "8px",
          ml: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            gap: "30px",
          }}
          onClick={() => handleClickTopic(data?._id)}
        >
          <Typography sx={{ fontSize: "16px" }}>{data?.title}</Typography>
          <Typography variant="body2">
            {moment.duration(data?.duration, "second")?.humanize()}
          </Typography>
        </Box>
      </AccordionDetails>
    </>
  );
};
