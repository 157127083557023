import { Box, Button, Tab, Typography } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCurriculum, startCourse } from "../../api/services/learning";
import { icons } from "../../assets";
import { Loader } from "../../components/loader";
import {
  StyledComponentWrapper,
  StyledContainer,
  StyledTabs,
} from "../../components/styles";
import AssignmentAndProject from "./components/AssignmentAndProject";
import Doubts from "./components/Doubts";
import EBooks from "./components/Ebooks";
import Learn from "./components/Learn";
import Notes from "./components/Notes";
import Quiz from "./components/Quiz";

function MyLearning() {
  const navigate = useNavigate();
  const { bundleId, courseId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const sectionId = searchParams.get("sectionId");
  const chapterId = searchParams.get("chapterId");
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["get-curriculum", courseId],
    getCurriculum
  );

  const { mutate } = useMutation(startCourse, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
      searchParams.set("chapterId", data?.data?.courseLog?.chapterId);
      searchParams.set("sectionId", data?.data?.courseLog?.sectionId);
      searchParams.set("topicId", data?.data?.courseLog?.topicId);
      navigate(`?${searchParams.toString()}`);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onStartCourse = () => {
    const postData = { bundleId: bundleId, courseId: courseId };
    mutate(postData);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {topicId && sectionId && chapterId ? (
        <TabsContainer
          containerVariant="box"
          tabs={[
            {
              component: <Learn />,
              key: "learn",
              label: "Learn",
              selectedIcon: (
                <img
                  src={icons.learn}
                  alt="learn"
                  height={"16px"}
                  width={"16px"}
                />
              ),
              icon: (
                <img
                  src={icons.learnBlack}
                  alt="learn"
                  height={"16px"}
                  width={"16px"}
                />
              ),
            },
            {
              component: <Doubts />,
              key: "doubt",
              label: "Ask a Doubt",
              icon: (
                <img
                  src={icons.doubt}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
              selectedIcon: (
                <img
                  src={icons.doubtSelected}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
            },
            {
              component: <Notes />,
              key: "notes",
              label: "Personal notes",
              icon: (
                <img
                  src={icons.notes}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
              selectedIcon: (
                <img
                  src={icons.noteSelected}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
            },
            {
              component: <EBooks />,
              key: "books",
              label: "E-Books",
              icon: (
                <img
                  src={icons.eBook}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
              selectedIcon: (
                <img
                  src={icons.bookSelected}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
            },
            {
              component: <Quiz />,
              key: "quiz",
              label: "Quiz",
              icon: (
                <img
                  src={icons.onlineExam}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
              selectedIcon: (
                <img
                  src={icons.examSelected}
                  alt="doubt"
                  height={"16px"}
                  width={"16px"}
                />
              ),
            },
            {
              component: <AssignmentAndProject />,
              key: "assignment",
              label: "Assignments & Projects",
              selectedIcon: (
                <img
                  src={icons.assignmentSelected}
                  alt="learn"
                  height={"16px"}
                  width={"16px"}
                />
              ),
              icon: (
                <img
                  src={icons.assignment}
                  alt="learn"
                  height={"16px"}
                  width={"16px"}
                />
              ),
            },
          ]}
        />
      ) : (
        <Box
          sx={{
            height: `calc(100vh - ${"80px"})`,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Box sx={{ height: "350px", width: "500px" }}>
                <img
                  src={data?.data?.imageUrl}
                  alt="Course Image"
                  height={"100%"}
                  width={"100%"}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography variant="h4">{data?.data?.title}</Typography>
              <Box
                sx={{ fontFamily: "figtree_medium", fontSize: "16px" }}
                dangerouslySetInnerHTML={{ __html: data?.data?.description }}
              ></Box>
              <Button
                variant="contained"
                sx={{ maxWidth: "max-content" }}
                onClick={onStartCourse}
              >
                Start Course
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MyLearning;

const updateSearchParams = (key: any, value: any) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  return searchParams.toString();
};
interface TabsContainerProps {
  tabs: {
    key: string;
    label: string;
    component: ReactNode;
    highlight?: boolean;
    icon?: any;
    selectedIcon?: any;
  }[];
  size?: "small" | "medium";
  containerVariant?: "box" | "page";
  onTabChange?: (tabKey: string) => void;
  tabUnderlineColor?: string;
  tabMobileUnderlineColor?: string;
}
function TabsContainer({
  tabs,
  size = "small",
  containerVariant = "box",
  onTabChange,
  tabUnderlineColor,
  tabMobileUnderlineColor = "F4347F",
}: TabsContainerProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(tab || tabs[0]?.key);

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    onTabChange && onTabChange(value);
    return navigate(`?${updateSearchParams("tab", value)}`);
  };

  useEffect(() => {
    setCurrentTab(tab || tabs[0]?.key);
  }, [tabs]);

  return (
    <StyledContainer
      sx={{
        width: {
          xs: "100vw",
          sm: "100vw",
          lg: "100%",
          xl: "100%",
        },
      }}
    >
      <StyledTabs
        containerVariant={containerVariant}
        size={size}
        onChange={handleTabsChange}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        underlineColor={tabUnderlineColor}
        mobileUnderlineColor={tabMobileUnderlineColor}
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab.key}
            sx={{ opacity: "100%" }}
            label={tab.label}
            value={tab.key}
            icon={currentTab === tab.key ? tab?.selectedIcon : tab?.icon}
            iconPosition="start"
            disableRipple
          />
        ))}
      </StyledTabs>
      <StyledComponentWrapper containerVariant={containerVariant}>
        <>{tabs?.find((tab) => tab?.key === currentTab)?.component}</>
      </StyledComponentWrapper>
    </StyledContainer>
  );
}
