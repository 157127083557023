import { http } from "../http";

export const login = (data: any) => {
  return http.post(`/auth/login`, data);
};

export const getStudentProfile = () => {
  return http.get(`/students/profile`);
};

export const getTimeSpent = ({ queryKey }: any) => {
  return http.get(`/students/time-spent`, {
    params: {
      ...queryKey[1],
    },
  });
};
export const getExamsEBooks = () => {
  return http.get(`/students/exams`);
};

export const getOneCourseBundle = ({ queryKey }: any) => {
  return http.get(`/course-bundles/${queryKey[1]}`);
};

export const getCourseData = ({ queryKey }: any) => {
  return http.get(`/course-bundles`, {
    params: {
      tradeId: queryKey[1],
    },
  });
};

export const getCourseById = ({ queryKey }: any) => {
  return http.get(`/courses/${queryKey[1]}`, {
    params: {
      ...queryKey[2],
    },
  });
};

export const getCourse = async ({ queryKey }: any) => {
  return await http.get(`/courses/${queryKey[1]}`);
};

export const getCourses = async ({ queryKey }: any) => {
  return await http.get(`/courses/search`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getQuiz = ({ queryKey }: any) => {
  return http.get(`/quiz/result`, {
    params: {
      search: queryKey[1],
    },
  });
};

export const getTopicComponents = ({ queryKey }: any) => {
  return http.get(`/subjects/chapters/topics/${queryKey[1]}`);
};

export const getAllDistrics = ({ queryKey }: any) => {
  return http.get(`/districts`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getAllCenterType = ({ queryKey }: any) => {
  return http.get(`/centre-type`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getAllDigitalLibrary = () => {
  return http.get(`/trades/digital-library`).then((res) => res.data);
};

export const getAllItiCenters = ({ queryKey }: any) => {
  return http.get(`/iti-centres`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getTrades = ({ queryKey }: any) => {
  return http.get(`/trades`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getTradeById = ({ queryKey }: any) => {
  return http.get(`/trades/${queryKey[1]}`);
};

export const signUp = (data: any) => {
  return http.post(`/students`, data);
};

export const logout = () => {
  return http.patch(`/students/time-stamp`);
};

export const getAllLanguages = () => {
  return http.get(`/languages`);
};

export const getLiveSessions = ({ queryKey }: any) => {
  return http.get(`/live-session`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getGuidelines = () => {
  return http.get(`/examination-guidelines`);
};

export const getExamTimetable = () => {
  return http.get(`/exam-timetable/student`);
};

export const getTradeTimeTable = ({ queryKey }: any) => {
  return http.get(`/time-table`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const withdrawRequestOtp = (data: any) => {
  return http.post(`/auth/request-otp`, data);
};

export const verifyOtp = (data: any) => {
  return http.post(`/auth/verify-otp`, data);
};

export const getStudentTestimonial = () => {
  return http.get(`/students/testimonials`);
};

export const getStatistics = () => {
  return http.get(`/statistics/user-web`);
};
