import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { getBundleTypes } from "../../../../api/services/ebooks";
import { Loader } from "../../../../components/loader";
import TabsContainer from "../../../../components/TabsContainer";
import DoubtTab from "./components/DoubtTab";

function MyDoubts() {
  const { data, isLoading } = useQuery("BundleTypes", getBundleTypes);
  const Tabs = data?.data?.bundles?.map((item: any) => {
    return {
      component: <DoubtTab data={item} />,
      key: item?.name,
      label: item?.name,
    };
  });
  return (
    <Box sx={{ width: "100%" }}>
      {isLoading ? <Loader /> : <TabsContainer tabs={Tabs || []} />}
    </Box>
  );
}

export default MyDoubts;
