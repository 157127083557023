import { Box, Typography } from "@mui/material";

import axios from "axios";
import { toast } from "react-toastify";
import { pdf } from "../assets";
import { StyledFlexBox } from "../styles";

function UploadImage({
  pic,
  setPic,
  type,
  subType,
  accept,
  labelName,
  pdfName,
}: any) {
  const handleImageChange = async (e: any) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("subType", subType);

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/s3-resources/file-upload`,
        data: formData,
      });
      setPic(response?.data);
    } catch (error) {
      toast.error("Error");
    }
  };

  return (
    <Box>
      {pic === null ||
      pic === undefined ||
      pic ===
        `https://jss-vkinnect-bucket.s3.ap-south-1.amazonaws.com/undefined` ? (
        <>
          <label htmlFor={labelName}>
            <Box
              mt={1}
              border="1px solid #F4347F"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="45px"
              px={7}
              sx={{ cursor: "pointer" }}
            >
              <StyledFlexBox gap={1}>
                <Typography fontSize="15px" color="#F4347F">
                  Upload
                </Typography>
              </StyledFlexBox>
            </Box>
          </label>
          <input
            onChange={handleImageChange}
            type="file"
            style={{ display: "none" }}
            id={labelName}
            accept={accept}
          />
        </>
      ) : (
        <Box
          my={2}
          sx={{
            display: "flex",
          }}
          gap={"5px"}
        >
          <label htmlFor={labelName}>
            <Box
              border="1px solid #0000001A"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
              sx={{ cursor: "pointer" }}
            >
              <img
                style={{ borderRadius: "10px", objectFit: "contain" }}
                src={pic?.includes("pdf") ? pdf : pic}
                alt="pic"
                height={200}
                width={250}
              />
            </Box>
            {pic?.includes("pdf") && (
              <Typography pt={1} fontSize="15px">
                {pdfName?.originalFileName}
              </Typography>
            )}
          </label>
          <input
            onChange={handleImageChange}
            type="file"
            style={{ display: "none" }}
            id={labelName}
            accept={accept}
          />
        </Box>
      )}
    </Box>
  );
}

export default UploadImage;
