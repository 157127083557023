import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { getNoticeboard } from "../../../../../api/services/noticeboard";
import { Loader } from "../../../../../components/loader";
import MediaCard from "./MediaCard";

const Trade = () => {
  const { data, isLoading } = useQuery(
    [
      "getNoticeboard",
      {
        type: "TRADE",
      },
    ],
    getNoticeboard
  );
  return (
    <Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "70%",
          }}
        >
          {data?.data?.data?.map((item: any) => (
            <MediaCard data={item} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Trade;
