import { array, object, string } from "yup";

export let studentValues = {
  fatherName: "",
  name: "",
  email: "",
  mobile: "",
  dateOfBirth: "",
  aadharNumber: "",
  districtId: "",
  tradeId: "",
  typeId: "",
  centreId: "",
  languageIds: [],
};

export let studentSchema = object().shape({
  name: string()
    .required("Name is required")
    .matches(/^[a-zA-Z ,&\-]+$/, "Must be a String"),
  fatherName: string()
    .required("Name is required")
    .matches(/^[a-zA-Z ,&\-]+$/, "Must be a String"),
  email: string()
    .required("Email is required")
    .email("Email is invalid")
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "Email is invalid"),
  mobile: string()
    .required("Mobile Number is required")
    .min(10, "Mobile number should be at least 10 digits")
    .max(10, "Mobile number should not exceed 10 digits")
    .matches(/^[0-9]+$/, "Must be a Number"),
  aadharNumber: string()
    .required("Aadhar Number is required")
    .min(12, "Aadhar number should be at least 12 digits")
    .max(12, "Aadhar number should not exceed 12 digits")
    .matches(/^[0-9]+$/, "Must be a Number"),
  districtId: string().required("District is required"),
  tradeId: string().required("Trade is required"),
  typeId: string().required("Type is required"),
  centreId: string().required("Centre is required"),
  languageIds: array().required("Language is required"),
});
