import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { addNotes, getNotes } from "../../../api/services/learning";
import FormOutlinedInput from "../../../components/FormFields/FormOutlinedInput";
import { Loader } from "../../../components/loader";
import { StyledHead } from "../../../components/styledHead";
import NoteCard from "./NoteCard";

const Notes = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { control, getValues, setValue } = useForm();
  const queryClient = useQueryClient();

  const topicId = searchParams.get("topicId");

  const { data, isLoading } = useQuery(["getNotes", topicId], getNotes);

  const { mutate } = useMutation(addNotes, {
    onSuccess: (res: any) => {
      toast.success("Note created successfully");
      setValue("note", "");
      queryClient.invalidateQueries("getNotes");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handlePostNotes = () => {
    if (getValues("note") === "") {
      toast.error("Enter the note before submiting");
    }
    const postData = {
      topicId: topicId,
      notesDescription: getValues("note"),
    };
    mutate(postData);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              py: 1,
              pb: 3,
              borderBottom: "1px solid #cecece47",
            }}
          >
            <StyledHead
              head={"Personal notes"}
              color="black"
              sx={{ height: "30px" }}
            />
            <FormOutlinedInput
              control={control}
              name="note"
              placeholder="Start writing here…"
              multiline={5}
            />
            <Button
              sx={{ width: "150px" }}
              variant="contained"
              onClick={() => handlePostNotes()}
            >
              Save
            </Button>
          </Box>

          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              color: "#2F468C",
              py: 2,
            }}
          >
            Your Notes
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {data?.data?.length > 0 ? (
              data?.data?.map((item: any) => (
                <NoteCard key={item._id} data={item} />
              ))
            ) : (
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: 600,
                  py: 5,
                }}
              >
                No Notes Found
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Notes;
