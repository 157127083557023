import { Tab } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";

import { StyledComponentWrapper, StyledContainer, StyledTabs } from "./styles";

export interface TabsContainerProps {
  tabs: {
    key: string;
    label: string;
    component: ReactNode;
    highlight?: boolean;
    icon?: any;
    selectedIcon?: any;
  }[];
  size?: "small" | "medium";
  containerVariant?: "box" | "page";
  onTabChange?: (tabKey: string) => void;
  tabUnderlineColor?: string;
  tabMobileUnderlineColor?: string;
}

export default function TabsContainer({
  tabs,
  size = "small",
  containerVariant = "box",
  onTabChange,
  tabUnderlineColor,
  tabMobileUnderlineColor = "F4347F",
}: TabsContainerProps) {
  const [currentTab, setCurrentTab] = useState(tabs[0]?.key);

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
    onTabChange && onTabChange(value);
  };
  useEffect(() => {
    setCurrentTab(tabs[0]?.key);
  }, [tabs]);

  return (
    <StyledContainer
      sx={{
        width: {
          xs: "100vw",
          sm: "100vw",
          lg: "100%",
          xl: "100%",
        },
      }}
    >
      <StyledTabs
        containerVariant={containerVariant}
        size={size}
        onChange={handleTabsChange}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        textColor="primary"
        indicatorColor="primary"
        underlineColor={tabUnderlineColor}
        mobileUnderlineColor={tabMobileUnderlineColor}
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab.key}
            sx={{ opacity: "100%" }}
            label={tab.label}
            value={tab.key}
            icon={currentTab === tab.key ? tab?.selectedIcon : tab?.icon}
            iconPosition="start"
            disableRipple
          />
        ))}
      </StyledTabs>
      <StyledComponentWrapper containerVariant={containerVariant}>
        <>{tabs?.find((tab) => tab?.key === currentTab)?.component}</>
      </StyledComponentWrapper>
    </StyledContainer>
  );
}
