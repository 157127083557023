import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { numberToAlphabet } from "../../api/services/quiz";
import {
  getExamStates,
  getResponse,
  getTest,
  saveAnswer,
  SubmitTheTest,
} from "../../api/services/test";
import ConfirmDialog from "../../components/ConfirmDialog";
import { StyledFlexBox } from "../../styles";
import {
  closeFullscreen,
  disableRightClick,
  handleKeyDown,
  openFullscreen,
} from "./instructions";
import {
  Answered,
  AnsweredAndReview,
  NotAnswered,
  NotVisited,
  Review,
} from "./responseContainers";
export const StartTest = () => {
  const [value, setValue] = useState("");
  const { id, examId } = useParams();
  const [part, setPart] = useState(0);
  const [question, setQuestion] = useState(0);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [warning, setWarning] = useState(false);
  const navigate = useNavigate();

  const handleExit = () => {
    setWarning(false);
    // navigate("/online-test");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data } = useQuery("get-single-test", () => getTest(id));
  const { data: ExamStatesData } = useQuery("get-exam-states", () =>
    getExamStates(id)
  );

  const { data: responseData } = useQuery(
    [
      "getResponse",
      {
        examId: data?.data?._id,
        partId: data?.data?.parts[part]?.partId,
        questionId: data?.data?.parts[part]?.questions[question]?.questionId,
      },
    ],
    getResponse
  );

  useEffect(() => {
    setValue(responseData?.data?.answer || "");
  }, [responseData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const { mutate } = useMutation(saveAnswer, {
    onSuccess: () => {
      setValue("");
      queryClient.invalidateQueries("get-exam-states");
      queryClient.invalidateQueries("get-single-test");

      if (question + 1 > data?.data?.parts[part]?.questions?.length - 1) {
        setQuestion(0);
        if (part + 1 > data?.data?.parts?.length) {
          setPart(part + 1);
        } else {
          setPart(0);
        }
      } else {
        setQuestion(question + 1);
      }
    },
  });

  const { mutate: notAnswered } = useMutation(saveAnswer, {
    onSuccess: () => {
      setValue("");
      queryClient.invalidateQueries("get-exam-states");
      queryClient.invalidateQueries("get-single-test");
    },
  });

  const { mutate: EndExam } = useMutation(SubmitTheTest, {
    onSuccess: () => {
      toast.success("Test Submitted Successfully");
      closeFullscreen();
      document.removeEventListener("contextmenu", disableRightClick);
      window.removeEventListener("keydown", handleKeyDown);
      window.open(`/result/${examId}`, "_self");
    },
  });

  const handleConfirm = () => {
    window.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("contextmenu", disableRightClick);
    EndExam(id);
    setOpen(false);
  };

  const handleSaveAnswer = async () => {
    const postBody = {
      examId: data?.data?._id,
      partId: data?.data?.parts[part]?.partId,
      questionId: data?.data?.parts[part]?.questions[question]?._id,
      answer: value,
      status: "ANSWERED",
    };
    mutate(postBody);
  };

  const handleReviewNext = async () => {
    const postBody = {
      examId: data?.data?._id,
      partId: data?.data?.parts[part]?.partId,
      questionId: data?.data?.parts[part]?.questions[question]?._id,
      answer: value,
      status: value ? "ANSWERED_AND_REVIEW" : "REVIEW",
    };
    mutate(postBody);
  };

  const handleSkip = async () => {
    const postBody = {
      examId: data?.data?._id,
      partId: data?.data?.parts[part]?.partId,
      questionId: data?.data?.parts[part]?.questions[question]?._id,
      answer: "",
      status: "NOT_ANSWERED",
    };
    if (responseData?.data?.status === "NOT_VISITED") {
      notAnswered(postBody);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography sx={{ px: 4, py: 1, color: "#2F468C", fontWeight: 600 }}>
          Part {numberToAlphabet(part)}
        </Typography>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                py: 2,
                height: `calc(100vh - ${"106px"})`,
                border: "1px solid #22222233",
                position: "relative",
              }}
            >
              <Typography px={3}>Question {question + 1}</Typography>
              <Divider sx={{ my: 1.5 }} />
              {data?.data?.parts[part]?.questions[question]?.question ? (
                <Box px={3} sx={{ overflowY: "scroll" }}>
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data?.data?.parts[part]?.questions[question]
                            ?.question,
                      }}
                    ></div>
                  </Typography>
                  <Box mt={1}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                    >
                      {data?.data?.parts[part]?.questions[
                        question
                      ]?.options?.map((item: any) => (
                        <FormControlLabel
                          value={item}
                          control={<Radio />}
                          label={
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item,
                              }}
                            ></div>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Typography>
                    Questions Not Available in Current Part Kindly Select Any
                    Other Part
                  </Typography>
                </Box>
              )}

              <Box position="absolute" bottom={0} width="100%">
                <Divider sx={{ mt: 1 }} />
                <Typography>
                  <Box
                    p={2}
                    px={3}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <StyledFlexBox gap={2}>
                      <Button
                        sx={{ padding: "0 20px", fontSize: "14px" }}
                        variant="contained"
                        onClick={handleReviewNext}
                      >
                        Mark for Review and Next
                      </Button>
                      <Button
                        sx={{ padding: "0 20px", fontSize: "14px" }}
                        variant="contained"
                        onClick={() => {
                          setValue(null);
                        }}
                      >
                        Clear Response
                      </Button>
                    </StyledFlexBox>
                    <Button
                      sx={{ padding: "0 20px", fontSize: "14px" }}
                      variant="contained"
                      onClick={handleSaveAnswer}
                    >
                      Save and Next
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                p: 0,
                height: `calc(100vh - ${"106px"})`,
                overflowY: "scroll",
                border: "1px solid #22222233",
                position: "relative",
              }}
            >
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                  <StyledFlexBox gap={1}>
                    <Answered
                      label={
                        ExamStatesData?.find(
                          (item: any) => item?.status === "ANSWERED"
                        )?.count
                      }
                    />
                    <Typography>Answered</Typography>
                  </StyledFlexBox>
                </Grid>
                <Grid item xs={6}>
                  <StyledFlexBox gap={1}>
                    <NotAnswered
                      label={
                        ExamStatesData?.find(
                          (item: any) => item?.status === "NOT_ANSWERED"
                        )?.count
                      }
                    />
                    <Typography>Not Answered</Typography>
                  </StyledFlexBox>
                </Grid>
                <Grid item xs={6}>
                  <StyledFlexBox gap={1}>
                    <Review
                      label={
                        ExamStatesData?.find(
                          (item: any) => item?.status === "REVIEW"
                        )?.count
                      }
                    />
                    <Typography>Marked for Review</Typography>
                  </StyledFlexBox>
                </Grid>
                <Grid item xs={6}>
                  <StyledFlexBox gap={1}>
                    <NotVisited
                      label={
                        ExamStatesData?.find(
                          (item: any) => item?.status === "NOT_VISITED"
                        )?.count
                      }
                    />
                    <Typography>Not Visited</Typography>
                  </StyledFlexBox>
                </Grid>
                <Grid item xs={12}>
                  <StyledFlexBox gap={1}>
                    <AnsweredAndReview
                      label={
                        ExamStatesData?.find(
                          (item: any) => item?.status === "ANSWERED_AND_REVIEW"
                        )?.count
                      }
                    />
                    <Typography>Answered & Marked for Review</Typography>
                  </StyledFlexBox>
                </Grid>
              </Grid>

              {data?.data?.parts?.map((item: any, key: any) => (
                <Box sx={{ px: 2, py: 1, marginTop: "10px" }}>
                  <Typography sx={{ color: "#0E125E" }}>
                    Part {numberToAlphabet(key)}{" "}
                    {`No of Questions : ${item?.noOfQuestions}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      marginTop: "10px",
                      flexWrap: "wrap",
                      maxWidth: "250px",
                    }}
                  >
                    {item?.questions?.map((question: any, number: any) =>
                      question?.status === "NOT_VISITED" ? (
                        <NotVisited
                          onClick={() => {
                            setPart(key);
                            setQuestion(number);
                            handleSkip();
                          }}
                          label={number + 1}
                        />
                      ) : question?.status === "ANSWERED" ? (
                        <Answered
                          onClick={() => {
                            setPart(key);
                            setQuestion(number);
                            handleSkip();
                          }}
                          label={number + 1}
                        />
                      ) : question?.status === "REVIEW" ? (
                        <Review
                          onClick={() => {
                            setPart(key);
                            setQuestion(number);
                            handleSkip();
                          }}
                          label={number + 1}
                        />
                      ) : question?.status === "ANSWERED_AND_REVIEW" ? (
                        <AnsweredAndReview
                          onClick={() => {
                            setPart(key);
                            setQuestion(number);
                            handleSkip();
                          }}
                          label={number + 1}
                        />
                      ) : (
                        <NotAnswered
                          onClick={() => {
                            setPart(key);
                            setQuestion(number);
                            handleSkip();
                          }}
                          label={number + 1}
                        />
                      )
                    )}
                  </Box>
                </Box>
              ))}
              <Box position="absolute" bottom={0} width="100%">
                <Divider sx={{ mt: 1 }} />
                <Typography>
                  <Box p={2} display="flex" justifyContent="center">
                    <Button
                      sx={{
                        fontSize: "14px",
                        backgroundColor: "#689F38",
                        ":hover": { backgroundColor: "#689f38f0" },
                      }}
                      variant="contained"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Submit Test
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        message={" Are you sure you want to Submit This Test?"}
        open={open}
      />
      <Dialog open={warning} onClose={handleExit}>
        <DialogTitle>{"Exit Fullscreen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have attempted to exit fullscreen mode. Do you want to continue
            in fullscreen mode or exit the test?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExit} color="secondary">
            Exit
          </Button>
          <Button onClick={openFullscreen} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
