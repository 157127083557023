import { Box } from "@mui/material";

export const TradeTheory = (data: any) => {
  return (
    <Box>
      <table width="100%" cellPadding={15}>
        <tr style={{ height: "80px" }}>
          <th style={{ width: "140px" }}>Week No</th>
          <th colSpan={2}>Lesson Name</th>
        </tr>
        {data?.data?.data?.count === 0 ? (
          <tr style={{ backgroundColor: "#fff" }}>
            <td colSpan={3}>No data found</td>
          </tr>
        ) : (
          <>
            {data?.data?.data?.data?.map((item: any) => (
              <>
                {item?.theory?.map((theory: any) => (
                  <>
                    <tr style={{ backgroundColor: "#fff" }}>
                      <td>Week {theory?.week}</td>
                      <td style={{ textAlign: "left" }}>
                        {theory?.names?.map((name: any, index: number) => (
                          <ul style={{ margin: 0 }} className="list-guideline">
                            <li key={index}>{name}</li>
                          </ul>
                        ))}
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ))}
          </>
        )}
      </table>
    </Box>
  );
};
