import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Quill from "../../../../../components/Quill";
import LineText from "../../../Components/LineText";
import { postPersonalNotes } from "./services";

function PersonalNotes({ NotesData }: any) {
  const [data, setData] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const queryClient = useQueryClient();

  const { mutate } = useMutation(postPersonalNotes, {
    onSuccess: () => {
      queryClient.invalidateQueries("get-personal-notes");
      toast.success("Personal Notes saved Successfully");
      setData("");
    },
  });

  const handleSubmit = () => {
    const postBody = {
      topicId,
      notesDescription: data,
    };
    mutate(postBody);
  };

  return (
    <Box sx={{ marginY: "20px" }}>
      <LineText title={"Personal notes"} />
      <Box sx={{ marginY: "20px" }}>
        <Quill
          placeholder="Start writing here…"
          data={data}
          handleChange={(value) => setData(value)}
        />

        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
      <Box
        sx={{ border: "1px solid rgba(34, 34, 34, 0.1)", marginY: "20px" }}
      ></Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: "#2F468C" }} variant="h4">
            Your Notes
          </Typography>
        </Box>
      </Box>
      <Box>
        {NotesData?.map((item: any) => (
          <NotesCard notesDescription={item?.notesDescription} />
        ))}
      </Box>
    </Box>
  );
}

export default PersonalNotes;

export const NotesCard = ({ notesDescription }: any) => {
  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 3px 10px #0000001A",
          borderRadius: "15px",
          marginTop: "20px",
          padding: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack gap={"10px"}>
            <Typography
              variant="subtitle2"
              sx={{ color: "rgba(34, 34, 34, 0.5)" }}
            >
              Posted on 21 November, 2023 at 02:53 PM
            </Typography>
            <Stack gap={"15px"}>
              <Typography
                dangerouslySetInnerHTML={{ __html: notesDescription }}
              ></Typography>
            </Stack>
          </Stack>
          {/* <Typography
              sx={{
                color: "rgba(244, 52, 127, 0.75)",
                textDecoration: "underline",
                whiteSpace: "nowrap",
              }}
            >
              Go to topic
            </Typography> */}
        </Box>
      </Box>
    </>
  );
};
