import { Box, Button, Typography } from "@mui/material";

const Card = ({ data }: any) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 10px #0000001A",
        px: 2,
        py: 1,
        borderRadius: "5px",
      }}
    >
      {data?.type === "TEXT" && (
        <Box
          sx={{
            backgroundColor: "#0E125E05",
            px: 1,
            my: 1,
            border: "1px solid #cecece",
            fontFamily: "figtree_medium",
            fontSize: "14px",
            "& *": { py: 1, m: 0 },
          }}
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></Box>
      )}
      {data?.type === "RESOURCE" && (
        <Box>
          <Typography variant="body1">{data?.title}</Typography>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography> {`Resource Link: `}</Typography>
            <Button
              variant="text"
              disableRipple
              sx={{ px: 1, py: 0 }}
              onClick={() => window.open(data?.fileUrl, "_blank")}
            >
              Click Here
            </Button>
          </Box>
        </Box>
      )}
      {data?.type === "LINK" && (
        <Box>
          <Typography variant="body1">{data?.title}</Typography>
          <Box
            sx={{
              backgroundColor: "#0E125E05",
              px: 1,
              border: "1px solid #cecece",
              fontFamily: "figtree_medium",
              fontSize: "14px",
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></Box>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Typography> {`Reference Link: `}</Typography>
            <Button
              variant="text"
              disableRipple
              sx={{ px: 1, py: 0 }}
              onClick={() => window.open(data?.link, "_blank")}
            >
              Click Here
            </Button>
          </Box>
        </Box>
      )}
      {data?.type === "EXPERIMENT" && (
        <Box>
          <Typography variant="body1">{data?.title}</Typography>
          <Box
            sx={{
              backgroundColor: "#0E125E05",
              px: 1,
              my: 1,
              border: "1px solid #cecece",
              fontFamily: "figtree_medium",
              fontSize: "14px",
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></Box>
        </Box>
      )}
    </Box>
  );
};

export default Card;
