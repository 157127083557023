import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { image, pdf } from "../../../../assets";

const MediaCard = ({ data }) => {
  return (
    <>
      <Typography sx={{ fontSize: "14px", opacity: "0.6", pb: "3px" }}>
        {data?.keyType}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "7px",
          justifyContent: "space-between",
          border: "1px solid #cecece",
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box sx={{ height: "40px", width: "40px" }}>
            <img
              src={data?.mediaType === "IMAGE" ? image : pdf}
              width={"100%"}
              height={"100%"}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {data?.originalFileName}
            </Typography>
            <Typography variant="body2">
              Added on {moment(data?.createdAt).format("DD-MM-YYYY")}
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={() => {
            window.open(data?.url, "_blank");
          }}
        >
          <VisibilityIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default MediaCard;
